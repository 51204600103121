import apiInstance from ".."
import useAuthStatus from "../../Hooks/useAuthStatus"
import { apiEndpoints } from "../constants"
import cookie from 'react-cookies';
export const getRoomReviews = async ({ roomId }) => {


    return new Promise(async (resolve, reject) => {
        const session = cookie.load('session');
        try {
            if (!roomId) {
                reject("Room ID is required")
                return;
            }
            apiInstance.get(`${apiEndpoints.getReview + roomId.replace("id", "")}`, {
                headers: {
                    "Authorization": `Bearer ${session}`
                }
            }).then(res => {

                resolve(res.data)
                return;
            }
            ).catch(err => {
                console.log(err)
                reject(err)
                return;
            }
            )
        } catch (error) {
            reject(error);

        }
    }
    )

}
