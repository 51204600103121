// locationUtils.js

const getAddressDetails = (data) => {
    const addressDetails = data.address;
    return addressDetails;
};

const getAddress = async (latitude, longitude) => {
    try {
        const response = await fetch(
            `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`
        );

        if (!response.ok) {
            throw new Error('Error fetching address details');
        }

        const data = await response.json();

        if (data.address) {
            const addressDetails = getAddressDetails(data);
            return addressDetails;
        } else {
            throw new Error('No address found');
        }
    } catch (error) {
        throw new Error(`Error getting address: ${error.message}`);
    }
};

const getLocation = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            if ('geolocation' in navigator) {
                navigator.geolocation.getCurrentPosition(
                    async (position) => {
                        try {
                            const addressDetails = await getAddress(
                                position.coords.latitude,
                                position.coords.longitude
                            );

                            resolve({
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude,
                                addressDetails,
                            });
                        } catch (error) {
                            reject({ error: `Error getting location: ${error.message}` });
                        }
                    },
                    (error) => {
                        reject({ error: `Error getting location: ${error.message}` });
                    }
                );
            } else {
                reject({ error: 'Geolocation is not supported in this environment' });
            }
        } catch (error) {
            reject({ error: `Error checking geolocation: ${error.message}` });
        }
    });
};

export default getLocation;
