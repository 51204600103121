import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faLocationCrosshairs } from '@fortawesome/free-solid-svg-icons';
import useAuthStatus from '../../../Hooks/useAuthStatus';
import getLocation from '../../../Utils/locationUtils';
import { searchProperties } from '../../../API/site/searchProperties';
import toast from 'react-hot-toast'
function Dropdown({ options, isActive, onSelect, showText = true, }) {
    if (!isActive) return null;

    return (
        <div className="absolute w-fit top-24 mt-2 text-xs text-black bg-white border rounded shadow-md z-10">
            {options.map((option) => (
                <div
                    key={option.id}
                    className="p-2 text-black hover:bg-gray-100 cursor-pointer"
                    onClick={() => onSelect(option.name)}
                >
                    {option.name}
                </div>
            ))}
        </div>
    );
}

function SearchMobile({ firstValue, secondValue, onSearchResult, CoordinateChange, showText, LocationDefined, value, onChange, onPress, onfirstOption, onsecondOption }) {
    const onNearMePress = () => {

        // Check if Geolocation is supported
        if (navigator.geolocation) {

            // Request location permission
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    toast.success("Getting Properties Near You...")
                    // Permission granted, handle success
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;

                    CoordinateChange({ latitude, longitude })

                    // Show loading alert


                    // Perform further actions with latitude and longitude
                    // ...

                },
                (error) => {
                    // Permission denied or other errors
                    if (error.code === error.PERMISSION_DENIED) {


                        // User denied the request for Geolocation
                        toast.error('Permission not granted. Please enable location services.');
                    } else {
                        // Handle other errors, e.g., position unavailable, timeout, etc.
                        toast.error(`Error getting location: ${error.message}`);
                    }
                }
            );
        } else {
            // Geolocation not supported by the browser
            toast.error('Geolocation is not supported by your browser');
        }
    };

    // Example usage:
    // Call onNearMePress when a button is pressed or any other event triggers it
    // e.g., <button onclick="onNearMePress()">Get Location</button>


    const [city, setCity] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedFirstOption, setSelectedFirstOption] = useState('Occupancy');
    const [selectedSecondOption, setSelectedSecondOption] = useState(secondValue ? secondValue : "Property Type");
    const [activeDropdown, setActiveDropdown] = useState(null);

    useEffect(() => {
        getLocation().then((res) => {
            console.log("YOUR LOCATION RES");
            console.log(res);

            setCity(res.addressDetails.city_district);
        }).catch((err) => {
            console.log(err);
        })
    }, []);
    const firstDropDownOptions = [
        { id: 1, name: 'Single' },
        { id: 2, name: 'Double' },
        { id: 3, name: 'Triple' }
    ];

    const secondDropDownOptions = [
        { id: 1, name: 'House / Apartment' },
        { id: 2, name: 'PG' }
    ];



    const handleOptionSelect = (optionName, dropdown) => {
        if (dropdown === 'first') {
            setSelectedFirstOption(optionName);
        } else if (dropdown === 'second') {
            setSelectedSecondOption(optionName);
        }
        setShowDropdown(false); // Close the dropdown
    };
    return (



        <div className="md:hidden  flex flex-col h-[35vh] justify-center items-center pt-16 w-screen">
            {true ?
                <div className="font-Montserrat mt-[33px]  flex  flex-col items-center justify-center ">
                    <div className='flex flex-col relative items-center '>
                        <div className=' w-[90%]    h-14 rounded-full flex flex-row  items-center gap-x-10 border-0 px-4 font-Montserrat bg-gradient-to-r from-[#F8F8F8] via-[#F8F8F8] to-[#AC6FFA]'>
                            <input

                                value={value}
                                onChange={onChange}
                                className="bg-transparent outline-none h-4 text-sm  w-full"
                                placeholder="Search by name or address..."
                            />
                            <button
                                onClick={onNearMePress}
                                className=" w-56 h-9 flex-row justify-center flex items-center gap-2 p-1 pl-4 pr-4 bg-[#D7BBFA] rounded-full">
                                <FontAwesomeIcon icon={faLocationCrosshairs} />
                                <span>
                                    near me</span>
                            </button>

                        </div>
                        <div className='flex flex-row items-center mt-2 bg-white rounded-full py-1'>
                            <div 
                                onClick={() => {
                                    setActiveDropdown(activeDropdown === 'first' ? null : 'first');
                                    setShowDropdown(prevState => !prevState);
                                }}
                                className=" bg-transparent w-28  flex-row justify-center border-r-[1px] h-full text-xs border-[#9F9F9F] flex items-center gap-2 cursor-pointer"
                            >
                                {selectedFirstOption}
                                <FontAwesomeIcon icon={faChevronDown} />
                                <Dropdown
                                    options={firstDropDownOptions}
                                    isActive={activeDropdown === 'first'}
                                    onSelect={(optionName) => {
                                        onfirstOption(optionName)
                                        handleOptionSelect(optionName, 'first');
                                        setShowDropdown(false);
                                    }}
                                />
                            </div>

                            <div
                                onClick={() => {
                                    setActiveDropdown(activeDropdown === 'second' ? null : 'second');
                                    setShowDropdown(prevState => !prevState);
                                }}
                                className=" bg-transparent w-28 text-xs flex-row justify-center h-full flex items-center gap-2 cursor-pointer"
                            >
                                {selectedSecondOption}
                                <FontAwesomeIcon icon={faChevronDown} />
                                <Dropdown

                                    options={secondDropDownOptions}
                                    isActive={activeDropdown === 'second'}
                                    onSelect={(optionName) => {
                                        onsecondOption(optionName)
                                        handleOptionSelect(optionName, 'second');
                                        setShowDropdown(false);
                                    }}
                                />
                            </div>
                            <button

                                onClick={onPress}
                                className=" h-full px-3 py-1 flex-row justify-center text-white  font-bold flex items-center gap-2 bg-[#AC6FFA] rounded-full">
                                <span>Search</span>
                            </button>
                        </div>


                    </div>
                </div>
                : null}

            {

            }
            {
                showText && <h1 className="text-white font-Montserrat font-semibold mt-4 text-center">
                    Properties {
                        LocationDefined ? "In & Near " + LocationDefined : city ? "In " + city : 'Near You'

                    }
                </h1>
            }

        </div>
    );
}

export default SearchMobile;
