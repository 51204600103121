import React, { useRef, forwardRef, useImperativeHandle } from 'react';

const AudioPlayer = forwardRef((props, ref) => {
  const audioRef = useRef(null);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  // Expose the playAudio function through the ref
  useImperativeHandle(ref, () => ({
    playAudio: playAudio
  }));

  return (
    <div>
      <audio ref={audioRef} src="https://dl.sndup.net/bfx5/R2ZWYCP-notification.mp3" />
    </div>
  );
});

export default AudioPlayer;
