import apiInstance from "..";
import cookie from 'react-cookies';
import { apiEndpoints } from "../constants";
const addProperty = async ({
    room_type,
    property_name,
    lat,
    long,
    price,
    occupancy,
    sex,
    contactNumber,
    address,
    rules,
    facilities,
    amenities,
    otherDetails,
    images,
    vacancy,
    meals,
    InstituteNearBy,
    occupancies,
    gender,
}) => {
    return new Promise(async (resolve, reject) => {

        const UserId = cookie.load('UserId');
        const Token = cookie.load('Token');

        try {
            // Check if any required field is empty
            const requiredFields = ['room_type', 'property_name', 'lat', 'long', 'price', 'occupancy', 'address', 'rules', 'facilities', 'amenities', 'images', 'InstituteNearBy'];
            const emptyFields = requiredFields.filter(field => !eval(field));
            if (emptyFields.length > 0) {
                reject(`The following fields are required: ${emptyFields.join(', ')}`);
                return;
            }
            if (!Array.isArray(images) || images.length < 2) {
                reject('Images must be an array with a minimum length of 2');
                return;
            }

            apiInstance.post(apiEndpoints.addPropertyApi, {
                PropertyType: room_type,
                PropertyName: property_name,
                Latitude: lat,
                Longitude: long,
                Price: price,
                occupancy,
                ContactNumber: contactNumber,
                PinCode: address.PinCode,
                City: address.City,
                State: address.State,
                Area: address.Area,
                Street: address.HouseNumber,
                Landmark: address.Landmark ? address.Landmark : "Property - ",
                Rules: rules,
                sex: "Male",
                Amenities: amenities,
                Floors: 2,
                Rooms: vacancy,
                Meals: meals,
                PropertyImages: images,
                Facilities: facilities,
                AdditionalNote: otherDetails,
                InstituteNearBy: InstituteNearBy,
                Occupancies: occupancies,
                Gender: gender
            }, {
                headers: {
                    UserId,
                    Token
                },
            }).then(res => {
                console.log(res.data)
                resolve(res.data)
            }
            ).catch(err => {
                console.log(err)
                reject(err)
            }
            )



        } catch (error) {
            reject(error);
        }
    });
}

export default addProperty;
