// Import React and CSS file
import * as React from 'react';
import Modal from '@mui/material/Modal';

import '../../Styles/Components/SubscriptionModal.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faClose, faCrown } from '@fortawesome/free-solid-svg-icons';
import { COLORS } from '../../Utils/Colors';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

// Create a ref for the modal
export const subscriptionModalRef = React.createRef();

// Expose a function to open the modal
export function openSubscriptionModal() {
    if (subscriptionModalRef.current) {
        subscriptionModalRef.current.handleOpen();
    }
}

export default function SubscriptionModal() {
    const [open, setOpen] = React.useState(false);

    // Expose the handleOpen function
    const handleOpen = () => setOpen(true);
    const handleClose = (event, reason) => {

        setOpen(false);
    }


    // Set the ref to the modal component
    React.useEffect(() => {
        subscriptionModalRef.current = { handleOpen };
    }, []);

    return (
        <Modal

            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"

            aria-describedby="modal-modal-description"
        >
            <div className="subscription-modal-container">

                <div className="subscription-modal-content">
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        cursor: 'pointer'
                    }}>
                        <span>
                            <FontAwesomeIcon
                                color={COLORS.Primary}
                                icon={faClose}
                                size="2x"
                                onClick={handleClose}
                            />
                        </span>
                    </div>
                    <div className="subscription-modal-text">
                        <div className="subscription-modal-header">
                            <div>
                                <span className="subscription-modal-header-text">
                                    Buy Premium and get unlimited access
                                </span>
                            </div>
                            <div className="subscription-modal-description">
                                Elevate your business with our Premium subscription.
                            </div>
                            <div className="subscription-modal-features">
                                <span>
                                    <span>
                                        <FontAwesomeIcon
                                            color={COLORS.Primary}
                                            icon={faCheckCircle}
                                            size="1x"
                                        />{" "}
                                    </span>
                                    Unlimited access to all features
                                </span>
                                <br />
                                <span>
                                    <span>
                                        <FontAwesomeIcon
                                            color={COLORS.Primary}
                                            icon={faCheckCircle}
                                            size="1x"
                                        />{" "}
                                    </span>
                                    Unlimited access to all features
                                </span>
                            </div>
                        </div>
                        <div className="subscription-modal-image">
                            <img
                                className="subscription-modal-image"
                                alt='PNG'
                                src='https://i.ibb.co/RhB1Njr/Moneyverse-Crypto-Galaxy.png'
                            />
                        </div>
                    </div>
                    <div className="subscription-modal-buttons">
                        <div style={{
                            paddingTop: '13px',
                            paddingBottom: '13px',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            borderRadius: '10px',
                            fontSize: '12px',
                            cursor: 'pointer',
                            background: 'linear-gradient(180deg, #AC6FFA 0%,  #7441D4 100%)',
                            color: 'white',
                            marginTop: '10px'
                        }}>
                            <span>
                                7 Days Free Trial
                            </span>
                        </div>
                        <div
                            style={{
                                marginTop: '10px',
                            }}
                        >
                            <span

                                style={{
                                    color: '#696969',
                                    fontSize: '12px',
                                }}>
                                Cancel anytime
                                <br />
                                Experience pro for 7 days
                            </span>



                        </div>
                        <div style={{
                            paddingTop: '13px',
                            paddingBottom: '13px',
                            paddingLeft: '50px',
                            paddingRight: '50px',
                            borderRadius: '10px',
                            fontSize: '12px',
                            cursor: 'pointer',
                            border: '1px solid #7441D4',
                            color: COLORS.Primary,
                            marginTop: '10px'
                        }}>
                            <span>
                                BASIC
                            </span>
                        </div>
                        <div style={{
                            paddingTop: '13px',
                            paddingBottom: '13px',
                            paddingLeft: '50px',
                            paddingRight: '50px',
                            borderRadius: '10px',
                            fontSize: '12px',
                            cursor: 'pointer',
                            background: '  #7441D4 ',
                            color: 'white',
                            fontWeight: 'bold',
                            marginTop: '10px'
                        }}>
                            <span>
                                <span>
                                    <FontAwesomeIcon
                                        color="white"
                                        icon={faCrown}
                                        size="1x"
                                    />
                                </span>  {"    "}  PRO
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
