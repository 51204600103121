import React from 'react'
import { Helmet } from 'react-helmet'
import Navbar from '../../Components/Navbar/Navbar'
import Footer from '../../Components/Footer/Footer'
export default function DealsandOffers() {
  const Coupons = [{ id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }, { id: 1 }]
  return (
    <>
      <Helmet>

        <title> Deals & Offers | Accomzy
        </title>
        <meta name="description" content="Connect with Accomzy – Your Ultimate Destination for Exceptional Accommodations. Reach out to our dedicated team for inquiries, support, or partnership opportunities. Your comfort is our priority, and we're here to make your stay extraordinary. Contact us today for personalized assistance and seamless travel experiences.">
        </meta>
        <meta name='og:title' content='Contact Us | Accomzy' />
        <meta name="og:description" content="Connect with Accomzy – Your Ultimate Destination for Exceptional Accommodations. Reach out to our dedicated team for inquiries, support, or partnership opportunities. Your comfort is our priority, and we're here to make your stay extraordinary. Contact us today for personalized assistance and seamless travel experiences.">
        </meta> <meta name='og:url' content='https://accomzy.in/ContactUs' />
        <meta name='og:type' content='website' />
        <meta name='og:site_name' content='Accomzy' />
        <meta name='og:locale' content='en_US' />

        <meta name='twitter:card' content='summary' />
        <meta name='twitter:site' content='@accomzy' />
        <meta name='twitter:creator' content='@accomzy' />
        <meta name='twitter:url' content='https://accomzy.in/' />


      </Helmet>
      <div className='Home-Navbar-Container'>



        <Navbar />
      </div>
      <div>
        <div style={{
          display: "flex",
          paddingTop: "106px",
          justifyContent: 'center',
          minHeight: "200px",
        }}>
          <div>
            <span style={{
              fontSize: "24px",
              color: 'white',
              fontFamily: "Montserrat",
              fontWeight: 'bold'
            }}>
              Grab  Amazing Deals
            </span>
          </div>
        </div>
        <div style={{
          marginTop: "48px",
          display: 'flex',
          width: "100%",
          justifyContent: "center",
          flexWrap: "wrap",
          minHeight: "2000px",
        }}>
          {
            Coupons.map((item, index) => {
              return (
                <div key={index} style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: "500px",
                  height: "300px",
                  backgroundColor: "white",
                  borderRadius: "20px",
                  marginRight: "20px",
                  backgroundImage: "url('https://i.ibb.co/Drd9Tff/Group-1839.png')",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  marginTop: "20px"
                }}>


                </div>
              )
            })
          }






          <Footer />
        </div>
      </div >

    </>
  )
}
