import React from 'react';
import '../../../../Styles/Site/Broker/PropertyForms/Section1.css';


export default function Section1({ formData, setFormData, onChange }) {
    const propertyTypes = [{
        id: 1,
        name: "House / Apartment",
    },
    {
        id: 2,
        name: "PG / Hostel",
    }
    ]
    const selectedType = formData.propertyType ?
        propertyTypes.find((propertyType) => propertyType.name === formData.propertyType)
        : {
            id: null,
            name: null
        }
    const [selectedPropertyType, setselectedPropertyType] = React.useState(selectedType);

    const handlePropertyTypeChange = (selectedType) => {

        setFormData({
            ...formData,
            propertyType: selectedType,
        });
    };



    return (
        <div className="Section1">
            <div className="Section1-content">
                <span className="Section1-text">
                    What Type Of {" "}
                    <span className="Section1-highlight">
                        property{" "}
                    </span>
                    do you want to list?
                </span>
            </div>

            <div

                style={{
                    marginTop: "50px"
                }}>

                {
                    propertyTypes.map((propertyType) => {
                        return (
                            <div

                                className='Section1-Hoverable-Button'
                                onClick={() => {
                                    setselectedPropertyType(propertyType)
                                    handlePropertyTypeChange(propertyType.name)
                                }

                                }
                                style={{
                                    textAlign: "center",
                                    backgroundColor: selectedPropertyType.id === propertyType.id ? "#676767" : null,
                                }}>
                                <span style={{
                                    color: "white",

                                }}>

                                    <span style={{


                                        paddingLeft: "50px"
                                    }}>
                                        {propertyType.name}
                                    </span>
                                </span>
                            </div>
                        )
                    }
                    )
                }



            </div>
        </div>
    );
}
