import React from 'react'

export default function GradientButton({ ButtonText, onClick, Loading }) {
    return (
        <div

            onClick={onClick}
            style={{
                background: 'linear-gradient(90deg, #7441D4 0%,  #AC6FFA 100%)',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: '16px',
                paddingBottom: '16px',
                width: '95%',
                borderRadius: '8px',

            }}>
            {
                Loading ? <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div> :

                    <span style={{
                        color: 'white',
                        fontFamily: 'Montserrat',
                        fontSize: '20px'
                    }}>
                        {ButtonText}
                    </span>

            }
        </div>
    )
}
