import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const ManualAd = () => {

    const imgAd = 'https://bestmediainfo.com/uploads/2019/03/VH_Bags_Campaign_3.jpg'
    const imgad2 = "https://as1.ftcdn.net/v2/jpg/01/31/25/62/1000_F_131256257_D2CABmYuUTXkwZPtx4XBOdoLMOseoGVs.jpg"
    const imgAd3 = "https://cdn.create.vista.com/downloads/b293f250-9619-40e7-ad92-1dd5dbcf518c_450.jpeg"
    return (
        <Carousel
            animationHandler={'fade'}
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            interval={3000}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                width: '100%',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    width: '60%',
                }}>
                    <img src={imgAd} alt="Slide 1" />

                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                width: '100%',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    width: '60%',
                }}>
                    <img src={imgad2} alt="Slide 1" />

                </div>
            </div><div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                width: '100%',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    width: '60%',
                }}>
                    <img src={imgAd3} alt="Slide 1" />

                </div>
            </div>
        </Carousel>
    );
};

export default ManualAd;
