import React from 'react';
import '../../../../Styles/Site/Broker/PropertyForms/Section2.css';

export default function Section2({ formData, onChange, setFormData }) {
    const avialable = [{
        id: 1,
        name: "Yes! I am at my property right now",
        value: true
    },
    {
        id: 2,
        name: "No! I am not at my property right now",
        value: false
    }
    ]
    const [atTheProperty, setAtTheProperty] = React.useState(0);

    return (
        <div className="Section2">
            <div className="Section2-content">
                <span className="Section2-text">
                    Are you at your {" "}
                    <span className="Section2-highlight">
                        property{" "}
                    </span>
                    right now?
                </span>
            </div>

            <div

                style={{
                    marginTop: "50px"
                }}>

                {
                    avialable.map((available) => {
                        return (
                            <div
                                className='Section2-Hoverable-Button'
                                onClick={() =>
                                    setFormData({
                                        ...formData,
                                        atYourProperty: available.value
                                    })
                                }
                                style={{

                                    backgroundColor: formData.atYourProperty === available.value ? "#676767" : null,
                                }}>
                                <span style={{
                                    color: "white"
                                }}>

                                    <span style={{

                                        paddingLeft: "50px"
                                    }}>
                                        {available.name}
                                    </span>
                                </span>
                            </div>
                        )
                    }
                    )
                }



            </div>
        </div>
    );
}
