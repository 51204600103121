import React, { useEffect, useState } from 'react';
import Navbar from '../../../Components/Navbar/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import cookie from 'react-cookies';
import { COLORS } from '../../../Utils/Colors';
import profile from '../../../Assets/accomzyUser.png'
import fadeGrid from '../../../Assets/fadeGrid.png'
import toast from 'react-hot-toast';
import PropertyCard from '../../../Components/Card/propertyCard';
import propertyImage from '../../../Assets/roomImage/property.png';
import { getProfile } from '../../../API/site/getProfile';
import { getYourListedProfile } from '../../../API/Broker/getYourListedProperties';

export default function BrokerProfile() {
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);
  const [editedPhoneNumber, setEditedPhoneNumber] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [listedProperty, setListedProperty] = React.useState([])


  const [data, setData] = useState({
    name: "John Doe",
    phone_number: "9876543210",
  });


  const handleSaveName = () => {
    setData(prevData => ({ ...prevData, name: editedName })); // Update data.name
    setIsEditingName(false);
    toast.success('Name saved successfully!');
  };

  const handleSavePhoneNumber = () => {
    const regex = /^[0-9]{10}$/;
    if (regex.test(editedPhoneNumber)) {
      setData(prevData => ({ ...prevData, phone_number: editedPhoneNumber })); // Update data.phone_number
      setIsEditingPhoneNumber(false);
      toast.success('Phone number saved successfully!');
    } else {
      toast.error('Invalid phone number. Please enter 10 digits.');
    }
  };



  const handleImageUpload = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.click();

    fileInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedImage(file);
        toast.success('Image uploaded successfully!');
        // Here, you can call a function to upload the image to your server or cloud storage
        // uploadImageToServer(file);
      }
    });
  };


  useEffect(() => {
    // Fetch owner profile
    getProfile().then((profileData) => {
      console.log("Profile Data")
      console.log(profileData);

      console.log("Profile Data")
      setData(profileData.userData);
    }
    ).catch(err => {
      console.log(err)
    })

    getYourListedProfile().then(listedProperties => {
      console.log("+++++")
      console.log(listedProperties)
      setListedProperty(listedProperties)
      console.log("+++++")
    })

  }, []);





  return (
    <div className="min-h-screen">
      <div className="Home-Navbar-Container fixed w-full z-50">
        <Navbar />
      </div>
      <div className='fixed w-full'>
        <img src={fadeGrid} className="absolute top-10 right-0 " />
        <img src={fadeGrid} className="absolute top-10 left-0 rotate-180 " />
      </div>


      <div className="Home-Main-Container bg-black items-center justify-center flex min-h-screen">

        <div className='border-[1px] border-[#242424] h-auto w-[75%] mt-[15%] rounded-md'
          style={{
            background: 'linear-gradient(180deg, rgba(53, 53, 53, 0.20) 0%, rgba(68, 68, 68, 0.00) 100%)'
          }}>

          <div className="relative bottom-28 flex flex-col items-center">
            {/* Profile Image */}
            <div className="mb-4 mt-4">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: 'none' }} // Hide the input
              />

              {/* Display the selected image preview */}
              {selectedImage ? (
                <div className="mt-4">
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                    className="w-32 h-32 rounded-full object-cover"
                  />
                </div>
              ) : (
                <div className="mt-4">
                  <img
                    src={data.ProfilePhoto}
                    alt="Selected"
                    className="w-32 h-32 rounded-full object-cover"
                  />
                </div>
              )}

              <button
                onClick={handleImageUpload}
                className="relative left-24 bottom-8 bg-purple-500 px-2 py-1 rounded-full cursor-pointer"
              >
                <FontAwesomeIcon icon={faPen} color="white" className='h-4 w-4' />
              </button>

            </div>


            <div className='relative bottom-8 ml-5'>
              {/* Edit Name */}
              {isEditingName ? (
                <div className="mb-4 flex items-center">
                  <input
                    type="text"
                    className="border border-gray-300 bg-transparent text-white font-bold p-2 focus:outline-none focus:border-purple-500 flex-grow"
                    value={editedName}
                    onChange={(e) => setEditedName(e.target.value)}
                  />
                  <button className="ml-2 p-2 bg-blue-500 text-white rounded-md" onClick={handleSaveName}>Save</button>
                  <button className="ml-2 p-2 bg-red-500 text-white rounded-md" onClick={() => setIsEditingName(false)}>Cancel</button>
                </div>
              ) : (
                <div className="mb-4">
                  <span className="text-xl font-bold text-white">{data.Name}</span>
                  <FontAwesomeIcon
                    icon={faPen}
                    className="ml-2 w-3 h-3 cursor-pointer text-white"
                    onClick={() => {
                      setEditedName(data.name);
                      setIsEditingName(true);
                    }}
                  />
                </div>
              )}

              {/* Edit Phone Number */}
              {isEditingPhoneNumber ? (
                <div className="mb-4 flex items-center">
                  <input
                    type="tel"
                    maxLength="10"
                    className="border border-gray-300 bg-transparent text-white font-bold p-2 focus:outline-none focus:border-purple-500 flex-grow"
                    value={editedPhoneNumber}
                    onChange={(e) => setEditedPhoneNumber(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key !== 'Backspace' && isNaN(e.key)) {
                        e.preventDefault();
                        toast.error('Only numbers are allowed for phone number.');
                      }
                    }}
                  />

                  <button className="ml-2 p-2 bg-purple-500 text-white rounded-md" onClick={handleSavePhoneNumber}>Save</button>
                  <button className="ml-2 p-2 bg-red-500 text-white rounded-md" onClick={() => setIsEditingPhoneNumber(false)}>Cancel</button>
                </div>
              ) : (
                <div className="mb-4">
                  <span className="text-lg text-white">{data.PhoneNumber}</span>
                  <FontAwesomeIcon
                    icon={faPen}
                    className="ml-2 w-3 h-3 cursor-pointer text-white"
                    onClick={() => {
                      setEditedPhoneNumber(data.phone_number);
                      setIsEditingPhoneNumber(true);
                    }}
                  />
                </div>
              )}

            </div>
            <div className="mt-8 flex flex-col items-center justify-center w-full">
              <span className="text-2xl font-bold border-b-2 text-center " style={{ color: COLORS.Primary, borderColor: COLORS.Primary }}>PROPERTIES YOU OWN</span>

              <div className="mt-4 flex gap-x-6 mb-2 mx-4 flex-wrap justify-center">
                {listedProperty.map((property) => (
                  <div className='mb-4'>
                    <PropertyCard
                      Approved={property.Approved}
                      key={property.id}
                      PropertyName={property.PropertyName}
                      Price={property.Price}
                      PropertyImage={property.PropertyImages[0]}
                      location={property.Landmark + ", " + property.City + ", " + property.State}
                      Reviews={JSON.parse(property.Reviews)}
                      price={property.price}
                    />
                  </div>
                ))}
              </div>
            </div>

          </div>



        </div>

        <div>
          <button
            onClick={() => {
              cookie.remove('Token', { path: '/' })
              window.location.href = "/"
            }}
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              padding: '20px',
              borderRadius: '50%',


              color: 'white',
              fontWeight: 'bold',
              fontSize: '16px',
              cursor: 'pointer',
            }}
          >
            <div className="navbar-signup" >
              Sign Out
            </div>
          </button>
        </div>
      </div>

    </div>
  );
}
