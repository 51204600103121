// TestimonialCard.jsx
import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import '../../Styles/Home/TestimonialCard.css';
import { openSubscriptionModal } from '../Modals/SubscriptionModal';



const TestimonialCard = ({ Name, Review, ProfileUrl }) => {

    const [ref, inView] = useInView({
        triggerOnce: true, // Only trigger once
        onLeave: () => {
            setIsVisible(false);
        },

    });
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (inView) {
            setIsVisible(true);

        }

    }, [inView]);
    return (
        <div className='w-full mt-20  md:h-[520px] '>
            <div ref={ref} className={`TestimonialCard-container   ${isVisible ? 'visible' : ''}`}>
            <div className="TestimonialCard-image">
                <div>
                    <img
                        style={{
                            objectFit: 'cover',
                        }}
                        className="TestimonialCard-avatar"
                        src={ProfileUrl}
                        alt="FakeImage"
                    />
                </div>
                <div style={{ marginTop: '16px' }}>
                    <span className="TestimonialCard-name">{Name}</span>
                </div>
            </div>
            <div className="TestimonialCard-quoteWrapper">
                <span className="TestimonialCard-quoteIcon">“</span>
                <div className="TestimonialCard-quoteText">
                    {Review}
                </div>
                <div className="TestimonialCard-quoteEndIcon">”</div>
            </div>
        </div>
        </div>
    );
};

export default TestimonialCard;
