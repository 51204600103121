import { useEffect, useState } from "react";
import cookie from 'react-cookies';
import verifySession from "../API/auth/verifySession";


const useAuthStatus = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [verificationComplete, setVerificationComplete] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isUser, setIsUser] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [isOwner, setIsOwner] = useState(false);


    useEffect(() => {
        const verifyAuth = async () => {
            const Token = cookie.load('Token');
            const UserId = cookie.load('UserId');
            if (Token && !isAuthenticated) {
                try {
                    const verified = await verifySession();
                    setIsAuthenticated(verified);
                    setIsUser(verified.data.is_user);
                    setIsOwner(verified.data.is_owner);

                } catch (error) {
                    setIsAuthenticated(false);
                }
            }

            setIsLoading(false);
            setVerificationComplete(true);
        };

        verifyAuth();
    }, [isAuthenticated]);

    return { isAuthenticated, isLoading, verificationComplete, isUser, isOwner };
};

export default useAuthStatus;
