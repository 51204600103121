import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import '../../../Styles/Site/Exlpore/RoomCardNew.css';
import { useNavigate } from 'react-router-dom';
import { RouteEndpoints } from '../../../Utils/RouterConsts';




function RoomCardNew({ roomCard }) {
    const navigate = useNavigate()
    const [mainImage, setMainImage] = useState(roomCard.PropertyImages[0]);
    const Reviews = (roomCard.Reviews);
    const rating = 1
    // Reviews.length === 0 ? 0 :
    //     Reviews.reduce((acc, curr) => acc + curr.Rating, 0) / Reviews.length;



    return (
        <div className='super-container'>
            <div className='main-container'>
                <div style={{ padding: 20, borderRadius: '12px', cursor: 'pointer' }}>
                    <img
                        src={mainImage}
                        alt="room"
                        height={250}
                        width={430}
                        style={{ borderRadius: '10px' }}
                    />
                </div>
                <div className='small-image-container'>
                    <div style={{ padding: 8, cursor: 'pointer' }}>
                        <img
                            src={roomCard.PropertyImages[0] ? roomCard.PropertyImages[0] : roomCard.PropertyImages[0]}
                            alt="room"
                            height={50}
                            width={70}
                            onClick={() => setMainImage(roomCard.PropertyImages[0] ? roomCard.PropertyImages[0] : roomCard.PropertyImages[0])}
                            style={{ borderRadius: '8px' }}

                        />
                    </div>
                    <div style={{ padding: 8, cursor: 'pointer' }}>
                        <img
                            src={roomCard.PropertyImages[1] ? roomCard.PropertyImages[1] : roomCard.PropertyImages[0]}
                            alt="room"
                            height={50}
                            width={70}
                            onClick={() => setMainImage(roomCard.PropertyImages[1] ? roomCard.PropertyImages[1] : roomCard.PropertyImages[0])}
                            style={{ borderRadius: '8px' }}

                        />
                    </div>
                    <div style={{ padding: 8, cursor: 'pointer' }}>
                        {roomCard.PropertyImages[2] &&
                            <img
                                src={roomCard.PropertyImages[2] ? roomCard.PropertyImages[2] : roomCard.PropertyImages[0]}
                                alt="room"
                                height={50}
                                width={70}
                                onClick={() => setMainImage(roomCard.PropertyImages[2] ? roomCard.PropertyImages[2] : null)}
                                style={{ borderRadius: '8px' }}

                            />
                        }
                    </div>
                    <div style={{ padding: 8, cursor: 'pointer' }}>
                        {roomCard.PropertyImages[3] &&
                            <img
                                src={roomCard.PropertyImages[3] ? roomCard.PropertyImages[3] : roomCard.PropertyImages[0]}
                                alt="room"
                                height={50}
                                width={70}
                                onClick={() => setMainImage(roomCard.PropertyImages[3] ? roomCard.PropertyImages[3] : roomCard.PropertyImages[0])}
                                style={{ borderRadius: '8px' }}
                            />
                        }
                    </div>
                </div>

                <div className="container">
                    <h1 className="heading1">{roomCard.PropertyName}</h1>

                    <div className="address">
                        <p className="addressText">{""}</p>
                        <span>&bull;</span>
                        <p className="addressText">{""}</p>
                    </div>

                    <div className="starRating">
                        <FontAwesomeIcon icon={faStar} className="starIcon" />
                        <p className="ratingText"> {(rating).toFixed(2)} </p>
                        <p className="reviewCount">({"reviews"})</p>
                    </div>

                    <h1 className="price">₹{parseFloat(roomCard.Price).toFixed(2)}</h1>

                    <div className="buttons">
                        <button
                            onClick={() => {
                                navigate((RouteEndpoints.PropertyDetails).replace(':id', roomCard.PropertyId))
                            }}
                            className="customButton">View Details</button>
                        <button className="customButton bookNowButton">Contact Owner</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RoomCardNew;
