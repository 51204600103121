import React, { useState } from 'react';
import ProgressBar from '../../../Components/Site/Broker/PropertyForms/ProgressBar';
import Section1 from '../../../Components/Site/Broker/PropertyForms/Section1';
import Section2 from '../../../Components/Site/Broker/PropertyForms/Section2';
import Section3 from '../../../Components/Site/Broker/PropertyForms/Section3';
import Section4 from '../../../Components/Site/Broker/PropertyForms/Section4';
import Section5 from '../../../Components/Site/Broker/PropertyForms/Section5';
import Section6 from '../../../Components/Site/Broker/PropertyForms/Section6';
import Navbar from '../../../Components/Navbar/Navbar';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { COLORS } from '../../../Utils/Colors';
import Footer from '../../../Components/Footer/Footer';
import { storage } from '../../../Utils/firebase';
import addProperty from '../../../API/Broker/addProperty';
import { useNavigate } from 'react-router-dom';
const EnlistProperty = () => {
    const [flashBackground, setFlashBackground] = useState(false);
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const totalSections = 6;
    const [formData, setFormData] = useState({

        propertyType: null,
        atYourProperty: false,
        price: 0,
        vacancy: 8,
        InstituteNearBy: "",
        propertyAddress: {
            HouseNumber: null,
            Area: null,
            City: null,
            State: null,
            Country: null,
            PinCode: null,
            latitude: null,
            longitude: null,
        },
        // numberOfFloors: 0,
        // numberOfRooms: 0,
        contactNumber: "",
        sex: "Male",
        occupancy: 12,

        propertyName: "",
        amenities: [],
        facilities: [],
        occupancies: [],
        meals: [],
        rules: [],
        otherDetails: '',
        propertyImages: [],
        gender: null,
        propertyImageURLs: [],
        // Add other fields for different sections
    });
    const [currentSection, setCurrentSection] = useState(1);
    // Function to handle form data changes
    const handleInputChange = (event) => {
    };
    // Function to handle section change
    const handleNext = () => {

        if (currentSection === 1 && formData.propertyType === null) {
            toast.error("Please select a property type")
            return
        }

        if (currentSection === 2 && formData.atYourProperty === null) {
            toast.error("Please select an option")
            return
        }
        if (currentSection === 3) {
            for (const key in formData.propertyAddress) {
                if (formData.propertyAddress[key] === null) {
                    setFlashBackground(flashBackground => !flashBackground)
                    toast.error(`Please fill the ${key} field`);
                    return;
                }
            }
        }
        if (currentSection === 4) {
            // if (formData.numberOfFloors == 0) {
            //     toast.error("Please select a number of floors");
            //     return
            // }
            if (formData.numberOfRooms == 0) {
                toast.error("Please select a number of rooms");
                return
            }
            if (formData.contactNumber.length < 10) {
                toast.error("Please enter a valid contact number");
                return
            }
            if (formData.InstituteNearBy.length < 3) {
                toast.error("Please enter a valid Institute name");
                return
            }
            if (formData.propertyName.length < 3) {
                toast.error("Property name should be atleast 3 characters long");
                return
            }







        }
        if (currentSection === 5) {
            if (formData.occupancies.length === 0) {
                toast.error("Please select an occupancy")
                return
            }
            if (formData.meals.length === 0) {
                toast.error("Please select at-least one meal")
                return
            }
            if (formData.rules.length === 0) {
                toast.error("Please select at-least one rule")
                return
            }
            if (formData.facilities.length === 0) {
                toast.error("Please select at-least one facility")
                return
            }
            if (formData.amenities.length === 0) {
                toast.error("Please select at-least one amenity")
                return
            }
            if (formData.gender == "" || formData.gender == null) {
                toast.error("Please select a gender")
                return;

            }


        }

        if (currentSection == totalSections) {
            alert("Your property has been listed successfully")
            return
        }
        setCurrentSection((prevSection) => prevSection + 1);
    };

    const handleBack = () => {
        setCurrentSection((prevSection) => prevSection - 1);
    };
    // Function to handle form submission
    const handleSubmit = async () => {
        const imgArr = []
        setLoading(true);
        try {

            for (const image of formData.propertyImages) {
                const randomId = Math.floor(Math.random() * 100000000000000000);
                const randomImageName = Math.floor(Math.random() * 100000000000000000);
                const snapshot = await storage
                    .ref(`images/${randomImageName + randomId}`)
                    .put(image);

                console.log(snapshot);

                const url = await snapshot.ref.getDownloadURL();
                imgArr.push(url)

            }
            console.log("UPLOADED IMAGES")
            console.log(formData.propertyImageURLs);
            console.log("UPLOADED IMAGES")



            formData.propertyImages = imgArr
            console.log(formData)
            addProperty({

                address: formData.propertyAddress,
                amenities: formData.amenities,
                facilities: formData.facilities,
                images: formData.propertyImages,
                lat: formData.propertyAddress.latitude,
                long: formData.propertyAddress.longitude,
                occupancy: formData.occupancy,
                meal: formData.meals,
                price: formData.price,
                property_name: formData.propertyName,
                room_type: formData.propertyType,
                rules: formData.rules,
                sex: formData.sex,
                meals: formData.meals,
                otherDetails: formData.otherDetails,
                contactNumber: formData.contactNumber,
                vacancy: formData.vacancy,
                InstituteNearBy: formData.InstituteNearBy,
                occupancies: formData.occupancies,
                gender: formData.gender
            }).then(res => {
                console.log(res)
                toast.success("Property Has Been Sent To Support System For Approval ")
                window.location.href = res.data.payment_url

            }).catch(err => {
                console.log(err)
                const errMessage = err.response?.data?.message || "Error listing property"
                toast.error(errMessage)
            })


        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <span style={{
                color: "white",
            }}>
                {/* {
                    JSON.stringify(formData)
                } */}
            </span>

            <Helmet>
                <title>List Your Property | Accomzy</title>
                <meta name="description" content="List your property with Accomzy and get the best tenants. We provide you with the best services and ensure that you get the best tenants for your property.">
                </meta>
            </Helmet>
            <div style={{

                fontFamily: 'Plus Jakarta Sans',
                width: '100vw',
                height: '100vh',
                backgroundColor: 'black',
                backgroundImage: 'url(https://i.ibb.co/ygcc3XX/Group-436.png)',
                backgroundPosition: 'center   50vh',

                backgroundRepeat: 'no-repeat',
                overflowX: 'hidden',
                //flip the image

            }}>

                <Navbar showOptions={false} authMethods={false} />

                <div style={{
                    display: "block",
                    margin: "35px",
                    marginTop: "40px"
                }}>
                    <ProgressBar currentSection={currentSection} totalSections={totalSections} />
                    <div style={{
                        marginTop: "50px",
                        minHeight: "65vh"
                    }}>
                        {currentSection === 1 && <Section1 formData={formData}
                            setFormData={setFormData}
                            onChange={handleInputChange} />}
                        {currentSection === 2 && <Section2 formData={formData}
                            setFormData={setFormData}
                            onChange={handleInputChange} />}
                        {currentSection === 3 && <Section3
                            flashBackground={flashBackground}
                            formData={formData}
                            setFormData={setFormData}

                            onChange={handleInputChange} />}

                        {currentSection === 4 && <Section4 formData={formData}
                            setFormData={setFormData}
                            onChange={handleInputChange} />}
                        {currentSection === 5 && <Section5
                            setFormData={setFormData}
                            formData={formData} onChange={handleInputChange} />}
                        {currentSection === 6 && <Section6
                            setFormData={setFormData}
                            formData={formData} onChange={handleInputChange} />}


                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            flexWrap: "wrap",
                        }}
                    >{
                            currentSection !== 1 &&

                            <div
                                onClick={handleBack}
                                style={{
                                    border: "1px solid white",
                                    color: "white",
                                    paddingLeft: "60px",
                                    paddingRight: "60px",
                                    paddingTop: "10px",
                                    paddingBottom: "10px",
                                    borderRadius: "5px",
                                    marginLeft: "20px",
                                    marginTop: "5px",
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer"


                                }}>
                                <span>
                                    Back
                                </span>
                            </div>
                        }
                        <div
                            onClick={
                                currentSection === 6 ? handleSubmit : handleNext
                            }
                            style={{
                                fontWeight: "bold",
                                border: "1px solid ",
                                borderColor: COLORS.Primary,
                                color: "black",
                                backgroundColor: COLORS.Primary,
                                paddingLeft: "60px",
                                paddingRight: "60px",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                borderRadius: "5px",
                                marginLeft: "20px",
                                marginTop: "5px",
                                fontSize: "14px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer"

                            }}>
                            {
                                loading ?
                                    <div className='spinner-border' role='status'>
                                        <span className='sr-only'>Loading...</span>
                                    </div>
                                    : <span>
                                        {
                                            currentSection === totalSections ? "Submit" : "Next"
                                        }
                                    </span>

                            }

                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>

    );
};

export default EnlistProperty;
