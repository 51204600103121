// Section4.jsx

import React, { useEffect, useState } from 'react';
import '../../../../Styles/Site/Broker/PropertyForms/Section4.css';
import PrimaryInput from '../../../Input/PrimaryInput';

import { getAllInstitutes } from '../../../../API/site/getAllInstitutes';
import AutoComplete from '../../../AutoComplete/AutoComplete';

export default function Section4({ formData, onChange, setFormData }) {


  const [collegeSearchQuery, setCollegeSearchQuery] = useState("")
  const [colleges, setColleges] = useState([]);
  useEffect(() => {
    getAllInstitutes({
      searchQuery: collegeSearchQuery
    }).then((CollegeData) => {
      console.log("CollegeData");
      console.log(CollegeData.institutes);
      console.log("CollegeData");

      setColleges(CollegeData.institutes);
    }).catch((err) => {
      console.log(err);
    }
    )

  }, [collegeSearchQuery]);


  const onPropertyNameChange = (e) => {

    setFormData({
      ...formData,
      propertyName: e.target.value
    })
  }


  return (
    <div className="Section4">
      <div className="Section4-content">
        <span className="Section4-text">
          Property{" "}
          <span className="Section4-highlight">
            information{" "}
          </span>
        </span>
        <br />
      </div>

      <div className="Section4-description">
        Please enter true information about your property
      </div>

      <div className="Section4-form">
        <div className="Section4-contact">
          <div className="Section4-contact-label">
            <span>Name Of Your Property  :</span>
          </div>
          <div className="Section4-contact-input">


            <PrimaryInput
              value={formData.propertyName}
              onChange={onPropertyNameChange}
              bgColor="#000000"

              Placeholder="Enter Name of your property"
              marginTop="0px"
              width="100%"
            />
          </div>
          <div className="Section4-contact">
            <div className="Section4-contact-label">
              <span>Near By Institute  :</span>
            </div>
            <div className="Section4-contact-input">
              <AutoComplete
                inputOnChange={
                  (e) => {

                    const numRegex = /^[0-9\b]+$/;
                    if (true) {
                      setCollegeSearchQuery(e.target.value);
                      setFormData({
                        ...formData,
                        InstituteNearBy: e.target.value
                      })
                    }

                  }

                }
                onSelected={(suggestion) => {

                  setFormData({
                    ...formData,
                    InstituteNearBy: suggestion
                  })

                }}
                suggestions={colleges}
                value={formData.InstituteNearBy}
              />
              {/* <PrimaryInput
                id={"InstituteNearBy"}

                value={formData.InstituteNearBy}
                onChange={
                  (e) => {

                    const numRegex = /^[0-9\b]+$/;
                    if (true) {
                      setCollegeSearchQuery(e.target.value);
                      setFormData({
                        ...formData,
                        InstituteNearBy: e.target.value
                      })
                    }

                  }
                }

                bgColor="#000000"

                Placeholder="Search For A NearBy Institute"
                marginTop="0px"
                width="100%"
              /> */}

            </div>
            <div>


            </div>
            <div>

            </div>
          </div>
        </div>

        <div className="Section4-input-container">
          <div className="Section4-input-label">
            <span>Price (INR) / Month</span>
          </div>
          <div className="Section4-input">
            <div className="Section4-input" >
              <PrimaryInput
                onChange={
                  (e) => {

                    const numRegex = /^[0-9\b]+$/;
                    if (e.target.value === '' || numRegex.test(e.target.value)) {
                      setFormData({
                        ...formData,
                        price: e.target.value
                      })
                    }

                  }
                }
                value={formData.price}
                bgColor="#000000"
                type="tel"

                marginTop="0px"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div className="Section4-input-container">
          <div className="Section4-input-label">
            <span>Number of Rooms</span>
          </div>
          <div className="Section4-input">
            <div className="Section4-input" >
              <PrimaryInput
                onChange={
                  (e) => {

                    const numRegex = /^[0-9\b]+$/;
                    if (e.target.value === '' || numRegex.test(e.target.value)) {
                      setFormData({
                        ...formData,
                        numberOfRooms: e.target.value
                      })
                    }

                  }
                }
                value={formData.numberOfRooms}
                bgColor="#000000"
                type="tel"
                maxLength={3}
                marginTop="0px"
                width="100%"
              />
            </div>
          </div>
        </div>
        <div className="Section4-contact">
          <div className="Section4-contact-label">
            <span>Contact Number :</span>
          </div>
          <div className="Section4-contact-input">
            <PrimaryInput
              id={"PhoneNumber"}

              value={formData.contactNumber}
              onChange={
                (e) => {

                  const numRegex = /^[0-9\b]+$/;
                  if (e.target.value === '' || numRegex.test(e.target.value)) {
                    setFormData({
                      ...formData,
                      contactNumber: e.target.value
                    })
                  }

                }
              }
              type="tel"
              bgColor="#000000"
              maxLength={10}
              Placeholder="Enter your contact number"
              marginTop="0px"
              width="100%"
            />
          </div>
        </div>


      </div>

    </div>
  );
}
