
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries


const firebaseConfig = {
    apiKey: "AIzaSyBHQ2JfeTUo8Ca-SRuBlKs5SyqXPxccyxA",
    authDomain: "accomzy-75b18.firebaseapp.com",
    databaseURL: "https://accomzy-75b18-default-rtdb.firebaseio.com",
    projectId: "accomzy-75b18",
    storageBucket: "accomzy-75b18.appspot.com",
    messagingSenderId: "1049468474000",
    appId: "1:1049468474000:web:d3146902be3b41e9c73e15",
    measurementId: "G-SDZN2PGVS6"
};

firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
let realtime = firebase.database();
let db = firebase.firestore();
let storage = firebase.storage();
export { firebase, auth, storage, realtime };