import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

const MobileManualAd = () => {
    const imgAd = 'https://bestmediainfo.com/uploads/2019/03/VH_Bags_Campaign_3.jpg';
    const imgad2 = "https://as1.ftcdn.net/v2/jpg/01/31/25/62/1000_F_131256257_D2CABmYuUTXkwZPtx4XBOdoLMOseoGVs.jpg";
    const imgAd3 = "https://cdn.create.vista.com/downloads/b293f250-9619-40e7-ad92-1dd5dbcf518c_450.jpeg";

    return (
        <Carousel
            animationHandler={'fade'}
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            interval={3000}
            renderArrowPrev={(onClickHandler, hasPrev) =>
                hasPrev && (
                    <button
                        type="button"
                        onClick={onClickHandler}
                        title="Previous"
                        style={{
                            left: 15,
                            zIndex: 2,
                            display: 'none', // Set display to 'none' on small screens
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        Previous
                    </button>
                )
            }
            renderArrowNext={(onClickHandler, hasNext) =>
                hasNext && (
                    <button
                        type="button"
                        onClick={onClickHandler}
                        title="Next"
                        style={{
                            right: 15,
                            zIndex: 2,
                            display: 'none', // Set display to 'none' on small screens
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        Next
                    </button>
                )
            }
        >
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>
                <img src={imgAd} alt="Slide 1" style={{ width: '100%' }} />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>
                <img src={imgad2} alt="Slide 1" style={{ width: '100%' }} />
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}>
                <img src={imgAd3} alt="Slide 1" style={{ width: '100%' }} />
            </div>
        </Carousel>
    );
};

export default MobileManualAd;
