// login.jsx

import React, { useState } from 'react';
import PrimaryInput from '../../Components/Input/PrimaryInput';
import GradientButton from '../../Components/Buttons/GradientButton';
import '../../Styles/Auth/Login.css'
import GrayComponentWithLine from '../../Components/Input/Line';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import cookie from 'react-cookies';

import toast from 'react-hot-toast';
import { appImages } from '../../Utils/Constants';
import { RouteEndpoints } from '../../Utils/RouterConsts';
import sendOTP from '../../API/auth/sendOTP';
import verifyOTP from '../../API/auth/verifyOTP';
import { loginUser } from '../../API/auth/loginUser';
const regexForNumbers = /^[0-9]+$/;

export default function Login() {
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [otp, setOTP] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [showOTP, setShowOTP] = React.useState(false);
    const [verificationCode, setVerificationCode] = useState("")
    const navigate = useNavigate();
    const handleSendOTP = async () => {

        setLoading(true)
        await sendOTP({

            phoneNumber
        }).then((response) => {
            console.log(response)
            toast.success(response.message)
            setVerificationCode(response.verificationCode)
            setShowOTP(true)
        }
        ).catch((error) => {
            console.log(error)
            toast.error(error)
        }).finally(() => {
            setLoading(false)
        }
        )

    }

    const handleVerifyOTP = async () => {

        setLoading(true)
        await verifyOTP({
            PhoneNumber: phoneNumber,
            OTP: otp,
            VerificationCode: verificationCode
        }).then(async (response) => {
            console.log(response)


            toast.success(response.message)
            await loginUser({
                PhoneNumber: phoneNumber
            }).then(data => {
                cookie.save("UserId", data.UserId)
                cookie.save("Token", data.Token)
                navigate('/')
            }).catch(err => {

                console.log(err)
                const alertMessage = err.response.data.message?.toString() || err.message?.toString() || "Something went wrong"
                toast.error(alertMessage)
                if (err.response.status === 404) {
                    navigate(RouteEndpoints.Register)
                }

            })


        }
        ).catch((error) => {
            console.log(error)
            toast.error(error)
        }).finally(() => {
            setLoading(false)
        }
        )

    }



    const onChangePhoneNumber = (e) => {
        //check regex 
        const phoneRegex = /^[0-9\b]+$/;

        setPhoneNumber(e.target.value)


    }

    const onOTPChange = (e) => {
        const otpRegex = /^[0-9\b]+$/;
        if (otpRegex.test(e.target.value) || e.target.value === '') {
            setOTP(e.target.value)
        }
    }


    return (
        <div className="login-container">

            <Helmet>
                <title>Login | Accomzy
                </title>
                <meta name="description" content="Login to your Accomzy account to continue exploring accommodation.">
                </meta>
                <meta name="keywords" content="Accomzy, Accommodation, Accommodation in India, Accommodation in Delhi, Accommodation in Mumbai, Accommodation in Bangalore, Accommodation in Chennai, Accommodation in Kolkata, Accommodation in Hyderabad, Accommodation in Pune, Accommodation in Ahmedabad, Accommodation in Surat, Accommodation in Jaipur, Accommodation in Lucknow, Accommodation in Kanpur, Accommodation in Nagpur, Accommodation in Indore, Accommodation in Thane, Accommodation in Bhopal, Accommodation in Visakhapatnam, Accommodation in Pimpri-Chinchwad, Accommodation in Patna, Accommodation in Vadodara, Accommodation in Ghaziabad, Accommodation in Ludhiana, Accommodation in Agra, Accommodation in Nashik, Accommodation in Faridabad, Accommodation in Meerut, Accommodation in Rajkot, Accommodation in Kalyan-Dombivli, Accommodation in Vasai-Virar, Accommodation in Varanasi, Accommodation in Srinagar, Accommodation in Aurangabad, Accommodation in Dhanbad, Accommodation in Amritsar, Accommodation in Navi Mumbai">
                </meta>
                <meta name='og:title' content='Login | Accomzy' />
                <meta name='og:description' content="Login to your Accomzy account to continue exploring accommodation." />
                <meta name='og:url' content='https://accomzy.com/Auth/Login' />
                <meta name='og:image' content='https://i.ibb.co/wRs2VQX/file-cover-1.png' />
            </Helmet>
            <div className="left-section">
                <div className="background-dots">
                    <img src="https://i.ibb.co/Hpxx57d/bgDots.png" alt="Description" />
                </div>
                <div className="title fade-in" style={{
                    paddingLeft: '32px',
                }}>
                    <span>
                        Exploring Accommodation <br /> Made Easy
                    </span>
                </div>
            </div>
            <div className="right-section ">

                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '24px',
                    marginRight: '32px'
                }}>
                    <img src={appImages.LOGO} width={82} alt="AccomzyLogo" />
                </div>
                <div className="login-form fade-in">
                    <div>
                        <span style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '38px',
                            fontFamily: 'Montserrat'
                        }}>
                            Login
                        </span>
                        <br />
                        <span style={{
                            color: 'white',
                            fontSize: '16px',
                            color:'#9F9F9F',

                        }}>
                            Start right where you left off...
                        </span>
                    </div>
                    <div className="input-fields">
                        <div>
                            <PrimaryInput
                                id={"PhoneNumber"}
                                regex={regexForNumbers}
                                disabled={showOTP}
                                maxLength={10}
                                value={phoneNumber}
                                type={"tel"}
                                handleKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        showOTP ? handleVerifyOTP() : handleSendOTP()
                                    }
                                }}
                                onChange={onChangePhoneNumber}
                                Label={'Phone Number'}
                                Placeholder={'Enter your 10 digit Mobile Number'}
                            />
                        </div>
                        {
                            showOTP &&
                            <div>

                                <PrimaryInput
                                    id={"OTP"}
                                    regex={regexForNumbers}
                                    maxLength={6}
                                    value={otp}
                                    onChange={onOTPChange}
                                    handleKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleVerifyOTP()
                                        }
                                    }
                                    }
                                    type={"tel"}
                                    Label={'OTP'}
                                    Placeholder={'Enter 6 digit OTP sent on Your Mobile NUmber'}
                                />
                            </div>
                        }
                        <div className="actionButton" style={{
                            marginTop: '48px'
                        }}>
                            <GradientButton
                                Loading={loading}
                                onClick={showOTP ? handleVerifyOTP : handleSendOTP}
                                ButtonText={showOTP ? "Verify OTP" : 'Send OTP'}
                            />


                            <div style={{
                                marginTop: "5vh"
                            }}>
                                <GrayComponentWithLine />
                            </div>
                        </div>

                    </div>

                    <div style={{

                        display: 'flex',
                        justifyContent: 'center',



                    }}>
                        <div
                            onClick={() => {
                                navigate('/Auth/Register')
                            }}
                            className='SignUpLinkButton mt-6  rounded-xl'
                            style={{
                                cursor: 'pointer',
                                backgroundColor: '#131313',
                                color: 'white',
                                fontFamily: 'Plus Jakarta Sans',
                                paddingTop: "27px",
                                paddingBottom: "27px",
                                paddingLeft: "48px",
                                paddingRight: "48px",

                            }}>
                            Register Now
                        </div>
                    </div>
                </div>


            </div>

        </div>
    );
}
