import React, { useEffect } from 'react';
import Navbar from '../../Components/Navbar/Navbar';

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }
        , [])
    return (
        <div>
            <Navbar />

            <div className=" leading-6 p-4 text-white mx-3 md:mx-32 my-20">
                <h1 className="text-5xl text-[#AC6FFA] font-bold mb-6 ">Welcome to Accomzy!</h1>
                <p className="mb-4 text-xl font-semibold">Your trust means a lot to us, and we work hard to protect your personal information as well as improve the user experience for you and all our users. Our specialist Privacy Team is responsible for the collection and processing of all personal information.</p>
                <p className="mb-4">This Privacy Policy outlines how we collect, process, and share information of users. In using the Accomzy platform and related payment services, you acknowledge that you have read and agree to the terms of this Policy. The ultimate aim of this document is to clearly describe how the Accomzy platform works. We want to explain the collection, processing, storage, and deletion of information in a way that makes sense to individual users.</p>

                <h2 className="text-2xl font-semibold mt-10 mb-4 text-[#AC6FFA]">Table of Contents</h2>
                <ol className="list-decimal list-inside pl-4 mb-4">
                    <li>Foreword</li>
                    <li>How does Accomzy process your personal information?</li>
                    <li>How do we store and protect your data?</li>
                    <li>How do we manage your personal information?</li>
                    <li>Protection of personal information of minors</li>
                    <li>Notices and Updates</li>
                    <li>How to contact us</li>
                </ol>

                <h3 className="text-xl text-[#AC6FFA] font-semibold mt-16 mb-4 ">1. Foreword</h3>
                <p>This Privacy Policy governs the Accomzy platform, including related third-party services on the platform. Where additional policies apply to individual products or services, this will be clearly indicated to you. Where there is a conflict of interest between additional policies and this Privacy Policy, this Policy shall take precedence.</p>

                <h3 className="text-xl text-[#AC6FFA] font-semibold mt-10 mb-4">2. How does Accomzy process your personal information?</h3>
                <p>You are able to control your privacy settings when using the Accomzy platform. For example, the options available to you include the following settings:</p>
                <ul className="list-disc list-inside pl-4 mb-4">
                    <li><strong>Essential:</strong> The minimum settings for us to effectively provide the Accomzy service to you, including the lowest levels of information collection.</li>
                    <li><strong>Advanced:</strong> Allows users to access the additional functions and services on the Accomzy platform for the most comprehensive and effective use, without affecting the essential or basic functionality discussed above.</li>
                </ul>

                <h3 className="text-xl text-[#AC6FFA] font-semibold mt-10 mb-4">3. How do we store and protect your data?</h3>
                <p>We take the protection of your personal information very seriously. We have put in place a range of security measures to protect your information, including:</p>
                <ul className="list-disc list-inside pl-4 mb-4">
                    <li>Encryption of many of our services using SSL</li>
                    <li>Review of our information collection, storage, and processing practices, including physical security measures, to prevent unauthorized access to our systems</li>
                    <li>Restricting access to personal information to Accomzy employees, contractors, and agents who need to know that information in order to process it for us and who are subject to strict contractual confidentiality obligations. They may be disciplined or their contract terminated if they fail to meet these obligations</li>
                </ul>

                <h3 className="text-xl text-[#AC6FFA] font-semibold mt-10 mb-4">4. How do we manage your personal information?</h3>

                <p>Users have the following rights and protections with regards to their Personal Information:</p>

                <ol>
                    <li className='mt-6 mb-8'>
                        (1) You have the right to access, correct, and delete your personal information, except where prescribed by law. You can manage your information in the following ways:
                        <ul className='mt-3'>
                            <li className='mb-2'>Account information – the right to access and amend account materials and information (except for Accomzy ID authentication information), payment details, and phone or email info. You may also amend passwords, add security info, and amend third-party or payment provider information. This info can all be changed in either the app or the webpage.</li>
                            <li className='mb-2'>Location information – the right to access, amend, or delete information. This info can be changed in either the app or the website.</li>
                            <li className='mb-2'>Search & Browsing information – the right to access and delete your search history, as well as to edit or amend your interests and manage your data.</li>
                            <li className='mb-2'>Booking information – the right to view your booking info, including completed, pending, and after-sale bookings within the “My Bookings.” Information regarding completed bookings may be deleted, but this may affect your after-sale service, and we cannot retrieve this information if you need it later.</li>
                            <li>Comments & reviews – the right to access, amend, and delete your comments and reviews. This can be amended within the website or the app.</li>
                        </ul>
                        <p>Please contact us as soon as possible if you are unable to access, amend, or delete any of the above-mentioned information. The relevant contact details can be found at the bottom of this Privacy Policy.</p>
                    </li>

                    <li className='mt-6 mb-8'>
                        (2) Editing & withdrawing authorizations
                        <p className='mt-3'>Your consent and authorization settings can be amended within the Accomzy app or website, or by accessing the Settings on your mobile phone or computer. If you withdraw these authorizations, we will no longer be able to provide certain services that require your consent, however, this will not affect the information processed before authorization was withdrawn.</p>
                    </li>

                    <li className='mt-6 mb-8 '>
                        (3) Targeted push notifications & other marketing
                        <p className='mt-3 '>You have the right to cancel and amend targeted marketing and other messages. Reply “TD” to any text message to unsubscribe. Algorithms are used to promote properties in search results based on suitability and availability. But you can use the “Sort by” function to display properties based on your requirements (e.g., distance or price) rather than the automatic property filters and promotions.</p>
                    </li>
                </ol>

                <h3 className="text-xl text-[#AC6FFA] font-semibold mt-10 mb-4">5. Protection of personal information of minors</h3>
                <p>Accomzy respects and upholds the rights and protections afforded to minors, whilst acknowledging that it is a service primarily aimed at adults. If you are a minor, you should ask your parent or guardian to read and agree to this Privacy Policy. Your use will then be governed by the acceptance of the relevant parent or guardian.
                    Accomzy will act in compliance with all laws and regulations governing the collection and processing of the personal information of minors. The use, sharing, or disclosure of any information is done under the principle of acceptance by the parent or guardian.
                    Where Accomzy discovers that is has collected the information of a minor without the prior consent of a verifiable parent or legal guardian, we will delete the information as soon as possible.

                </p>

                <h3 className="text-xl text-[#AC6FFA] font-semibold mt-10 mb-4">6. Notices and Updates</h3>
                <p>Accomzy is continually looking to make improvements to our service and the protections we offer to user information. As a result, this Policy will be updated from time to time. Where your consent is required, your rights cannot be amended or reduced without your express agreement. We will update you whenever changes are made to the mobile or web versions of the Accomzy platform. You should also check the Privacy Policy from time to time. Where the Policy is amended without reducing any of your rights, your continued use of the Accomzy platform will act as consent to the most up-to-date Privacy Policy.   </p>

                <h3 className="text-xl text-[#AC6FFA] font-semibold mt-10 mb-4">7. Contact Us</h3>
                <p>We are available anytime through the following:</p>
                <ul className="list-disc list-inside pl-4">
                    <li>Instant messaging services are available on both the Accomzy app and the Accomzy website (www.Accomzy.in)</li>
                    <li>The Accomzy customer service line is located in India, the number is 9967840206. Please note international calling charges may apply</li>
                    <li>Email <a href="mailto:" className="text-blue-500 mx-2">
                        accomzy@ignitionnestlabs.online
                    </a>
                        with your query or issue and we will respond as soon as possible.</li>
                </ul>
            </div>
        </div>
    );
}

export default PrivacyPolicy;