import apiInstance from ".."
import { apiEndpoints } from "../constants"
import cookie from 'react-cookies';
export const getRoomDetails = async ({ room_id }) => {
    const UserId = cookie.load('UserId');
    return new Promise(async (resolve, reject) => {
        try {
            apiInstance.post(apiEndpoints.roomDetails, {

                propertyId: room_id,
                UserId:UserId?UserId:"Accomzy"

            }).then(res => {
                resolve(res.data)
            }
            ).catch(err => {
                console.log(err)
                reject(err)
            }
            )
        } catch (error) {
            reject(error);
        }
    }
    )

}
