// FeatureCard.jsx

import React from 'react';
import { faHotel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../Styles/Home/FeatureCard.css'
import { useInView } from 'react-intersection-observer';

export default function FeatureCard({ icon, Heading, description, onClick }) {
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px', // Adjust the root margin as needed
    });

    return (
        <div
            onClick={onClick ? onClick : () => null}
            className={`Feature-Card  md:w-[377px] md:h-[500px] h-[400px] ${inView ? 'fadeIn' : ''}`} ref={ref}>
            <div className="icon-container">
                <div className="icon-wrapper">
                    <div className="icon-background" alt="Icon-Container">
                        <span>
                            <FontAwesomeIcon className="icon" icon={icon} color="white" size="3x" />
                        </span>
                    </div>
                </div>
                <div className="title-container">
                    <span className="title">

                        {Heading}</span>
                </div>
            </div>

            <div className="description-container">
                <div className="description">
                    <span className="description-text">
                        {
                            description

                        }
                    </span>
                </div>
               
            </div>
        </div>
    );
}
