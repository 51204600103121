import React from 'react';

const GrayComponentWithLine = () => {
  const lineStyle = {
    borderBottom: '1px solid #808080', // Gray color for the line
    width: '40%', // Adjust the width of the line as needed
    margin: '10px',
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const textStyle = {
    margin: '0 10px',
    color: '#808080',
 // Color for the "OR" text
 fontSize: '12px',
  };

  return (
    <div style={containerStyle}>
      <div style={lineStyle}></div>
      <span style={textStyle}>or</span>
      <div style={lineStyle}></div>
    </div>
  );
};

export default GrayComponentWithLine;
