import { faBroom, faCar, faClapperboard, faCloud, faCloudRain, faDog, faDumbbell, faMusic, faSmoking, faStar, faUtensils, faWifi, faWineBottle } from '@fortawesome/free-solid-svg-icons'
import React from 'react'

export default function iconGenerate({ input }) {
    const inputFinal = input.toLowerCase().trim();
    if (inputFinal.includes('cloudy')) return faCloud;
    if (inputFinal.includes('wifi')) return faWifi;
    if (inputFinal.includes('clean')) return faBroom;
    if (inputFinal.includes('meal')) return faUtensils;
    if (inputFinal.includes('alcohol')) return faWineBottle;
    if (inputFinal.includes('pets')) return faDog;
    if (inputFinal.includes('cinema')) return faClapperboard;
    if (inputFinal.includes('fit')) return faDumbbell;
    if (inputFinal.includes('parking')) return faCar;
    if (inputFinal.includes('gym')) return faDumbbell;
    if (inputFinal.includes('smok')) return faSmoking;
    if (inputFinal.includes('music')) return faMusic;
    if  (inputFinal.includes('quiet')) return faMusic;
    else return faStar;
}
