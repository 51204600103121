// Import React and CSS file
import * as React from 'react';
import Modal from '@mui/material/Modal';

import '../../Styles/Components/PostReviewModal.css'
import toast from 'react-hot-toast';
import { COLORS } from '../../Utils/Colors';
import PrimaryInput from '../Input/PrimaryInput';
import cookie from 'react-cookies';
import PrimaryButton from '../Buttons/PrimaryButton';

import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { postReviewApi } from '../../API/site/postReviews';
import axios from "axios"
import { apiEndpoints, prod_ENV } from '../../API/constants';
import apiInstance from '../../API';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

// Create a ref for the modal
export const PostReviewModalRef = React.createRef();





// Expose a function to open the modal
export function openPostReviewModal() {
    if (PostReviewModalRef.current) {
        PostReviewModalRef.current.handleOpen();
    }
}

export default function PostReviewModal({ propertyId }) {


    const [value, setValue] = React.useState(null);
    const [rating, setRating] = React.useState(1);



    const postReviewSubmit = async () => {
        const UserId = cookie.load('UserId');
        const Token = cookie.load('Token');
        apiInstance.post(apiEndpoints.postReview, {
            propertyId,
            Review: value,
            Rating: rating
        }, {
            headers: {
                UserId,
                Token
            }
        }).then((res) => {
            console.log(res.data);
            toast.success(res.data.message);
            handleClose();
            window.location.reload();
        }).catch((err) => {
            console.log(err);
            toast.error(err.response.data.message);
        })



    }

    const handleStarHover = (hoveredRating) => {
        setRating(hoveredRating);
    };

    const handleStarClick = (selectedRating) => {
        setRating(selectedRating);
        // You can add additional logic here when a star is clicked, like submitting the rating.
    };

    const [open, setOpen] = React.useState(false);

    // Expose the handleOpen function
    const handleOpen = () => setOpen(true);
    const handleClose = (event, reason) => {

        setOpen(false);
    }


    // Set the ref to the modal component
    React.useEffect(() => {
        PostReviewModalRef.current = { handleOpen };
    }, []);


    const renderStars = () => {
        const stars = [];
        const maxRating = 5;

        for (let i = 1; i <= maxRating; i++) {
            stars.push(
                <span style={{
                    padding: "2px",
                }}>
                    <FontAwesomeIcon
                        style={{
                            color: i <= rating ? COLORS.Primary : "gray",
                            cursor: 'pointer' // Make the star clickable
                        }}
                        key={i}
                        fontSize={30}
                        icon={faStar}
                        className="star"
                        onClick={() => handleStarClick(i)} // Handle the click event
                    />
                </span>
            );
        }

        return stars;
    };



    return (
        <Modal

            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"

            aria-describedby="modal-modal-description"
        >
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}>

                <div style={{
                    width: '80%',
                    height: '80%',
                    backgroundColor: "black",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: "8px",
                    border: "1px solid #3A3838",
                }}>
                    <div style={{
                        padding: "10px",
                    }}>
                        <span style={{
                            color: COLORS.Primary,
                            fontSize: "30px",
                            fontFamily: "Montserrat",
                        }}>
                            Review
                        </span>
                    </div>
                    <div style={{
                        padding: "10px",
                    }}>
                        <span style={{

                            fontSize: "16px",
                            fontFamily: "Montserrat",
                        }}>

                        </span>
                        <div style={{
                            width: "100%",
                            justifyContent: 'center',
                            alignItems: "center",
                            alignContent: "center",
                            display: "flex"
                        }}>

                            {renderStars()}
                        </div>


                    </div>
                    <div style={{
                        padding: "10px",
                    }}>
                        <div>
                            <PrimaryInput
                                value={value}
                                onChange={(e) => setValue(e.target.value)}

                                height={"300px"}
                                type={"textarea"}
                                Placeholder={"Please take a moment to provide a detailed review of your experience with the property. Share your insights, feedback, and any suggestions you may have to help us enhance our services and make future stays even better "} width={"100%"} />
                        </div>
                    </div>
                    <div style={{
                        padding: "10px",
                        width: '100%',
                        justifyContent: 'flex-end',
                        display: 'flex',
                    }}>
                        <PrimaryButton
                            onClick={postReviewSubmit}

                            Text={"Post My Review"} />
                    </div>

                </div>

            </div>
        </Modal>
    );
}
