// Import Axios library

import axios from "axios";


// Function to send OTP using a promise
export default function sendOTP({ phoneNumber }) {
    // Create a new promise
    return new Promise((resolve, reject) => {

        if (!phoneNumber) {
            reject("Phone number is required");
        }
        if (phoneNumber.length !== 10) {
            reject("Phone number must be 10 digits");
        }

        // API endpoint
        const apiUrl = 'https://api.igniteauth.in/OTP/sendOTP';

        // Request body
        const requestBody = {
            PhoneNumber: phoneNumber,
        };

        // Request headers
        const headers = {
            'Content-Type': 'application/json',
            'apiKey': "877189cf-418a-4c40-b2e9-dea3f1e6e0f8",
        };

        // Make a POST request using Axios
        axios.post(apiUrl, requestBody, { headers })
            .then(response => {
                console.log("======Response FROM IGNITEAUTH SEND OTP======")
                console.log(response.data)
                console.log("======Response FROM IGNITEAUTH SEND OTP======")
                // Resolve the promise with the API response data
                resolve(response.data);
            })
            .catch(error => {
                // Reject the promise with the error
                //check if err is network error or not
               

                reject("Error Sending OTP ");
            });
    });
}

