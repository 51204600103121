export const appImages = {
    LOGO: "https://i.ibb.co/B4NXG3S/accomzy-copy-01.png",
    bgFadeGrid: "https://i.ibb.co/ygcc3XX/Group-436.png",
    bgDots: "https://i.ibb.co/Hpxx57d/bgDots.png"
}

export function formatDate(inputDate) {
    // Parse the input date string
    const dateObj = new Date(inputDate);

    // Define month names
    const monthNames = [
        'January', 'February', 'March',
        'April', 'May', 'June',
        'July', 'August', 'September',
        'October', 'November', 'December'
    ];

    // Extract day, month, and year
    const day = dateObj.getDate();
    const monthIndex = dateObj.getMonth();
    const year = dateObj.getFullYear();

    // Format the date string
    const formattedDate = `${day} ${monthNames[monthIndex]} ${year}`;

    return formattedDate;
}

// Example usage


