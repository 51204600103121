import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import '../../Styles/Home/TestimonialCard.css';

function TestimonialCardNew({ data }) {
    const { Name, Review, ProfileUrl } = data;
  
    const [ref, inView] = useInView({
        triggerOnce: true,
        onLeave: () => {
            setIsVisible(false);
        },

    });
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (inView) {
            setIsVisible(true);

        }

    }, [inView]);

    return (
        <div ref={ref} className={`flex flex-col bg-[#131313] items-center rounded-xl TestimonialCard-container  ${isVisible ? 'visible' : ''}`}>
            <div className='relative bottom-10'>
                <img src={ProfileUrl} alt="profile" className='object-cover aspect-square w-20 h-20 md:w-32 md:h-32 rounded-full' />
            </div>
            <h1 className=' text-lg md:text-2xl font-bold text-white'>{Name}</h1>
            <div className='flex flex-col w-full px-4 md:px-10'>
                <div className='flex items-center w-full justify-start'>
                    <p className='text-white text-7xl font-bold'>"</p>
                </div>
                <div className='flex items-center'>
                    <p className='text-gray-300 text-xs md:text-base'>{Review}</p>
                </div>
                <div className='flex items-end w-full justify-end'>
                    <p className='text-white text-7xl font-bold'>"</p>
                </div>
            </div>
        </div>
    );
}

export default TestimonialCardNew;
