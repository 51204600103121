import axios from "axios";
import apiInstance from ".."

import { apiEndpoints } from "../constants"
import cookie from 'react-cookies';
export const updateSaveProperty = async ({ PropertyId }) => {

    console.log("Posting review for " + PropertyId)
    return new Promise(async (resolve, reject) => {

        try {
            const UserId = cookie.load('UserId');
            const Token = cookie.load('Token');

            apiInstance.post(apiEndpoints.updateSaveProperty, {
                PropertyId
            }, {
                headers: {
                    UserId,
                    Token
                }
            }
            ).then(res => {
                resolve(res.data)
                return;
            }
            ).catch(err => {
                reject(err)
                return;
            }
            )

        } catch (error) {
            reject(error);

        }
    }
    )

}
