
import cookie from "react-cookies";
export const logOut = () => {
    //first ask for confirmation
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm("Are you sure you want to log out?");
    if (confirmation) {
        cookie.remove("UserId", { path: "/" });
        cookie.remove("Token", { path: "/" });
        window.location.reload();

    }
    else {
        //do nothing
    }
}
