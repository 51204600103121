// Import Axios library

import axios from "axios";


// Function to send OTP using a promise
export default function verifyOTP({ OTP, VerificationCode, PhoneNumber }) {
    // Create a new promise
    return new Promise((resolve, reject) => {

        if (!OTP) {
            reject("OTP is required");
        }
        if (OTP.length !== 6) {
            reject("OTP number must be 6 digits");
        }

        // API endpoint
        const apiUrl = 'https://api.igniteauth.in/OTP/verifyOTP';

        // Request body
        const requestBody = {
            OTP,
            VerificationCode,
            PhoneNumber
        };

        // Request headers
        const headers = {
            'Content-Type': 'application/json',
            'apiKey': "877189cf-418a-4c40-b2e9-dea3f1e6e0f8",
        };

        // Make a POST request using Axios
        axios.post(apiUrl, requestBody, { headers })
            .then(response => {
                console.log("======Response FROM IGNITEAUTH VERIFY OTP======")
                // Resolve the promise with the API response data
                resolve(response.data);
                console.log("======Response FROM IGNITEAUTH VERIFY OTP======")
            })
            .catch(error => {
                // Reject the promise with the error
                reject(error);
            });
    });
}

