import React, { useEffect, useState } from 'react'
import { realtime } from '../../../Utils/firebase';


const ChatUserCard = ({ ch, onClick, roomId, UserId }) => {

  const [latestMessage, setLatestMessage] = useState(null);






  return (
    <div
      onClick={onClick}
      className="flex justify-between cursor-pointer border-b border-[#242424] py-3 items-start px-4"
    >
      <div className="flex items-start space-x-4">
        <img src={ch.UserData.ProfilePhoto} alt="User" className="w-14 h-14 rounded-full" />
        <div className="flex flex-col space-y-2">
          <div className="text-white">{ch.UserData.Name}</div>
          <div className="text-[#9F9F9F]">{ch.UserData.Role}</div>

        </div>
      </div>
      <div className="text-[#6A6A6A] flex flex-col space-y-2">
        <div>{ch.time}</div>
        {/* <div className="bg-[#AC6FFA] rounded-full w-5 h-5 flex justify-center items-center text-[12px] text-white self-end">
          1
        </div> */}
      </div>
    </div>
  );
}

export default ChatUserCard