import apiInstance from "..";
import { apiEndpoints } from "../constants";

export const register = ({ PhoneNumber, Name, Owner, Explorer }) => {
    console.log("register" + PhoneNumber + Name + Owner, Explorer);
    return new Promise(async (resolve, reject) => {
        Name.length < 3 && reject("Name must be at least 3 characters long");
        if (!PhoneNumber) {
            reject("Phone number is required");
        }
        if (PhoneNumber.length !== 10) {
            reject("Phone number must be 10 digits");
        }
        if (!Owner && !Explorer) {
            reject("    Please select one of the options ");
        }
        try {
            const response = await apiInstance.post(apiEndpoints.register, {
                Name,
                PhoneNumber,
                Owner: Owner === true ? true : false,
                Explorer: Explorer === true ? true : false,

            }
            );
            resolve(response.data);
        } catch (error) {
            reject(error);
        }
    });
};
