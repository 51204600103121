import React from 'react';

export default function PrimaryInput({ onBlur, id, height, autocomplete, marginTop, bgColor, borderRadius, width, Label, Placeholder, onPress, type, onChange, regex, value, maxLength, disabled, hide, handleKeyDown }) {


    if (hide) {
        return null;
    }

    return (
        <div

        >{
                Label && <div>
                    <span style={{
                        color: ' #ffffff',
                        fontSize: '16px',
                        marginTop: '8px',


                        fontFamily: 'Plus Jakarta Sans'
                    }}>
                        {Label}
                    </span>
                </div>
            }
            <div style={{
                marginTop: marginTop ? marginTop : '16px'
            }}>
                {
                    type == "textarea" ?
                        <textarea
                            id={id}
                            autoComplete={autocomplete}
                            disabled={disabled}
                            value={value}
                            maxLength={maxLength}
                            onChange={onChange}
                            type={type}
                            onClick={onPress}
                            placeholder={Placeholder}
                            style={{
                                zIndex: 1,
                                resize: "none",
                                height: height ? height : null,
                                width: width ? width : '90%',
                                fontSize: '16px',
                                backgroundColor: bgColor ? bgColor : '#3F3C3C',
                                border: "1px solid #9F9F9F",
                                borderRadius: borderRadius ? borderRadius : '8px',
                                color: 'white',
                                outline: 'none',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                            }} /> :
                        <input
                            onBlur={onBlur}
                            id={id}
                            autoComplete={autocomplete}
                            disabled={disabled}
                            value={value}
                            maxLength={maxLength}
                            onChange={onChange}
                            type={type}
                            onKeyDown={handleKeyDown}
                            onClick={onPress}
                            className='focus:border-[#9F9F9F] border-[1px] border-transparent'
                            placeholder={Placeholder}
                            style={{
                                zIndex: 1,
                                resize: "none",
                                height: height ? height : null,
                                width: width ? width : '90%',
                                fontSize: '16px',
                                backgroundColor: bgColor ? bgColor : '#3F3C3C',
                                border: "1px solid #9F9F9F",
                                borderRadius: borderRadius ? borderRadius : '8px',
                                color: 'white',
                                outline: 'none',
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                paddingLeft: '16px',
                                paddingRight: '16px',
                            }} />
                }

            </div>


        </div>


    )
}
