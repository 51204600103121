import React from 'react';
import '../../../../Styles/Site/Broker/PropertyForms/Section6.css';
import ImageUploader from '../ImageUploader';

export default function Section2({ formData, onChange, setFormData }) {
  const [propertyImages, setPropertyImages] = React.useState([])
  const handleImagesSelected = (images) => {
    // Do something with the selected images, e.g., send them to the server
    console.log('Selected Images:', images);
    setPropertyImages(images)
    setFormData({
      ...formData,
      propertyImages: images
    })
  };
  return (
    <div className="Section6">

      <div className="Section6-content">
        <span className="Section6-text">
          Add {" "}
          <span className="Section6-highlight">
            property{" "}
          </span>
          images
        </span>
      </div>
      <div className="Section6-description">
        Add atleast 5 images
      </div>
      <div style={{
        marginTop: "30px",
        display: 'flex',
        justifyContent: 'center',

      }}>
        <span style={{
          fontFamily: "Montserrat",
          color: "white",
          fontSize: "14px",
          fontWeight: "bold",
        }}>
          {propertyImages.length} images uploaded
        </span>
      </div>
      <div

        style={{
          marginTop: "50px"
        }}>

        <ImageUploader onImagesSelected={handleImagesSelected} />



      </div>
    </div>
  );
}
