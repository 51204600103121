import React, { useEffect } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import { Helmet } from 'react-helmet'
import { COLORS } from '../../Utils/Colors'
import '../../Styles/Site/Exlpore/PropertyDetails.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark, faStar, faWifi } from '@fortawesome/free-solid-svg-icons'
import PrimaryButton from '../../Components/Buttons/PrimaryButton'
import { useParams } from 'react-router-dom';
import ReviewCard from '../../Components/Home/ReviewCard'
import Footer from '../../Components/Footer/Footer'
import { getRoomDetails } from '../../API/site/getRoomDetails'
import ImageModal, { openImageModal } from '../../Components/Modals/ImageModal'
import { getRoomReviews } from '../../API/site/getReviews'
import PostReviewModal, { openPostReviewModal } from '../../Components/Modals/PostRviewModal'
import iconGenerate from '../../Utils/iconGenerate'
import AllReviewModal from '../../Components/Modals/AllReviewModal'
import { FaBookmark } from "react-icons/fa"
import { GiBookmark } from 'react-icons/gi'
import apiInstance from '../../API'
import cookie from 'react-cookies';
import toast from 'react-hot-toast'

import { apiEndpoints } from '../../API/constants'
import useAuthStatus from '../../Hooks/useAuthStatus'
import MapContainer from '../../Components/Site/Containers/MapContainers'
import TestimonialCard from '../../Components/Home/TestimonialCard'
import { updateSaveProperty } from '../../API/site/updateSaveProperty'
import { chatWithUser } from '../../API/Chat/chatWithUser'
import { useNavigate } from 'react-router-dom'
export default function PropertyDetails() {
  const navigate = useNavigate()
  const { isAuthenticated } = useAuthStatus()
  //get room id from params
  const { id } = useParams();
  const [room_id, setRoomId] = React.useState(id);
  const [room, setRoom] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [err, setErr] = React.useState(null);
  const [reviews, setReviews] = React.useState([]);
  const [allReviews, setAllReviews] = React.useState(false)
  const [propertySaved, setPropertySaved] = React.useState(false)
  const [ownerData, setOwnerData] = React.useState(null)
  const [rating, setRating] = React.useState(0)

  const saveProperty = () => {
    if (!isAuthenticated) {
      toast.error("Please Login To Save Property")
      return
    }
    updateSaveProperty({ PropertyId: room_id }).then(res => {
      console.log(res)
      toast.success(res.message)
      setPropertySaved(res.saved)

    }
    ).catch(err => {
      console.log(err)
      toast.error("Something Went Wrong")
    }
    )




  }


  useEffect(() => {

    console.log(room_id);


    window.scrollTo(0, 0)
    // getRoomReviews({
    //   roomId: room_id
    // }).then(res => {
    //   console.log(res);
    //   console.log("Response From Get Property Reviews" + res);
    //   setReviews(res.reviews);
    // }
    // ).catch(err => {
    //   console.log("Error From Get Property Reviews" + err);
    // }
    // )
    getRoomDetails({ room_id: room_id }).then(roomData => {
      console.log(roomData);
      setRoom(roomData.propertyData);
      setOwnerData(roomData.ownerDetails)
      roomData.rating ? setRating(roomData.rating) : setRating(0)
      setReviews(roomData.propertyData.Reviews)
      setPropertySaved(roomData.save.saved)
    }
    ).catch(err => {
      console.log(err)
      setErr(err)
    }
    ).finally(() => {
      setLoading(false)
    }
    )

  }
    , [room_id]);


  return (
    <>
      <Helmet>

        <title> Explore  {room ? room.PropertyName : "Accomzy"}  at Accomzy
        </title>
        <meta name="description" content="Discover convenient and comfortable living with Accomzy! Find the perfect PGs, hostels, and apartments for students. Simplify your accommodation search with our user-friendly platform. Your ideal living space is just a click away. Start your housing journey with Accomzy today!">
        </meta>
        <meta name='og:title' content='Accomzy - Exploring Accommodation Made Easy' />
        <meta name='og:description' content="Discover convenient and comfortable living with Accomzy! Find the perfect PGs, hostels, and apartments for students. Simplify your accommodation search with our user-friendly platform. Your ideal living space is just a click away. Start your housing journey with Accomzy today!" />
        <meta name='og:url' content='https://accomzy.in/' />
        <meta name='og:image' content={room ? room.PropertyImages[0] : "https://i.ibb.co/yNZmnrW/Accomzy-Icon.png"} />
        <meta name='og:type' content='website' />
        <meta name='og:site_name' content='Accomzy' />
        <meta name='og:locale' content='en_US' />
        <meta name='fb:app_id' content='966242223397117' />
        <meta name='twitter:card' content='summary' />
        <meta name='twitter:site' content='@accomzy' />
        <meta name='twitter:creator' content='@accomzy' />
        <meta name='twitter:url' content='https://accomzy.in/' />


      </Helmet>
      <div className='Home-Navbar-Container'>
        <Navbar />
      </div>

      <div
        style={{
          overflowY: 'hidden',
          paddingTop: "60px"
        }}
        className='Home-Main-Container'>


        {
          loading ? <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw'
          }}>
            <span style={{
              color: 'white',
              fontSize: '20px'
            }}>


              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </span>
          </div> : (
            err ? <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
              width: '100vw'
            }}>
              <span style={{
                color: 'white',
                fontSize: '20px'
              }}>
                Something Went Wrong
              </span>
            </div> :


              <div className='Property-Details-Main-Container fade-in'  >
                <div
                  className='Property-Details-Container'
                >
                  <div className='Property-Details-Header-Container'   >
                    <div style={{
                      flex: 1
                    }}>
                      <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}>
                        <div>
                          <span
                            style={{



                              color: 'white'
                            }}
                          >
                            <h4>{room.PropertyName}</h4>
                          </span>
                        </div>
                        <div style={{
                          paddingLeft: '10px',
                        }}>
                          <span className='Property-Details-Header-Container-Badge'  >
                            {room.PropertyType}
                          </span>
                        </div>

                      </div>
                      <div>
                        <span style={{
                          fontSize: '12px',
                          color: 'white',
                        }}>
                          <FontAwesomeIcon icon={faStar} style={{
                            color: COLORS.Primary,
                            marginRight: '10px'
                          }} /> {rating.toFixed(2)} . <span>
                            {
                              room.HouseNumber ? room.HouseNumber + ", " : ""
                            }
                            {
                              room.Street ? room.Street + ", " : ""
                            }
                            {
                              room.Locality ? room.Locality + ", " : ""
                            }
                            {
                              room.City ? room.City + ", " : ""
                            }
                            {
                              room.State ? room.State + ", " : ""
                            }
                            {
                              room.Country ? room.Country + ", " : ""
                            }
                            {
                              room.PinCode ? parseInt(room.PinCode) : ""
                            }
                            {
                              room.InstituteNearBy ? " | " + room.InstituteNearBy : ""
                            }
                          </span>
                        </span>
                      </div>
                    </div>

                    <div style={{
                      flex: 1
                    }}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}>
                        <div
                          className='flex flex-row items-center gap-x-2 border-[1px] border-transparent duration-300 px-4 py-2 rounded-full hover:border-gray-300  '
                          onClick={saveProperty}
                          style={{
                            cursor: "pointer"
                          }}>
                          <FaBookmark className='text-white' />
                          <span
                            // className="navbar-login"
                            style={{
                              color: 'white',
                            }}>
                            {
                              propertySaved ? "Unsave Property" : "Save Property"
                            }

                          </span>
                        </div>
                        <div style={{
                          paddingLeft: '20px'
                        }}>


                          <span className='Property-Details-Header-Price-Container'            >
                            ₹{(parseFloat(room.Price)).toFixed(2)}/mo

                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div
                    className='Property-Details-Main-Details-Container'
                  >
                    <div
                      className='Property-Details-Main-Details-Container-Left-Container fade-in'
                    >
                      <div style={{
                        width: '100%',
                      }}>
                        <div>
                          <img
                            alt='property'
                            src={room.PropertyImages[0]} style={{
                              width: '100%',
                              borderTopRightRadius: '10px',
                              borderTopLeftRadius: '10px',
                            }} />
                        </div>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}>
                          <div style={{
                            width: '49%',
                            marginTop: '10px',
                          }}>
                            <img
                              alt='property'
                              src={room.PropertyImages[1]}
                              style={{
                                width: '100%',
                                borderBottomLeftRadius: '10px',
                              }} />

                          </div>
                          <div
                            onClick={() => {
                              openImageModal()
                            }}
                            style={{
                              width: '49%',
                              marginTop: '10px',
                              position: 'relative',
                              overflow: 'hidden', // Added overflow hidden to contain the overlay

                              // Background image with overlay
                              backgroundImage: `url(${room.PropertyImages[2]})`,
                              backgroundSize: 'cover',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',
                              cursor: 'pointer',
                            }}>

                            {/* Semi-transparent overlay */}
                            <div style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Adjust the alpha value for transparency
                              zIndex: 1, // Make sure the overlay is above the text
                            }}></div>

                            <div style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              zIndex: 2, // Make sure the text is above the overlay
                            }}>
                              <span style={{
                                fontWeight: 'bold',
                                fontSize: '14px',
                                color: 'white',
                              }}>
                                +  {room.PropertyImages.length - 2} Images
                              </span>
                            </div>

                          </div>

                        </div>



                      </div>
                    </div>
                    <div className='Property-Details-Main-Details-Container-Right-Container'      >
                      <div style={{
                        padding: '20px',
                        display: 'flex',


                      }}>

                        {
                          room.Facilities ? (room.Facilities).map((Facility) => {
                            return (
                              <div style={{

                                margin: '10px',
                                padding: '10px',
                                textAlign: 'center',
                                borderRadius: '10px',
                                border: '1px solid white',
                              }}>
                                <span
                                  style={{
                                    color: 'white',
                                    paddingLeft: '10px',
                                    fontSize: '12px'
                                  }}
                                  key={Facility.name}>
                                  <span>
                                    <FontAwesomeIcon icon={iconGenerate({ input: Facility.name })} style={{
                                      color: 'white',
                                      marginRight: '10px'
                                    }} />
                                    <br />
                                  </span>
                                  {
                                    Facility.name
                                  }

                                </span>
                              </div>



                            )

                          }
                          ) : ""
                        }


                      </div>
                      <div style={{
                        paddingLeft: '20px',
                        marginTop: '30px',
                      }}>
                        <span style={{
                          color: "white"
                        }}>
                          <h6>Room Amenities</h6>
                        </span>
                      </div>
                      <div style={{
                        paddingLeft: '20px',
                        display: 'flex',
                        flexWrap: 'wrap',



                      }}>

                        {
                          room.Amenities ? (room.Amenities).map((Amenity) => {
                            return (

                              <span
                                style={{
                                  color: 'white',
                                  margin: '10px',
                                  paddingTop: '5px',
                                  paddingBottom: '5px',
                                  border: "1px solid white",
                                  paddingLeft: '10px',
                                  paddingRight: '10px',
                                  borderRadius: '13px',
                                  fontSize: '12px'
                                }}
                                key={Amenity.name}>

                                {
                                  Amenity.name
                                }

                              </span>




                            )

                          }
                          ) : ""
                        }


                      </div>
                      <div style={{
                        paddingLeft: '20px',
                        marginTop: '30px',
                      }}>
                        <span style={{
                          color: "white"
                        }}>
                          <h6>Rules</h6>
                        </span>
                      </div>
                      <div style={{
                        paddingLeft: '20px',
                        display: 'flex',
                        flexWrap: 'wrap',



                      }}>

                        {
                          room.Rules ? (room.Rules).map((Rule) => {
                            return (

                              <div style={{

                                margin: '10px',
                                padding: '10px',
                                textAlign: 'center',
                                borderRadius: '10px',
                                border: '1px solid white',
                              }}>
                                <span
                                  style={{
                                    color: 'white',
                                    paddingLeft: '10px',
                                    fontSize: '12px'
                                  }}
                                  key={Rule.name}>
                                  <span>
                                    <FontAwesomeIcon icon={iconGenerate({ input: Rule.name })} style={{
                                      color: 'white',
                                      marginRight: '10px'
                                    }} />
                                    <br />
                                  </span>
                                  {
                                    Rule.name
                                  }

                                </span>
                              </div>




                            )

                          }
                          ) : ""
                        }


                      </div>
                      <div style={{
                        paddingLeft: '20px',
                        marginTop: '30px',
                      }}>
                        <span style={{
                          color: "white"
                        }}>
                          <h6>Additional Note From The Owner -</h6>
                        </span>
                      </div>
                      <div style={{
                        paddingLeft: '20px',
                        display: 'flex',
                        flexWrap: 'wrap',



                      }}>



                        <div style={{

                          margin: '10px',
                          padding: '10px',
                          textAlign: 'center',
                          borderRadius: '10px',

                        }}>
                          <span
                            style={{
                              color: 'white',
                              paddingLeft: '10px',
                              fontSize: '12px'
                            }}
                          >
                            <span>
                              {
                                room.AdditionalNote ? room.AdditionalNote : "No Additional Note"
                              }
                              <br />
                            </span>


                          </span>
                        </div>







                      </div>

                    </div>

                  </div>
                  <div style={{
                    marginTop: '30px',

                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                    <div style={{
                      flex: 1,
                      display: "flex"
                    }}>
                      <div
                        style={{
                          width: "56px",
                          height: "56px",
                          display: "block",
                          marginRight: "20px",
                        }}
                      >
                        <img style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          borderRadius: "28px"
                        }}
                          alt='PROFILE'
                          src={ownerData.ProfilePhoto ? ownerData.ProfilePhoto : "https://i.ibb.co/yNZmnrW/Accomzy-Icon.png"}
                        />
                      </div>
                      <div>
                        <div>
                          <span
                            style={{



                              color: 'white'
                            }}
                          >
                            <h6>Hosted By {ownerData ? ownerData.Name : "Accomzy"}</h6>
                          </span>

                        </div>
                        <div style={{
                          color: "#A8A8A8"
                        }}>
                          <span>
                            <h9>
                              Joined {ownerData ? new Date(ownerData.createdAt).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }) : "this Year"}
                            </h9>
                          </span>
                        </div>
                        <div>
                          <span>

                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{
                      flex: 1,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}>
                      <PrimaryButton
                        onClick={() => {
                          chatWithUser({
                            ReceiverId: ownerData.UserId
                          }).then(res => {
                            console.log(res)
                            navigate(`/User/Chat`)
                            //         window.open(`https://wa.me/${res.phone}?text=Hi ${res.name} I am interested in your property ${room.PropertyName} listed on Accomzy. Please let me know if it is still available.`, '_blank')

                          }
                          ).catch(err => {
                            console.log(err)
                          })
                        }}
                        Text={"Contact Owner"} />


                    </div>
                  </div>
                  <div style={{
                    marginTop: '50px',


                    flexWrap: 'wrap',
                  }}>
                    <span style={{
                      fontSize: "26px",
                      color: "white"
                    }}>
                      Location
                    </span>

                    <MapContainer
                      lat={room.Latitude}
                      lng={room.Longitude}
                      zoom={15}
                    />



                  </div>
                  <div style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    marginTop: "30px",
                  }}>
                    <div>
                      <span style={{
                        fontSize: "26px",
                        color: "white"
                      }}>
                        Reviews
                      </span>
                    </div>

                    {isAuthenticated ?
                      <div>
                        <PrimaryButton Text={"Post A Review"} onClick={() => {
                          openPostReviewModal()
                        }
                        } />
                      </div>
                      :
                      <div>
                        <PrimaryButton Text={"Post A Review"} onClick={() => {
                          toast.error("Please Login To Post A Review")
                        }
                        } />
                      </div>
                    }
                  </div>
                  <div style={{
                    marginTop: '30px',


                    flexWrap: 'wrap',
                  }}>

                    <div style={{
                      width: "100%",
                      marginTop: "30px",
                      borderRadius: "23px",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",

                    }}>




                      {
                        !allReviews ?
                          reviews.
                            sort((a, b) => {
                              return b.rating - a.rating
                            }
                            ).
                            slice(0, 3).map((review, index) => {
                              return (
                                <div key={index}>
                                  <TestimonialCard
                                    Name={review.Name}
                                    Review={review.Review}
                                    ProfileUrl={review.ProfilePhoto}
                                  />

                                </div>
                              )
                            }
                            ) :
                          reviews.map((review, index) => {
                            return (
                              <div key={index}>
                                <TestimonialCard
                                  Name={review.Name}
                                  Review={review.Review}
                                  ProfileUrl={review.ProfilePhoto}
                                />
                              </div>
                            )
                          }
                          )
                      }

                    </div>
                    {
                      reviews.length > 3 && <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: "30px"
                      }}>
                        <div style={{
                          width: "30%"
                        }}>
                          <PrimaryButton

                            Text={allReviews ? "Show Less Reviews" : "View All Reviews"} onClick={() => {
                              setAllReviews(allReviews ? false : true)
                              // scroll to bottom
                              window.scrollTo(0, document.body.scrollHeight);


                            }
                            } />
                        </div>
                      </div>
                    }




                  </div>
                </div>
                <PostReviewModal propertyId={room_id} />
                <AllReviewModal Reviews={reviews} />
              </div>
          )

        }

      </div>
      <div style={{
        paddingTop: "50px"
      }}>
        <Footer />
        <ImageModal
          ImgArr={room ? room.PropertyImages : []}
        />
      </div>

    </>
  )

}
