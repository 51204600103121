import React, { useState, useEffect } from 'react';
import '../../Styles/Home/HomeBanner.css'

export default function HomeBanner() {
    const [propertiesCount, setPropertiesCount] = useState(0);
    const [customersCount, setCustomersCount] = useState(0);

    useEffect(() => {
        const propertiesInterval = setInterval(() => {
            if (propertiesCount < 200) {
                setPropertiesCount(propertiesCount + 1);
            }
        }, 2);

        const customersInterval = setInterval(() => {
            if (customersCount < 550) {
                setCustomersCount(customersCount + 10);
            }
        }, 20);

        return () => {
            clearInterval(propertiesInterval);
            clearInterval(customersInterval);
        };
    }, [propertiesCount, customersCount]);

    return (
        <div className="HomeBanner-container">
            <div className=" mr-10 ">
                <img src="https://i.ibb.co/W0t6jJq/Rectangle-230.png" alt="Rectangle-230" className='w-40 h-40 md:w-64 md:h-64' />
            </div>
            <div className="HomeBanner-text">
                <div className="flex w-full items-center justify-center">
                    <span className="text-2xl  items-center justify-center w-full text-center text-white font-montserrat">
                        Choose{' '}
                        <span className="text-purple-600 text-center">
                            the best
                        </span> places to stay
                    </span>
                </div>

                <div className=" flex items-center gap-x-10 w-full justify-center">
                    <div className="">
                        <div>
                            <span className="HomeBanner-count">
                                {propertiesCount}+
                            </span>
                        </div>
                        <div className="HomeBanner-infoText ">
                            <span>Listed</span>
                            <br />
                            <span>Properties</span>
                        </div>
                    </div>
                    <div className=" ">
                        <div>
                            <span className="HomeBanner-count">
                                {customersCount}+
                            </span>
                        </div>
                        <div className="HomeBanner-infoText">
                            <span>Happy</span>
                            <br />
                            <span>customers</span>
                        </div>
                    </div>
                </div>
                <div className="HomeBanner-description">
                    <span className="HomeBanner-descriptionText">
                        Effortlessly find your perfect hostel/PG: Discover, Compare, and Save with Ease
                    </span>
                </div>
            </div>
        </div>
    );
}
