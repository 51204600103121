// ReviewCard.js

import { faStar, faStarHalfAlt, faStarHalfStroke } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { COLORS } from '../../Utils/Colors';
import '../../Styles/Components/ReviewCard.css'; // Import the CSS file
import { formatDate } from '../../Utils/Constants';

export default function ReviewCard({ review }) {
    const [isVisible, setIsVisible] = useState(false);
    const reviewCardRef = useRef(null);

    useEffect(() => {

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { root: null, rootMargin: '0px', threshold: 1 } // Adjust threshold as needed
        );

        if (reviewCardRef.current) {
            observer.observe(reviewCardRef.current);
        }

        return () => {
            if (reviewCardRef.current) {
                observer.unobserve(reviewCardRef.current);
            }
        };
    }, []);
    // random from 1 to 5
    const rating = Math.floor(Math.random() * 5) + 1;

    return (
        <div
            className={`Review-Card-Container ${isVisible ? 'fade-in' : ''}`}
            ref={reviewCardRef}
        >
            <div className="Review-Card-Header">
                <div className="Review-Card-Image">
                    <img
                        src={review.profile_image ? review.profile_image : "https://cirrusindia.co.in/wp-content/uploads/2016/10/dummy-profile-pic-male1.jpg"}
                        alt="Profile"
                        border="0"
                    />
                </div>
                <div className="Review-Card-Details">
                    <div>
                        <span style={{
                            color: 'white'
                        }}>
                            {
                                review.name ? review.name : 'Anonymous'
                            }
                        </span>
                    </div>
                    <span>
                        <span className="Review-Card-Date">
                            {formatDate(review.created_at)}
                        </span>
                    </span>
                </div>
                <div className="Review-Card-Rating">
                    <span>

                        {Array.from(Array(parseInt(review.rating)), (_, i) => (
                            <FontAwesomeIcon
                                key={i}
                                icon={faStar}
                                style={{
                                    color: COLORS.Primary,
                                    fontSize: '12px',
                                }}
                            />
                        ))}
                        {Array.from(Array(5 - parseInt(review.rating)), (_, i) => (
                            <FontAwesomeIcon
                                key={i}
                                icon={faStarHalfAlt}
                                style={{
                                    color: COLORS.Primary,
                                    fontSize: '12px',
                                }}
                            />
                        ))}
                    </span>
                </div>
            </div>

            <div className="Review-Card-Content">
                <span>
                    {review.review_text}
                </span>
            </div>
        </div>
    );
}
