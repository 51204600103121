import React, { Component } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import PrimaryButton from '../../Buttons/PrimaryButton';

class MapContainer extends Component {
    openNewWindow = () => {
        const { lat, lng } = this.props;

        // Check if the user is on an Apple device
        const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);

        // Construct the directions URL with the latitude and longitude
        let directionsUrl;

        if (isAppleDevice) {
            // Apple Maps URL for directions
            directionsUrl = `https://maps.apple.com/?daddr=${lat},${lng}`;
        } else {
            // Google Maps URL for directions
            directionsUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
        }

        // Open a new window with the directions URL
        window.open(directionsUrl, '_blank');
    };

    render() {
        const { lat, lng } = this.props;

        const mapStyles = {
            width: '100%',
            height: '200px',
        };

        const defaultCenter = {
            lat: lat,
            lng: lng,
        };

        return (
            <div>
                <div
                    style={{
                        position: 'relative',
                        width: '100%',
                        height: '200px',
                        overflow: 'hidden',
                        borderRadius: '5px',
                        border: '1px solid #ddd',
                        marginBottom: '20px'
                    }}
                >
                    <Map
                        google={this.props.google}
                        zoom={15}
                        style={mapStyles}
                        initialCenter={defaultCenter}
                        center={defaultCenter}
                    >
                        <Marker position={defaultCenter} icon={{ url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png' }} />
                    </Map>
                </div>
                <div style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                }}>
                    <PrimaryButton
                        onClick={this.openNewWindow}
                        Text="📍 Navigate To Property"

                    />
                </div>

            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAzmyP0VcjESUp7X8kTE4MLSEn-obVm45Y',
})(MapContainer);
