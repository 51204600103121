import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";


import cookie from 'react-cookies';
import { RouteEndpoints } from "./RouterConsts";
import verifySession from "../API/auth/verifySession";
import { appImages } from "./Constants";
const useAuthentication = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [verificationComplete, setVerificationComplete] = useState(false);

    useEffect(() => {
        const verifyAuth = async () => {
            const Token = cookie.load('Token')
            const UserId = cookie.load('UserId')


            if (Token && !isAuthenticated) {
                try {
                    const verified = await verifySession()
                    setIsAuthenticated(verified);
                } catch (error) {
                    setIsAuthenticated(false);
                }
            }

            setIsLoading(false);
            setVerificationComplete(true);
        };

        verifyAuth();
    }, [isAuthenticated]);

    return { isAuthenticated, isLoading, verificationComplete };
};

const PrivateRoute = ({ children }) => {
    const { isAuthenticated, isLoading, verificationComplete } =
        useAuthentication();

    if (isLoading || !verificationComplete) {
        // You can render a loading spinner or any other loading indicator here
        return <div style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            backgroundColor: "black",
            justifyContent: "center",
            alignItems: "center"

        }}>
            <image src={appImages.LOGO} width={"30vw"} alt="AccomzyLogo" />
        </div>;
    }

    return isAuthenticated && verificationComplete ? (
        children
    ) : (
        <Navigate to={RouteEndpoints.Login} />
    );
};

export default PrivateRoute;