import React from 'react';
import roomImage from '../../Assets/roomImage/property.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin, faStar } from '@fortawesome/free-solid-svg-icons';
import { Location, Star } from '../../Utils/Icons';

function PropertyCard({ PropertyName, Price, PropertyImage, location, Reviews, Approved }) {
    const Rating =
        Reviews.length === 0 ? 0 :
            Reviews.reduce((acc, curr) => acc + curr.Rating, 0) / Reviews.length;

    return (
        <div className="relative bg-[#131313] rounded-lg shadow-md overflow-hidden">
            {/* Property Image */}
            <img src={PropertyImage} alt="Property" className="w-72 h-52" />

            {/* Property Details */}
            <div className="pl-4 mt-1">
                {/* Property Name */}
                <h2 className="text-xl font-semibold text-white"> {PropertyName}</h2>

                {/* Location */}
                <div className="mt-1 flex gap-x-2 items-center">
                    <Location />
                    <p className="text-sm text-gray-400 mt-2.5">{location}</p>
                </div>

                {/* Rating */}
                <div className="flex  gap-x-2 items-center flex-row">
                    <Star />
                    <h1 className="text-sm text-gray-400 mt-2">{Rating.toFixed(2)}</h1>
                </div>
            </div>

            {/* Price */}
            <div className="absolute top-2 right-2">
                <p className="text-white text-xs font-normal bg-black px-2.5 py-1 rounded-full">
                    Rs {parseInt(Price)}/mo
                </p>
            </div>
            <div className="absolute top-10 right-2">
                <p className="text-white text-xs font-normal px-2.5 py-1 rounded-full" style={{
                    backgroundColor: Approved ? "#217221" : "#ff0000"
                }}>
                    {Approved ? "Approved" : "Approval Pending"}
                </p>
            </div>
        </div>
    );
}

export default PropertyCard;
