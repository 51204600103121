// backend

import apiInstance from "..";
import { apiEndpoints } from "../constants";

export const getAllRooms = async ({ searchQuery = undefined,
    page,
    facilities = [],
    amenities = [],
    minPrice,
    maxPrice,
    latitude = undefined,
    longitude = undefined,
    city = undefined,
    propertyType = undefined,
    institute = undefined }) => {
    return new Promise(async (resolve, reject) => {

        try {
            apiInstance.post(apiEndpoints.getAllRooms, {
                page,
                facilities,
                amenities,
                minPrice: minPrice,
                maxPrice: maxPrice,
                latitude,
                longitude,
                city,
                institute,
                searchQuery,
                type: propertyType
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        } catch (error) {
            reject(error);
        }
    });
};
