import React from 'react';

import { faBars, faBuilding, faBuildingColumns, faEnvelope, faHandshake, faL, faMessage, faTeletype } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../Styles/Components/Navbar.css'
import { useNavigate } from 'react-router-dom';
import { RouteEndpoints } from '../../Utils/RouterConsts';
import { appImages } from '../../Utils/Constants';
import useAuthStatus from '../../Hooks/useAuthStatus.js';
import { logOut } from '../../Utils/logOut.js';
import { COLORS } from '../../Utils/Colors.js';
import Profile from '../../Assets/profile-circle.png';
import logOutIcon from '../../Assets/logout.png';

export default function Navbar({ showOptions = true, authMethods = true }) {

    const navigate = useNavigate();
    const { isAuthenticated, isOwner, isUser, isLoading } = useAuthStatus();
    const [showDropdown, setShowDropdown] = React.useState(false);
    const [showDropdown1, setShowDropdown1] = React.useState(false);
    const [showDropdown3, setShowDropdown3] = React.useState(false);
    const [dropdown2, setDropdown2] = React.useState(false);

    return (
        <div className="navbar-container">
            <div
                onClick={
                    () => {
                        window.location.href = "/"
                    }
                }
                className="navbar-logo">
                <div>
                    <img src={appImages.LOGO} width={60} height={60} alt="AccomzyLogo" />
                </div>
            </div>
            {
                showOptions && <div className="navbar-section">
                    {!isOwner &&
                        <div className="navbar-item"
                        >
                            <div className='navbar-fade-in' >
                                <span>
                                    <FontAwesomeIcon className="navbar-icon" color='white' icon={faBuildingColumns} />
                                </span>
                            </div>
                            <div
                                onClick={() => {
                                    navigate(RouteEndpoints.Explore)
                                }}
                                className='Nav-Option navbar-fade-in'
                            >
                                <span className="navbar-title">
                                    Explore Properties
                                </span>
                                <br />
                                <span className="navbar-subtitle">
                                    More than 1000 Properies
                                </span>
                            </div>
                        </div>
                    }
                    {
                        isUser &&
                        <div
                            onClick={() => {
                                navigate(RouteEndpoints.DealsandOffers)
                            }}
                            className="navbar-item ">
                            <div className='navbar-fade-in'>
                                <span>
                                    <FontAwesomeIcon className="navbar-icon" color='white' icon={faHandshake} />
                                </span>
                            </div>
                            <div

                                className='Nav-Option navbar-fade-in'
                            >
                                <span className="navbar-title">
                                    Deals & Offers
                                </span>
                                <br />
                                <span className="navbar-subtitle">
                                    More than 100 collaborators
                                </span>
                            </div>
                        </div>
                    }
                    {
                        isOwner &&
                        <div
                            onClick={() => {
                                navigate(RouteEndpoints.Chat)
                            }}
                            className="navbar-item ">
                            <div className='navbar-fade-in'>
                                <span>
                                    <FontAwesomeIcon className="navbar-icon" color='white' icon={faMessage} />
                                </span>
                            </div>
                            <div

                                className='Nav-Option navbar-fade-in'
                            >
                                <span className="navbar-title">
                                    Your Chats
                                </span>
                                <br />
                                <span className="navbar-subtitle">
                                    Chat And Connect with explorers
                                </span>
                            </div>
                        </div>
                    }

                    {!isUser &&
                        <div className="navbar-item">
                            <div className='navbar-fade-in'>
                                <span>
                                    <FontAwesomeIcon className="navbar-icon" color='white' icon={faBuilding} />
                                </span>
                            </div>
                            <div
                                onClick={() => {
                                    // Check if the owner is authenticated
                                    if (isAuthenticated) {
                                        navigate(RouteEndpoints.EnlistProperty);
                                    } else {
                                        // Redirect to the login page if not authenticated
                                        navigate(RouteEndpoints.Login);
                                    }
                                }}
                                className='Nav-Option navbar-fade-in'
                            >
                                <span className="navbar-title">
                                    List Your Property
                                </span>
                                <br />
                                <span className="navbar-subtitle">
                                    Grow Your Business
                                </span>
                            </div>
                        </div>
                    }



                    <div className="navbar-item" onClick={() => setShowDropdown1(!showDropdown1)}>
                        <div className='navbar-fade-in'>
                            <span>
                                <FontAwesomeIcon className="navbar-icon" color='white' icon={faEnvelope} />
                            </span>
                        </div>
                        <div className='Nav-Option navbar-fade-in'>
                            <span className="navbar-title">
                                accomzy.living@gmail.com
                            </span>
                            <br />
                            <span className="navbar-subtitle">
                                Contact Us
                            </span>
                        </div>
                    </div>
                    {showDropdown1 && (
                        <div className='absolute top-20 right-96 bg-white px-10 rounded-lg'>
                            <div
                                onClick={() => {
                                    navigate('/contactUs')
                                }
                                }
                                className="mt-2 cursor-pointer"
                            >
                                <p className='text-sm'>Contact Us</p>
                            </div>
                            <div
                                onClick={() => {
                                    navigate('/reportAnIssue')
                                }
                                }
                                className="cursor-pointer"
                            >
                                <p className='text-sm'>Report An Issue</p>
                            </div>
                        </div>
                    )}


                </div>

            }
            {
                authMethods && <div

                    className="navbar-contact">
                    {
                        isLoading ? <>
                            <span style={{
                                color: "white"
                            }}>
                                loading
                            </span>
                        </> :

                            isAuthenticated ? (
                                <>

                                    <div className="cursor-pointer absolute right-4 " onClick={() => setDropdown2(!dropdown2)}
                                    >
                                        <span className=''>
                                            {isOwner ?
                                                <img src={Profile} width={40} height={40} alt="Profile" />
                                                : isUser ?
                                                    <img src={Profile} width={40} height={40} alt="Profile" />
                                                    : "Register Now"
                                            }
                                        </span>
                                        {dropdown2 && isOwner && (


                                            <div className=" absolute top-16 right-6 items-end bg-black/90 rounded-lg shadow-black ">

                                                <div className=" w-full mr-32 " onClick={() => navigate(RouteEndpoints.OwnerProfile)}>
                                                    <div className=' border-b-[1px] border-[#242424] items-end flex hover:opacity-70 duration-300 rounded-lg mr-4 mt-2.5'>
                                                        <p className='text-end text-sm w-full text-white '>Your Profile</p>
                                                    </div>
                                                </div>
                                                <div className=" w-full mr-32" onClick={() => logOut()}>
                                                    <div className=' border-b-[1px] border-[#242424] hover:opacity-70 duration-300 flex justify-end w-full items-end rounded-lg  mt-2.5'>
                                                        <div className='flex flex-row gap-x-2 items-center mr-4 '>
                                                            <img src={logOutIcon} width={20} height={10} alt="logout" className='mb-3 justify-end flex ' />
                                                            <p className=' text-end text-sm w-full text-white '>Log Out</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        )}
                                        {dropdown2 && isUser && (
                                            <div className="absolute top-16 right-6 items-end bg-black/90 rounded-lg shadow-black ">

                                                <div className=" w-full mr-32 " onClick={() => navigate(RouteEndpoints.UserProfile)}>
                                                    <div className=' border-b-[1px] border-[#242424] items-end flex hover:opacity-70 duration-300 rounded-lg mr-4 mt-2.5'>
                                                        <p className='text-end text-sm w-full text-white '>Your Profile</p>
                                                    </div>
                                                </div>
                                                <div className=" w-full mr-32" onClick={() => logOut()}>
                                                    <div className=' border-b-[1px] border-[#242424] hover:opacity-70 duration-300 flex justify-end w-full items-end rounded-lg  mt-2.5'>
                                                        <div className='flex flex-row gap-x-2 items-center mr-4 '>
                                                            <img src={logOutIcon} width={20} height={10} alt="logout" className='mb-3 justify-end flex ' />
                                                            <p className=' text-end text-sm w-full text-white '>Log Out</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>


                                    <div
                                        onClick={() => setShowDropdown(!showDropdown)}
                                        className=' cursor-pointer flex md:hidden'>

                                        <span style={{

                                            color: COLORS.Primary,
                                            paddingRight: "10px",
                                            paddingLeft: "10px",
                                            paddingTop: "5px",
                                            paddingBottom: "5px",
                                            borderRadius: "10px",
                                            fontSize: "24px",
                                            border: "1px solid",
                                            borderColor: COLORS.Primary
                                        }}>
                                            <FontAwesomeIcon icon={faBars} />
                                        </span>
                                    </div>


                                </>
                            )
                                : (
                                    // Render options for non-authenticated user
                                    <div className='flex flex-row gap-x-2'>
                                        <div
                                            onClick={() => setShowDropdown3(!showDropdown3)}
                                            className='navbar-burger'>

                                            <span style={{

                                                color: COLORS.Primary,
                                                paddingRight: "10px",
                                                paddingLeft: "10px",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                                borderRadius: "10px",
                                                fontSize: "24px",
                                                border: "1px solid",
                                                borderColor: COLORS.Primary
                                            }}>
                                                <FontAwesomeIcon icon={faBars} />
                                            </span>

                                        </div>

                                        <>
                                            <div
                                                onClick={() => {
                                                    navigate(RouteEndpoints.Login);
                                                }}
                                                className="navbar-login md:flex hidden"
                                            >
                                                <span>Login</span>
                                            </div>
                                            <div
                                                onClick={() => {
                                                    navigate(RouteEndpoints.Register);
                                                }}
                                                className="navbar-signup"
                                            >
                                                <span>Sign Up</span>
                                            </div>
                                        </>

                                    </div>
                                )
                    }

                </div>
            }

            {
                showDropdown3 &&
                <div className='navbar-dropdown'
                    style={{
                        height: "100vh",
                        width: "100vw",
                        backgroundColor: "black",
                        justifyContent: "center",
                        alignItems: "center",


                    }}>
                    <div
                        onClick={() => {
                            navigate(RouteEndpoints.Login)
                        }}
                        className=" mt-10 justify-center cursor-pointer items-start flex w-full h-full"
                    >
                        <p className='text-white font-bold text-lg'>Login</p>
                    </div>
                    <div
                        onClick={() => {
                            navigate(RouteEndpoints.Register);
                        }}
                        className="absolute top-16 mt-4 cursor-pointer justify-center flex w-full h-full"
                    >
                        <p className='text-white font-bold text-lg'>Register</p>
                    </div>
                </div>
            }
            {
                showDropdown && <div
                    className='navbar-dropdown'
                    style={{
                        height: "100vh",
                        width: "100vw",
                        position: "absolute",
                        backgroundColor: "black",
                        top: "9vh",
                    }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                        <div>
                            <div className="navbar-item"
                                onClick={() => {
                                    setShowDropdown(false);
                                    navigate(RouteEndpoints.Explore)
                                }
                                }
                                style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    borderBottom: "1px solid white",
                                    paddingBottom: "20px",
                                    borderLeft: "none",
                                    paddingLeft: "20px",
                                    borderRight: "none",
                                }}
                            >
                                <div className='navbar-fade-in'>
                                    <span>
                                        <FontAwesomeIcon className="navbar-icon" color='white' icon={faBuildingColumns} />
                                    </span>
                                </div>
                                <div
                                    onClick={() => {
                                        navigate(RouteEndpoints.Explore)
                                    }}
                                    className='Nav-Option navbar-fade-in'
                                >
                                    <span className="navbar-title">
                                        Explore Properties
                                    </span>
                                    <br />
                                    <span className="navbar-subtitle">
                                        More than 1000 Properies
                                    </span>
                                </div>
                            </div>
                            <div
                                onClick={() => {
                                    navigate(RouteEndpoints.DealsandOffers)
                                }} style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    borderBottom: "1px solid white",
                                    paddingBottom: "20px",
                                    borderLeft: "none",
                                    paddingLeft: "20px",
                                    borderRight: "none",
                                }}
                                className="navbar-item ">
                                <div className='navbar-fade-in'>
                                    <span>
                                        <FontAwesomeIcon className="navbar-icon" color='white' icon={faHandshake} />
                                    </span>
                                </div>
                                <div

                                    className='Nav-Option navbar-fade-in'
                                >
                                    <span className="navbar-title">
                                        Deals & Offers
                                    </span>
                                    <br />
                                    <span className="navbar-subtitle">
                                        More than 100 collaborators
                                    </span>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    borderBottom: "1px solid white",
                                    paddingBottom: "20px",
                                    borderLeft: "none",
                                    paddingLeft: "20px",
                                    borderRight: "none",
                                }}
                                className="navbar-item">
                                <div className='navbar-fade-in'>
                                    <span>
                                        <FontAwesomeIcon className="navbar-icon" color='white' icon={faBuilding} />
                                    </span>
                                </div>
                                <div
                                    onClick={() => {
                                        // Check if the owner is authenticated
                                        if (isAuthenticated) {
                                            navigate(RouteEndpoints.EnlistProperty);
                                        } else {
                                            // Redirect to the login page if not authenticated
                                            navigate(RouteEndpoints.Login);
                                        }
                                    }}
                                    className='Nav-Option navbar-fade-in'
                                >
                                    <span className="navbar-title">
                                        List Your Property
                                    </span>
                                    <br />
                                    <span className="navbar-subtitle">
                                        Grow Your Business
                                    </span>
                                </div>
                            </div>
                            <div
                                style={{
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                    borderBottom: "1px solid white",
                                    paddingBottom: "20px",
                                    borderLeft: "none",
                                    paddingLeft: "20px",
                                    borderRight: "none",
                                }}
                                className="navbar-item">
                                <div className='navbar-fade-in'>
                                    <span>
                                        <FontAwesomeIcon className="navbar-icon" color='white' icon={faEnvelope} />
                                    </span>
                                </div>
                                <div
                                    className='Nav-Option navbar-fade-in'
                                >
                                    {/* Use anchor tag to create email link */}
                                    <a href="mailto:accomzy.living@gmail.com" className="email-link">
                                        <span className="navbar-title">
                                            accomzy.living@gmail.com
                                        </span>
                                    </a>
                                    <br />
                                    <span className="navbar-subtitle">
                                        Contact Us
                                    </span>

                                </div>
                            </div>
                        </div>
                        <div>

                        </div>
                    </div>
                </div>
            }
        </div >
    );
}
