import React, { useEffect, useState } from 'react'
import Navbar from '../../../Components/Navbar/Navbar'
import { getProfile } from '../../../API/site/getProfile'
import { COLORS } from '../../../Utils/Colors'
import cookie from 'react-cookies';
import { getSavedRooms } from '../../../API/site/getSavedRoom'
import RoomCard from '../../../Components/Site/Explore/RoomCard'
import RoomCardNew from '../../../Components/Site/Explore/RoomCardNew'
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import toast from 'react-hot-toast';
import { storage } from '../../../Utils/firebase'
import { updateProfile } from '../../../API/auth/updateProfile'
import { updateUserProfile } from '../../../API/site/updateUserProfile';
import { logOut } from '../../../Utils/logOut';


export default function UserProfile() {
  const session = cookie.load('session');
  console.log("Session", session)

  const chooseProfilePic = async () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = async e => {
      // getting a hold of the file reference
      var file = e.target.files[0];
      // setting up the reader
      var reader = new FileReader();
      reader.readAsDataURL(file); // this is reading as data url
      // here we tell the reader what to do when it's done reading...
      reader.onload = async readerEvent => {
        var content = readerEvent.target.result; // this is the content!
        console.log(content)
        //check if file is image
        if (!file.type.includes("image")) {
          toast.error("Please upload an image file")
          return
        }
        //check if file size is less than 2mb
        if (file.size > 3000000) {
          toast.error("File size should be less than 3mb")
          return
        }
        //upload to firebase

        //set image random name woth its extension
        const imageName = Math.random().toString(36).substring(2) + '.' + file.name.split('.').pop();

        await storage.ref('profile_images/' + imageName).put(file).then((snapshot) => {
          console.log('Uploaded a blob or file!');
          toast.success("Image uploaded successfully!"); // Toast success message
        }
        ).catch(err => {
          console.log(err)
        })

        //get download url
        const downloadURL = await storage.ref('profile_images/' + imageName).getDownloadURL().then((url) => {
          console.log(url)
          return url
        }
        ).catch(err => {
          console.log(err)
        })
        //update profile image





        setData({
          ...data,
          profile_image: downloadURL
        })
        updateUserProfile({
          Name: data.Name,
          ProfilePhoto: downloadURL
        }).then(res => {
          console.log(res)

          toast.success("Profile picture updated successfully!")
          window.location.reload()
        }
        ).catch(err => {
          console.log(err)
        }
        )

      }
    }
    input.click();
  }



  const [data, setData] = useState({})
  const [savedRooms, setSavedRooms] = useState([])

  useEffect(() => {
    getProfile().then(data => {

      console.log("++++++PROFILE++++++")
      console.log(data.userData)
      console.log("++++++PROFILE++++++")
      setData(data.userData)
    }
    ).catch(err => {
      console.log(err)
    }
    )
    getSavedRooms().then(savedProperties => {
      console.log("++++++SAVED ROOMS++++++")
      console.log(savedProperties)
      setSavedRooms(savedProperties)
      console.log("++++++SAVED ROOMS++++++")

    }
    ).catch(err => {
      console.log("++++++SAVED ROOMS Error +++++")
      console.log(err)
      console.log("++++++SAVED ROOMS Error +++++")
    }
    )
  }, [])


  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);
  const [editedName, setEditedName] = useState(data.name || "");
  const [editedPhoneNumber, setEditedPhoneNumber] = useState(data.phone_number || "");


  const handleSaveName = () => {
    setData({ ...data, name: editedName });
    setIsEditingName(false);
    updateUserProfile({
      Name: editedName,
      ProfilePhoto: data.ProfilePhoto
    }).then(res => {
      console.log(res)

      toast.success("Name updated successfully!")
      window.location.reload()
    }
    ).catch(err => {
      console.log(err)
    }
    )

  };

  const isValidPhoneNumber = (number) => {
    return /^\d{10}$/.test(number);
  };

  const handleSavePhoneNumber = () => {
    if (!isValidPhoneNumber(editedPhoneNumber)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }

    setData({ ...data, phone_number: editedPhoneNumber });
    setIsEditingPhoneNumber(false); // Disable edit mode
  };



  return (
    <div
      style={{
        width: '100%',

        overflowY: 'hidden',
        fontFamily: 'Montserrat',
      }}
    >
      <>
        <Helmet>

          <title>User Profile | Accomzy
          </title>
          <meta name="description" content="Discover convenient and comfortable living with Accomzy! Find the perfect PGs, hostels, and apartments for students. Simplify your accommodation search with our user-friendly platform. Your ideal living space is just a click away. Start your housing journey with Accomzy today!">
          </meta>
          <meta name='og:title' content='User Profile | Accomzy' />
          <meta name='og:description' content="Discover convenient and comfortable living with Accomzy! Find the perfect PGs, hostels, and apartments for students. Simplify your accommodation search with our user-friendly platform. Your ideal living space is just a click away. Start your housing journey with Accomzy today!" />
          <meta name='og:url' content='https://accomzy.in/' />
          <meta name='og:image' content='https://i.ibb.co/wRs2VQX/file-cover-1.png' />


        </Helmet>
      </>
      <div className='Home-Navbar-Container'>
        <Navbar />
      </div>

      <div className='Home-Main-Container'>

        <div style={{
          height: '100vh',
          width: '100vw',
          backgroundImage: "url('https://i.ibb.co/tPQtPSL/fadeGrid.png') ",
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',




        }}
        >
          <div style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: "70px"

          }}>
            <span style={{
              fontSize: '24px',
              fontWeight: "bold",
              color: "white"
            }}>
              Your Profile
            </span>

          </div>
          <div style={{
            display: 'flex',
            width: '100%',
            height: "100%",



            alignItems: 'center',
            marginTop: "70px",
            flexDirection: "column",
            overflowY: "none"


          }}>
            <div style={{
              width: "90%",
              backgroundSize: "cover",
              backgroundColor: "rgba(0,0,0,0.5)",
              backgroundImage: 'linear-gradient(to bottom right, rgba(255,255,255,0.2), rgba(255,255,255,0))',
              boxShadow: "0px 0px 10px 0px #676767",
              display: 'flex',
              backdropFilter: "blur(10px)",
              flexDirection: "column",
              alignItems: 'center',
              marginTop: "70px",
              borderRadius: "10px",
            }}>
              <div style={{
                position: "relative",
                bottom: "80px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}>
                <div style={{
                  display: "block",
                  width: "150px",
                  height: "150px",


                }}>
                  <img
                    style={{
                      objectFit: "cover",
                      minWidth: "100%",
                      minHeight: "100%",
                      maxWidth: "100%",
                      maxHeight: "100%",
                      borderRadius: "50%"
                    }}
                    src={

                      data.ProfilePhoto ? data.ProfilePhoto :
                        "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"} alt="Profile-Pic" border="0" />

                </div>
                <div>
                  <span
                    onClick={chooseProfilePic}

                    style={{
                      position: "relative",

                      fontSize: "14px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "8px",
                      paddingRight: "8px",
                      backgroundColor: COLORS.Primary,
                      borderRadius: "50%",
                      fontWeight: "bold",
                      color: "white",
                      cursor: "pointer",
                      bottom: "10px",

                    }}>
                    <FontAwesomeIcon
                      color='black'
                      icon={faPen} />
                  </span>
                </div>
                <div>
                  {/* Edit Name */}
                  {isEditingName ? (
                    <div>
                      <input
                        className='bg-transparent text-white outline-none border-[1px] border-gray-300 rounded-md py-1 pl-4 pr-2 mr-4'
                        type="text"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                      />
                      <button className='text-white' onClick={handleSaveName}>Save</button>
                    </div>
                  ) : (
                    <div>
                      <span className='text-white font-bold mr-4 '>{data.Name}</span>
                      <FontAwesomeIcon
                        color='white'
                        icon={faPen}
                        onClick={() => setIsEditingName(true)}
                      />
                    </div>
                  )}
                </div>
                <div style={{
                  marginTop: "10px"
                }}>
                  <span style={{
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#676767"
                  }}>
                    {data.is_user && "User"}
                    {data.is_owner && "Owner"}
                  </span>
                </div>
                {isEditingPhoneNumber ? (
                  <div>
                    <input
                      className='bg-transparent text-white outline-none border-[1px] border-gray-300 rounded-md py-1 pl-4 pr-2 mr-4'
                      type="text"
                      value={editedPhoneNumber}
                      onChange={(e) => setEditedPhoneNumber(e.target.value)}
                    />
                    <button className='text-white' onClick={handleSavePhoneNumber}>Save</button>
                  </div>
                ) : (
                  <div>
                    <span className='text-white font-bold mr-4 '>{data.PhoneNumber ? `+91 ${data.PhoneNumber}` : ""}</span>

                  </div>
                )}
                <div style={{
                  marginTop: "20px"
                }}>
                  <span style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: COLORS.Primary,
                    borderBottom: "2px solid " + COLORS.Primary
                  }}>
                    YOUR SAVED PROPERTIES
                  </span>
                </div>
                <div style={{
                  marginTop: "40px",
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  padding: "20px",
                  justifyContent: "space-evenly"

                }}>
                  {
                    savedRooms.map((item, index) => {
                      return (
                        <div
                          style={{
                            width: "100%",

                            marginTop: "20px",
                            border: "1px solid #676767",
                            borderRadius: "10px",
                            boxShadow: "0px 0px 10px 0px #676767",
                          }}
                        >
                          <RoomCardNew roomCard={item} room={item} />
                        </div>
                      )
                    })
                  }
                </div>
              </div>

            </div>



          </div>

        </div>

        <div>

          <button
            onClick={() => {
              logOut()
            }}
            style={{
              position: 'fixed',
              bottom: '20px',
              right: '20px',
              padding: '20px',
              paddingTop: "10px",
              paddingBottom: "20px",
              borderRadius: '10%',


              color: 'white',
              fontWeight: 'bold',
              fontSize: '16px',
              cursor: 'pointer',

            }}
          >
            <div className="navbar-signup" >
              Sign Out
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}
