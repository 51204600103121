import React, { useState, useEffect } from 'react';
import PrimaryInput from '../Input/PrimaryInput';

const Autocomplete = ({ suggestions, onSelected, inputOnChange }) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);

    useEffect(() => {
        inputValue === "" && setShowSuggestions(false);
        setFilteredSuggestions(suggestions.filter(suggestion =>
            suggestion.InstituteName.toLowerCase().includes(inputValue.toLowerCase())
        ));
    }, [inputValue, suggestions]);

    const handleInputChange = (e) => {
        e.target.value === "" && setShowSuggestions(false);
        setInputValue(e.target.value);
        setShowSuggestions(true);
        inputOnChange(e);
    };

    const handleSuggestionClick = (suggestion) => {
        setInputValue(suggestion.InstituteName);
        setShowSuggestions(false);
        onSelected(suggestion.InstituteName);
    };

    return (
        <div className="autocomplete">
            <PrimaryInput


                Placeholder="Search For A NearBy Institute"
                bgColor={"#000000"}
                width={"100%"}
                value={inputValue}
                onChange={handleInputChange}

            />

            {showSuggestions && (
                <ul className="suggestion-list">
                    {filteredSuggestions.map((suggestion, index) => (
                        <li key={index}
                            style={{
                                backgroundColor: index === 0 ? "#f5f5f5" : "white",
                                padding: "10px",
                            }}
                            onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion.InstituteName}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Autocomplete;
