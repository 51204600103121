import React from 'react';
import { COLORS } from '../../../../Utils/Colors';

const ProgressBar = ({ currentSection, totalSections }) => {
    const progress = (currentSection / totalSections) * 100;

    const progressBarStyles = {
        width: '100%',
        height: '2px',
        backgroundColor: '#676767', // Gray background color
        borderRadius: '5px',
        position: 'relative',
    };

    const fillStyles = {
        height: '100%',
        width: `${progress}%`,
        backgroundColor: COLORS.Primary, // White fill color
        borderRadius: '5px',
        position: 'absolute',
        top: '0',
        left: '0',
        transition: 'width 0.3s ease-in-out', // Smooth transition for width changes
    };

    return (
        <div style={progressBarStyles}>
            <div style={fillStyles}></div>
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white' }}>

            </div>
        </div>
    );
};

export default ProgressBar;
