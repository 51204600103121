import React, { useEffect } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import { Helmet } from 'react-helmet'
import { getAllRooms } from '../../API/site/getAllRooms'
import Search from '../../Components/Site/Explore/Search'
import '../../Styles/Site/Exlpore/Explore.css'
import ScrollToTopButton from '../../Components/Buttons/ScrollTopButton'
import FeatureCard from '../../Components/Home/FeatureCard'
import { faBuilding, faBuildingColumns, faBuildingShield, faBus, faCalendar, faHome, faSchool } from '@fortawesome/free-solid-svg-icons'
import OurValueSection from '../../Components/Home/OurValueSection'
import TestimonialCard from '../../Components/Home/TestimonialCard'
import { useNavigate } from 'react-router-dom'
import { RouteEndpoints } from '../../Utils/RouterConsts'
import { getFilterOptions } from '../../API/site/getFilterOptions'
import SearchMobile from '../../Components/Site/Explore/SearchMobile'
import TestimonialCardNew from '../../Components/Home/TestimonialCardNew'

const data = [
    {
        id: 1,
        title: "Convenience",
        description: "Prioritize ease of use, seamless booking, and user-friendly features to make the app a "
    },
    {
        id: 2,
        title: "Transparency",
        description: "Maintain transparency in listings, pricing, and policies, allowing students to make informed decisions when booking accommodations and transport."
    },
    {
        id: 3,
        title: "Community",
        description: "Foster a sense of community among users, connecting students and travelers with hosts and transportation providers in a friendly and supportive environment."
    },
    {
        id: 4,
        title: "Student-Centric",
        description: " Prioritize the unique needs and challenges of students by offering student-specific features, discounts, and resources."
    }
]

const testimonialData = [
    {
        Name: "Priyanshi Garg",
        Review: "Accomzy saved me so much time and effort during my first year of college. The platform's intuitive design and comprehensive listings made it easy to compare different housing options. I found a fantastic place with great roommates, all thanks to Accomzy",
        ProfileUrl: "https://s.yimg.com/lo/api/res/1.2/Q_8CT0KXqXhPX2uYAs5h7Q--/YXBwaWQ9YXBlY21lZGlhO3NtPTE7dz00NzE7aD00MDY-/https://media-mbst-pub-ue1.s3.amazonaws.com/creatr-uploaded-images/2020-02/1b135b50-5148-11ea-bf77-109a5a9d4ca6"
    },
    {
        Name: "Himanshu Tiwari",
        Review: "I was skeptical about finding decent accommodation close to my university, but Accomzy exceeded my expectations. The real-time updates and detailed property descriptions made the decision-making process smoother. I now have a fantastic place that meets all my requirements.",
        ProfileUrl: "https://www.careerguide.com/career/wp-content/uploads/2023/06/depositphotos_542532798-stock-photo-portrait-indian-college-boy-holding.webp"
    },
    {
        Name: "Harpreet Singh Bhatia",
        Review: "Navigating the housing market in a new country can be overwhelming, but Accomzy made it a breeze. The platform's tailored search options helped me find a cozy apartment close to my study location. I'm grateful for the convenience it brought to my study abroad experience",
        ProfileUrl: "https://st4.depositphotos.com/16405760/23694/i/450/depositphotos_236941312-stock-photo-portrait-young-attractive-beautiful-indian.jpg"
    },
];



export default function Explore() {
    useEffect(() => {
        window.scrollTo(0, 0);

    }, []);


    const navigate = useNavigate()
    const [cities, setCities] = React.useState([])
    const [institutions, setInstitutions] = React.useState([])
    const [occupancy, setOccupancy] = React.useState(undefined)
    const [propertyType, setPropertyType] = React.useState(undefined)
    const [selectedOption, setSelectedOption] = React.useState(null)
    const [page, setPage] = React.useState(1)
    const [maxPrice, setMaxPrice] = React.useState(undefined)
    const [latitude, setLatitude] = React.useState(undefined)
    const [longitude, setLongitude] = React.useState(undefined)
    const [amenitiesAvailable, setAmenitiesAvailable] = React.useState([])
    const [searchQuery, setSearchQuery] = React.useState(undefined)

    const handleScroll = () => {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        // Load more results when scrolled to the bottom (adjust the threshold as needed)
        if (windowHeight + scrollTop + 20 >= documentHeight) {
            setPage((prevPage) => prevPage + 1);
        }
    };
    useEffect(() => {
        getFilterOptions().then(res => {
            console.log(res)
            setCities(res.Cities)
            setInstitutions(res.Institutes)
        }
        ).catch(err => {
            console.log(err)
        }
        )
    }
        , [])


    return (
        <div className='bg-[#090909] h-screen'>
            <ScrollToTopButton />
            <Helmet>
                <title>Explore Accommodations Near You | Accomzy
                </title>
                <meta name='og:description' content="Discover convenient and comfortable living with Accomzy! Find the perfect PGs, hostels, and apartments for students. Simplify your accommodation search with our user-friendly platform. Your ideal living space is just a click away. Start your housing journey with Accomzy today!" />
                <meta name='og:url' content='https://accomzy.in/' />
                <meta name='og:image' content='https://i.ibb.co/wRs2VQX/file-cover-1.png' />
                <meta name="description" content="Discover convenient and comfortable living with Accomzy! Find the perfect PGs, hostels, and apartments for students. Simplify your accommodation search with our user-friendly platform. Your ideal living space is just a click away. Start your housing journey with Accomzy today!">
                </meta>
                <meta name='og:title' content='Explore Accommodations Near You | Accomzy' />
            </Helmet>
            <div style={{
                position: 'fixed',
                width: '100%',
                zIndex: '100',
                

            }} >
                <Navbar />
            </div>

            <div style={{

                backgroundImage: 'url(https://i.ibb.co/93GWD0B/Group-464.png)',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                borderBottomLeftRadius: '40px', borderBottomRightRadius: '40px'
            }}
            className='flex justify-center items-center'>

               
                    <Search onfirstOption={(e) => {
                        setOccupancy(e)
                    }}
                    onsecondOption={(e) => {

                        setPropertyType(e)
                    }}
                    value={searchQuery}
                    onChange={(e) => {

                        setSearchQuery(e.target.value)
                    }
                    }
                    onPress={() => {
                        navigate(RouteEndpoints.ExploreSearch, {
                            state: {
                                searchQuery: searchQuery,
                                occupancy: occupancy,
                                propertyType: propertyType,
                            }
                        })
                    }
                    }
                    showText={false}
                    CoordinateChange={(Coordinates) => {
                        const { latitude, longitude } = Coordinates

                        navigate(RouteEndpoints.ExploreSearch, {
                            state: {
                                latitude,
                                longitude
                            }
                        })
                    }}/> 
                    
                    <SearchMobile onfirstOption={(e) => {
                        setOccupancy(e)
                    }}
                    onsecondOption={(e) => {

                        setPropertyType(e)
                    }}
                    value={searchQuery}
                    onChange={(e) => {

                        setSearchQuery(e.target.value)
                    }
                    }
                    onPress={() => {
                        navigate(RouteEndpoints.ExploreSearch, {
                            state: {
                                searchQuery: searchQuery,
                                occupancy: occupancy,
                                propertyType: propertyType,
                            }
                        })
                    }
                    }
                    showText={false}
                    CoordinateChange={(Coordinates) => {
                        const { latitude, longitude } = Coordinates

                        navigate(RouteEndpoints.ExploreSearch, {
                            state: {
                                latitude,
                                longitude
                            }
                        })
                    }}/>
            



            </div>
            <div style={{
                marginTop: '50px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
                <div className="Section6-content">
                    <span className="Section6-text">
                        Search {" "}
                        <span className="Section6-highlight">
                            By{" "}
                        </span>

                    </span>
                </div>

            </div>
            <div style={{
                display: "flex",

                flexWrap: 'wrap',
                marginTop: "50px",
                justifyContent: 'space-evenly',
                marginBottom: "100px",

            }}>

                <div className='flex justify-center items-center'>
                    <FeatureCard
                        description={"Find the perfect accommodations In Your City"}
                        onClick={() => {

                            setSelectedOption('Cities')
                            //just scroll down a bit
                            window.scrollTo(0, 1000);
                        }}
                        icon={faBuildingColumns}
                        Heading={"Cities"}
                    />

                </div>
                <div className='flex justify-center items-center'>
                    <FeatureCard
                        description={"Find the perfect accommodations near your college or workplace"}
                        onClick={() => {

                            setSelectedOption('Institutions')
                            window.scrollTo(0, 1000);
                        }}
                        icon={faSchool}
                        Heading={"Institutions"}
                    />

                </div>

            </div>

            {
                selectedOption && <div style={{
                    marginTop: '50px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    justifyItems: 'center',
                    alignItems: 'center',
                }}>
                    <div className="Section6-content">
                        <span className="Section6-text">
                            Popular  {" "}
                            <span className="Section6-highlight">
                                {
                                    selectedOption === 'Cities' ? "Cities" : "Institutions"
                                }

                            </span>

                        </span>
                    </div>
                    <div style={{
                        marginTop: "10px",
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        width: "80%",
                    }}>


                        {
                            selectedOption == "Cities" &&
                            cities.sort().map((city) => {
                                const cityExists = false

                                return (
                                    <div
                                        onClick={() => {
                                            navigate(RouteEndpoints.ExploreSearch, {
                                                state: {
                                                    city: city
                                                }
                                            })
                                        }
                                        }
                                        className='Section5-Hoverable-Button'


                                        style={{
                                            backgroundColor: cityExists ? "#676767" : null,
                                        }}
                                    >
                                        <span style={{ color: "white" }}>
                                            <span style={{ fontSize: '20px' }}>
                                                {city}
                                            </span>
                                        </span>
                                    </div>
                                );
                            })


                        }
                        {
                            selectedOption == "Institutions" &&
                            institutions.sort().map((institution) => {
                                const cityExists = false

                                return (
                                    <div
                                        onClick={() => {
                                            navigate(RouteEndpoints.ExploreSearch, {
                                                state: {
                                                    institute: institution
                                                }
                                            })
                                        }
                                        }
                                        className='Section5-Hoverable-Button'


                                        style={{
                                            backgroundColor: cityExists ? "#676767" : null,
                                        }}
                                    >
                                        <span style={{ color: "white" }}>
                                            <span style={{ fontSize: '20px' }}>
                                                {institution}
                                            </span>
                                        </span>
                                    </div>
                                );
                            })


                        }

                    </div>
                </div>
            }
            <div style={{
                paddingLeft: '20px',
                paddingRight: '20px',
                textAlign: 'center',
                marginTop: '150px',
            }}>
                <span style={{
                    fontSize: '40px',
                    color: 'white',
                    fontFamily: 'Montserrat',
                    fontWeight: 'bold'
                }}>
                    Core  {" "}
                    <span style={{
                        color: '#8A38F5'
                    }}>
                        Values
                    </span>
                </span>
            </div>
            <div className='flex flex-wrap gap-x-24 gap-y-10 justify-center items-center w-full mt-4 mb-20'>
                                {data.map((item, index) => (
                                    <OurValueSection key={index} data={item} />
                                ))}
                            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',

                marginTop: "20px",
                marginBottom: "50px",
                flexWrap: 'wrap'

            }}>     <div className="Section6-content">
                    <span className="Section6-text" style={{
                        marginTop: '50px',
                        marginBottom: '50px'
                    }}>
                        What Students {" "}
                        <span className="Section6-highlight">
                            Say{" "}
                        </span>
                        About Us?
                    </span>
                </div>

                <div className="md:px-0 px-4 w-full flex md:flex-wrap md:flex-row flex-col  justify-around mt-32 mb-20 gap-x-5">
                            {testimonialData.map((testimonial, index) => (
                                <div key={index} className='w-full md:w-[25%] mt-20'>
                                    <TestimonialCardNew data={testimonial} />
                                </div>
                            ))}
                        </div>

            </div>




        </div >
    )
}
