import React from 'react'

export default function PrimaryButton({ Text, onClick }) {

    const styles = {
        button: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "40px",
            backgroundColor: "white",
            paddingTop: '10px',
            fontSize: "13px",
            paddingBottom: '10px',
            paddingLeft: '20px',
            paddingRight: '20px',
            borderRadius: '25px',
            color: "black",
            fontWeight: 'bold',
            cursor: 'pointer',
            //it should glow when hovered

        }
    }

    return (
        <div


            onClick={onClick ? onClick : () => {
                alert("Please add onClick function to the button through props")
            }}

            style={styles.button}>
            <span style={{

            }}>
                {
                    Text ? Text : "Button"
                }

            </span>
        </div>
    )
}
