import axios from "axios";
import { prod_ENV, test_ENV } from "./constants";
export const env = prod_ENV;


const apiInstance = axios.create({
    baseURL: env.baseURL,
})


export default apiInstance;