import apiInstance from "..";
import { apiEndpoints } from "../constants";

export const loginUser = ({ PhoneNumber }) => {
    console.log("register" + PhoneNumber);
    return new Promise(async (resolve, reject) => {

        if (!PhoneNumber) {
            reject("Phone number is required");
        }
        if (PhoneNumber.length !== 10) {
            reject("Phone number must be 10 digits");
        }

        try {
            const response = await apiInstance.post(apiEndpoints.login, {

                PhoneNumber,


            }
            );
            resolve(response.data);
        } catch (error) {

            reject(error);
        }
    });
};
