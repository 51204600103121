export const test_ENV = {
    baseURL: "http://localhost:4000"
}

export const prod_ENV = {
    baseURL: "https://api.accomzy.in"
}


export const apiEndpoints = {
    sendOTP: "login/",
    verifyOTP: "verify/",
    getAllInstitutes: "/Property/getAllInstitutes",
    roomDetails: "/Property/getPropertyDetails",
    verifySession: "/auth/checkAuth",
    register: "/User/Register",
    getYourListedProfile: "/Property/getYourListedProperties",
    login: "/User/Login",
    getAllRooms: "/Property/getAllProperties/",
    addPropertyApi: '/Property/RegisterProperty',
    getReview: "room_reviews/",
    postReview: "/Property/PostReview",
    getProfile: "/User/getProfile",
    getFilterOptions: "/Property/getFilterOptions",
    SearchProperty: "/Property/SearchProperty",
    updateUserProfile: "/User/updateUserProfile",
    getSavedRooms: "/User/getSavedProperties",
    updateSaveProperty: "/User/updateSaveProperty/",
    editProfile: "editProfile/",
    chatWithUser: "/Chat/chatWithUser",
    sendMessage: "/Chat/sendMessage",
    getAllChatlist: "/Chat/getAllChatlist",
}