import React from 'react';
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';
import { useNavigate } from 'react-router';
import logo from '../../Assets/newLogo.png';
import { RouteEndpoints } from '../../Utils/RouterConsts';

export default function Footer() {
  const navigate = useNavigate();
  const pdfUrl = 'https://drive.google.com/file/d/18z6CThHXHnSg_7MD9yyTPQICEfCiBvaJ/view?usp=sharing';

  const handleOpenPdf = () => window.open(pdfUrl, '_blank');

  return (
    <div className="flex flex-col md:flex-row md:justify-between md:flex-wrap bg-[#131313] p-4">
      <div className="flex flex-col md:pl-32 md:items-start">
        <div className="pt-4 md:pt-8">
          <div className="flex gap-x-4 items-center">
            {[FaFacebook, FaInstagram, FaTwitter].map((Icon, index) => (
              <div key={index} className="cursor-pointer">
                <span className="text-gray-500 text-2xl font-bold"><Icon /></span>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-4 md:mt-8 mb-4 md:mb-0">
          <img src={logo} className="w-16 h-16 md:w-24 md:h-24" alt="AccomzyLogo" />
        </div>
      </div>
      <div className="hidden md:flex flex-col justify-center items-center md:py-5">
        <div className="flex flex-col md:flex-row md:justify-between md:py-5">
          <div className="md:pr-8 md:border-r md:border-[#9F9F9F] md:space-y-4">
            <div className="mb-2 md:mb-0">
              <span
                className="text-gray-500 text-sm cursor-pointer"
                onClick={handleOpenPdf}
              >
                Terms and Conditions
              </span>
            </div>
            <div className="mb-2 md:mb-0">
              <span className="text-gray-500 text-sm">Student Policy</span>
            </div>
            <div className="mb-2 md:mb-0">
              <span
                className="text-gray-500 text-sm cursor-pointer"
                onClick={() => navigate(RouteEndpoints.PrivacyPolicy)}
              >
                Privacy Policy
              </span>
            </div>
            <div>
              <span className="text-gray-500 text-sm">Trust and Safety</span>
            </div>
          </div>
          <div className=" md:mt-0 md:px-8 md:space-y-4">
            <div className="mb-2 md:mb-0">
              <span className="text-gray-500 text-sm">About Us</span>
            </div>
            <div className="mb-2 md:mb-0">
              <span className="text-gray-500 text-sm">Team Info</span>
            </div>
            <div
              onClick={() => navigate('/contactUs')}
              className="cursor-pointer"
            >
              <span className="text-gray-500 text-sm">Contact Us</span>
            </div>
            <div>
              <span className="text-gray-500 text-sm">Help & Support</span>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden flex flex-row w-full justify-between mb-16 md:py-5 ">
        <div className="flex flex-row justify-between w-full md:py-5">
          <div className="pr-8 border-r border-[#9F9F9F] space-y-4">
            <div className="mb-2 md:mb-0">
              <span
                className="text-gray-500 text-sm cursor-pointer"
                onClick={handleOpenPdf}
              >
                Terms and Conditions
              </span>
            </div>
            <div className="mb-2 md:mb-0">
              <span className="text-gray-500 text-sm">Student Policy</span>
            </div>
            <div className="mb-2 md:mb-0">
              <span
                className="text-gray-500 text-sm cursor-pointer"
                onClick={() => navigate(RouteEndpoints.PrivacyPolicy)}
              >
                Privacy Policy
              </span>
            </div>
            <div>
              <span className="text-gray-500 text-sm">Trust and Safety</span>
            </div>
          </div>
          <div className=" md:mt-0 px-8 space-y-4">
            <div className="mb-2 md:mb-0">
              <span className="text-gray-500 text-sm">About Us</span>
            </div>
            <div className="mb-2 md:mb-0">
              <span className="text-gray-500 text-sm">Team Info</span>
            </div>
            <div
              onClick={() => navigate('/contactUs')}
              className="cursor-pointer"
            >
              <span className="text-gray-500 text-sm">Contact Us</span>
            </div>
            <div>
              <span className="text-gray-500 text-sm">Help & Support</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
