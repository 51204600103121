// Import React and CSS file
import * as React from 'react';
import Modal from '@mui/material/Modal';

import '../../Styles/Components/AllReviewModal.css'
import { Carousel } from 'react-responsive-carousel';
import ReviewCard from '../Home/ReviewCard';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

// Create a ref for the modal
export const AllReviewModalRef = React.createRef();

// Expose a function to open the modal
export function openAllReviewModal() {
    if (AllReviewModalRef.current) {
        AllReviewModalRef.current.handleOpen();
    }
}

export default function AllReviewModal({ Reviews }) {

    const [open, setOpen] = React.useState(false);

    // Expose the handleOpen function
    const handleOpen = () => setOpen(true);
    const handleClose = (event, reason) => {

        setOpen(false);
    }


    // Set the ref to the modal component
    React.useEffect(() => {
        AllReviewModalRef.current = { handleOpen };
    }, []);
    return (
        <Modal

            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"

            aria-describedby="modal-modal-description"
        >
            <div style={{
                width: '100%',
                height: '100%',

                justifyContent: 'center',

                alignItems: 'center',


            }}>

                {
                    Reviews.map((review, index) => {
                        return (
                            <div style={{
                                margin: "2px"
                            }}>
                                <ReviewCard
                                    review={review}
                                />
                            </div>

                        )
                    }

                    )
                }

            </div>
        </Modal>
    );
}
