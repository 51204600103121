import React, { useEffect, useRef, useState } from 'react';
import '../../../../Styles/Site/Broker/PropertyForms/Section3.css';
import PrimaryInput from '../../../Input/PrimaryInput';
import { COLORS } from '../../../../Utils/Colors';
import getLocation from '../../../../Utils/locationUtils';
import { AddressAutofill } from '@mapbox/search-js-react';
import Toast from 'react-hot-toast'
import { Autocomplete, useLoadScript, Marker, GoogleMap } from '@react-google-maps/api';
import MapContainer from '../../Containers/MapContainers';

const placesLibrary = ['places']
export default function Section3({ formData, onChange, setFormData, basicFunction, flashBackground }) {
  useEffect(() => {
    // If flashBackground is true, schedule a reset after 1000 milliseconds (1 second)

    setmanualAddressMode(true)


  }, [flashBackground]);

  const [manualAdrressMode, setmanualAddressMode] = React.useState(false)
  const [city, setCity] = React.useState("")
  const [area, setArea] = React.useState("")
  const [pinCode, setPinCode] = React.useState("")
  const [latitude, setLatitude] = React.useState(22.3072)
  const [longitude, setLongitude] = React.useState(73.1812)
  const [houseNumber, setHouseNumber] = React.useState("")
  const [state, setState] = React.useState("")
  const [country, setCountry] = React.useState("")
  const [searchResult, setSearchResult] = useState('')

  const autocompleteRef = useRef();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAzmyP0VcjESUp7X8kTE4MLSEn-obVm45Y',
    libraries: placesLibrary
  });

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }
  const extractAddressComponent = (place, componentType) => {
    const component = place.address_components.find(
      (c) => c.types.indexOf(componentType) !== -1
    );

    return component ? component.long_name : null;
  };

  useEffect(() => {



    // setArea(formData.propertyAddress.Area)
    // setCity(formData.propertyAddress.City)
    // setPinCode(formData.propertyAddress.PinCode)
    // setLatitude(formData.propertyAddress.latitude)
    // setLongitude(formData.propertyAddress.longitude)
    // setHouseNumber(formData.propertyAddress.HouseNumber)
    // setState(formData.propertyAddress.State)
    // setCountry(formData.propertyAddress.Country)



    if (formData.atYourProperty) {
      console.log("Getting Location")
      getLocation().then(res => {
        const address = res.addressDetails
        console.log(address)
        setCity(address.state_district)
        setArea(address.county)
        setPinCode(address.postcode)
        setmanualAddressMode(true)
      }).catch(err => {
        console.log("Error", err)
      }).finally(() => {
        console.log("COde EXECUTED For Getting Location")
        setmanualAddressMode(true)
      })
    }
  })
  function onPlaceChanged() {
    if (searchResult != null) {
      //variable to store the result
      const place = searchResult.getPlace();

      const areaFromMap = extractAddressComponent(place, "sublocality_level_1") || extractAddressComponent(place, "sublocality") || extractAddressComponent(place, "neighborhood");
      const cityFromMap = extractAddressComponent(place, "locality") || extractAddressComponent(place, "administrative_area_level_2")
      const stateFromMap = extractAddressComponent(place, "administrative_area_level_1") || extractAddressComponent(place, "administrative_area_level_2")
      const houseNumberFromMap = extractAddressComponent(place, 'neighborhood') || extractAddressComponent(place, 'sublocality_level_1') ||
        extractAddressComponent(place, 'sublocality_level_2') || extractAddressComponent(place, "street_number") || extractAddressComponent(place, "premise")
      const countryFromMap = extractAddressComponent(place, "country") || extractAddressComponent(place, "administrative_area_level_1")
      const pinCodeFromMap = extractAddressComponent(place, "postal_code") || extractAddressComponent(place, "postal_code_prefix")
      //console log all results
      console.log("+++++++")
      console.log(place)
      console.log("+++++++")

      //get lat and long
      const lat = place.geometry.location.lat()
      const lng = place.geometry.location.lng()
      console.log("++LAT++"
        , lat)
      console.log("++LNG++"
        , lng)

      setLatitude(lat)
      setLongitude(lng)
      setArea(areaFromMap)
      setCity(cityFromMap)
      setHouseNumber(houseNumberFromMap)
      setState(stateFromMap)
      setCountry(countryFromMap)
      setPinCode(pinCodeFromMap)
      setCountry(countryFromMap)

      setFormData({
        ...formData,
        propertyAddress: {
          ...formData.propertyAddress,
          Area: areaFromMap,
          City: cityFromMap,
          HouseNumber: houseNumberFromMap,
          State: stateFromMap,
          Country: countryFromMap,
          PinCode: pinCodeFromMap,
          latitude: lat,
          longitude: lng

        }

      })




      //variable to store the name from place details result 

      // console.log(place);



    } else {
      alert("Please enter text");
    }
  }


  if (!isLoaded) {
    return <div>Loading...</div>
  };



  return (
    <div className="Section2">


      {
        manualAdrressMode ? <>
          <div className="Section2-content">
            <span className="Section2-text">
              Enter Your {" "}
              <span className="Section2-highlight">
                address{" "}
              </span>

            </span>
          </div>
        </> :
          <div className="Section2-content">
            <span className="Section2-text">
              Search for Your {" "}

              <span className="Section2-highlight">
                property{" "}
              </span>
              address
            </span>
          </div>
      }
      {
        manualAdrressMode ? <div style={{
          marginTop: "40px"
        }}>
          <PrimaryInput

            value={houseNumber}
            onChange={
              (e) => {
                setHouseNumber(e.target.value)
                setFormData({
                  ...formData,
                  propertyAddress: {
                    ...formData.propertyAddress,
                    HouseNumber: e.target.value
                  }

                })
              }
            }
            marginTop={"32px"}
            width={"100%"}

            Placeholder={"House Number"}
          />
          <PrimaryInput
            value={area}
            onChange={
              (e) => {
                setArea(e.target.value)
                setFormData({
                  ...formData,
                  propertyAddress: {
                    ...formData.propertyAddress,
                    Area: e.target.value
                  }

                })
              }
            }
            marginTop={"32px"}
            width={"100%"}
            Placeholder={"Area"}
          />
          <PrimaryInput
            onChange={
              (e) => {
                setCity(e.target.value)
                setFormData({
                  ...formData,
                  propertyAddress: {
                    ...formData.propertyAddress,
                    City: e.target.value
                  }

                })
              }
            }
            marginTop={"32px"}
            width={"100%"}
            Placeholder={"City"}
            value={city}
          />
          <PrimaryInput
            maxLength={6}

            onChange={
              (e) => {
                // number regex
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {

                  setPinCode(e.target.value)
                  setFormData({
                    ...formData,
                    propertyAddress: {
                      ...formData.propertyAddress,
                      PinCode: e.target.value
                    }

                  })
                }
                else {
                  Toast.error("Pin Code should be a number")
                }
              }
            }
            value={pinCode}
            marginTop={"32px"}
            width={"100%"}
            Placeholder={"Pin Code"}
          />
          <PrimaryInput
            hide={true}
            onChange={
              (e) => {
                setLatitude(e.target.value)
                setFormData({
                  ...formData,
                  propertyAddress: {
                    ...formData.propertyAddress,
                    latitude: e.target.value
                  }

                })
              }
            }
            value={latitude}
            marginTop={"32px"}
            width={"100%"}

            Placeholder={"Latitude"}
          />  <PrimaryInput
            hide={true}
            onChange={
              (e) => {
                setLongitude(e.target.value)
                setFormData({
                  ...formData,
                  propertyAddress: {
                    ...formData.propertyAddress,
                    longitude: e.target.value
                  }

                })
              }
            }
            value={longitude}
            marginTop={"32px"}
            width={"100%"}
            Placeholder={"Longitude"}
          />
          <PrimaryInput
            value={state}
            onChange={
              (e) => {
                setState(e.target.value)
                setFormData({
                  ...formData,
                  propertyAddress: {
                    ...formData.propertyAddress,
                    State: e.target.value
                  }

                })
              }
            }

            marginTop={"32px"}
            width={"100%"}
            Placeholder={"State"}

          />

          <PrimaryInput
            marginTop={"32px"}
            width={"100%"}
            value={country}
            onChange={
              (e) => {
                setCountry(e.target.value)
                setFormData({
                  ...formData,
                  propertyAddress: {
                    ...formData.propertyAddress,
                    Country: e.target.value
                  }

                })
              }
            }
            Placeholder={"Country"}
          />

        </div> :
          <div
            id="searchColumn"

            style={{
              marginTop: "50px"
            }}>



            <Autocomplete

              //  i want to style the options here




              onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
              <PrimaryInput
                autocomplete={"address-line1"}
                width={"100%"} borderRadius={"26px"} Placeholder={"Search By Name or Address...."} />

            </Autocomplete>

            <div style={{
              marginTop: "30px",
              paddingBottom: "60px",
              borderBottom: "1px solid white"
            }}>


              <span style={{
                color: "white"
              }}>
                Search by your property’s name if it’s already on Google
              </span>
            </div>
            <GoogleMap
              mapContainerStyle={{
                height: "400px",
                width: "100%"
              }}
              zoom={18}
              center={{
                lat: latitude,
                lng: longitude
              }}
            >
              <Marker position={{
                lat: latitude,
                lng: longitude
              }} />
            </GoogleMap>
            <div style={{
              marginTop: "30px"
            }}>
              <span style={{
                color: "white",
                fontSize: "18px",
                fontWeight: "bold"
              }}>
                Can’t find your property ?
              </span>
            </div>
            <div

              onClick={
                () => setmanualAddressMode(true)
              }

              style={{
                marginTop: "30px",
                cursor: "pointer"
              }}>
              <span style={{
                color: COLORS.Primary,
                fontSize: "14px",
                fontWeight: "bold",

              }}>
                Enter your address manually
              </span>
            </div>

          </div>

      }
      {
        manualAdrressMode && <>
          <div

            onClick={
              () => setmanualAddressMode(false)
            }

            style={{
              marginTop: "30px"
            }}>
            <span style={{
              color: COLORS.Primary,
              fontSize: "14px",
              fontWeight: "bold"
            }}>
              Enter your address using google search
            </span>
          </div>
        </>
      }

    </div >
  );
}
