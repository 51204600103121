// backend

import apiInstance from "..";
import { apiEndpoints } from "../constants";

export const getAllInstitutes = async ({ searchQuery = '' }) => {
    return new Promise(async (resolve, reject) => {

        try {
            apiInstance.post(apiEndpoints.getAllInstitutes, {

                searchQuery,

            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                console.log(err);
                reject(err);
            });
        } catch (error) {
            reject(error);
        }
    });
};
