import React, { useState, useRef } from 'react';
import { Autocomplete, useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';

const placesLibrary = ['places']


function MapWithASearchBox() {
    const [searchResult, setSearchResult] = useState('')

    const autocompleteRef = useRef();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAzmyP0VcjESUp7X8kTE4MLSEn-obVm45Y',
        libraries: placesLibrary
    });

    function onLoad(autocomplete) {
        setSearchResult(autocomplete);
    }

    function onPlaceChanged() {
        if (searchResult != null) {
            //variable to store the result
            const place = searchResult.getPlace();
            //variable to store the name from place details result 
            const name = place.name;
            //variable to store the status from place details result
            const status = place.business_status;
            //variable to store the formatted address from place details result
            const formattedAddress = place.formatted_address;
            // console.log(place);
            //console log all results
            console.log(`Name: ${name}`);
            console.log(`Business Status: ${status}`);
            console.log(`Formatted Address: ${formattedAddress}`);
        } else {
            alert("Please enter text");
        }
    }
    if (!isLoaded) {
        return <div>Loading...</div>
    };


    return (
        <div className="App">
            <div id="searchColumn">
                <h2>Tide Forecast Options</h2>
                <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
                    <input
                        type="text"
                        placeholder="Search for Tide Information"
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `240px`,
                            height: `32px`,
                            padding: `0 12px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`
                        }}
                    />
                </Autocomplete>
            </div>
        </div>
    )
}

export default MapWithASearchBox;