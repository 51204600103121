import React, { useState } from 'react';
import { AiOutlineRight } from "react-icons/ai";

function OurValueSection({ data }) {
  const [isHovered, setIsHovered] = useState(false);
  const {title, description} = data

  const defaultContainerStyle = {
    background: isHovered?  'rgba(28, 28, 28, 0.60)' : 'transparent',
    borderRadius: 24,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'box-shadow 0.3s ease',
    cursor: 'pointer',
    padding: '32px 24px',
    boxShadow: isHovered ? '0px 2px 20px 0px rgba(172, 111, 250, 0.90)' : '0px 2px 20px 0px rgba(172, 111, 250, 0)',
  };

  const mergedContainerStyle = { ...defaultContainerStyle };

  return (
    <div
      style={mergedContainerStyle}
      className="core-value-container w-full mx-4 md:w-[35%] border-[1px] border-gray-100 border-opacity-25 "
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <h1 className="font-semibold text-white text-3xl text-center md:text-4xl">{title}</h1>
      <p className="text-[#8B8B8B] md:text-base text-sm text-center">{description}</p>
      {/* <div className='flex flex-row items-center gap-x-2'>
      <button className="text-purple-700 text-lg">Read More</button>
        <AiOutlineRight className="text-purple-700 text-lg" />
      </div> */}
    </div>
  );
}

export default OurValueSection;
