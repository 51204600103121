
import cookie from 'react-cookies'
import apiInstance, { env } from '..'
import { apiEndpoints } from '../constants'
import axios from 'axios'
const verifySession = async () => {
    return new Promise(async (resolve, reject) => {
        const Token = cookie.load('Token')
        const UserId = cookie.load('UserId')
        console.log("Token and UserId from cookie")
        console.log(Token, UserId)
        console.log("Token and UserId from cookie")
        if (!Token || !UserId) {
            reject("No token or User Id found")
        }
        try {
            await axios({
                method: "POST",
                headers: {
                    Token: Token,
                    UserId: UserId
                },
                url: env.baseURL + "/auth/checkAuth"
            }).then((response) => {
                console.log("response from verify session")
                console.log(response)
                console.log("response from verify session")
                resolve(response)
            }).catch((error) => {
                console.log(error)
                reject(error)
            })



        } catch (error) {
            reject(error);
        }
    }
    )
}

export default verifySession