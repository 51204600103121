import React, { useState } from 'react';
import '../../../../Styles/Site/Broker/PropertyForms/Section5.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faBeerMugEmpty, faCalendar, faDog, faHandsWash, faMedal, faPeopleGroup, faShirt, faSmoking, faUtensilSpoon, faUtensils, faWifi } from '@fortawesome/free-solid-svg-icons';
import { Alcohal, Breakfast, Dinner, DoubleBed, Lunch, More, OnlyVeg, SingleBed, Smoking, Snacks, TripalBed } from '../../../../Utils/Icons';

export default function Section2({ formData, onChange, setFormData }) {

  const amenities = [
    {
      id: 1,
      name: "Attached Washroom"
    },
    {
      id: 2,
      name: "Attached Balcony"
    },
    {
      id: 3,
      name: "Attached Kitchen"
    },
    {
      id: 4,
      name: "Personal Closets"
    }
  ]

  const facilities = [
    {
      id: 1,
      name: "WiFi",
      icon: faWifi
    },
    {
      id: 2,
      name: "Cleanliness",
      icon: faHandsWash
    },
    {
      id: 3,
      name: "Meals",
      icon: faUtensils
    },
    {
      id: 4,
      name: "Laundry",
      icon: faShirt
    }
  ]

  const rules = [
    {
      id: 1,
      name: "No Smoking",
      icon: Smoking
    },
    {
      id: 2,
      name: "No Alcohol",
      icon: Alcohal
    },
    {
      id: 3,
      name: "Only Veg",
      icon: OnlyVeg
    }
  ]

  const occupancies = [
    {
      id: 1,
      name: "Single",
      icon: SingleBed
    },
    {
      id: 2,
      name: "Double",
      icon: DoubleBed

    },
    {
      id: 3,
      name: "Triple",
      icon: TripalBed
    }

  ]


  const meals = [
    {
      id: 1,
      name: "Breakfast",
      icon: Breakfast
    },
    {
      id: 2,
      name: "Lunch",
      icon: Lunch

    },
    {
      id: 3,
      name: "Snacks",
      icon: Snacks
    },
    {
      id: 4,
      name: "Dinner",
      icon: Dinner
    }

  ]


  const [selectedGender, setSelectedGender] = useState(formData.gender || null);

  const genderOptions = [
    { id: 'male', label: 'Male', color: 'blue' },
    { id: 'female', label: 'Female', color: 'pink' },
    { id: 'unisex', label: 'Unisex', color: 'purple' },
  ];

  const handleGenderChange = (gender) => {
    setSelectedGender(gender);
    setFormData({
      ...formData,
      gender: gender
    });
  };



  return (
    <div className="Section2">
      <div className="Section2-content">
        <span className="Section2-text">

          <span className="Section2-highlight">
            Facilities {" "}
          </span>
          in your property
        </span>
      </div>
      <div className="Section5-description">
        Let people know what your property has to offer!
      </div>

      <div
        style={{
          marginTop: "50px",
          display: "flex",
          width: "100%",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
          alignItems: 'flex-start'
        }}>
        <div style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
        }}>
          <div style={{
            marginTop: "20px"
          }}>
            <span style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "20px",
              fontFamily: "Plus Jakarta Sans"
            }}>
              Tap to choose facilities you have
            </span>
          </div>
          <div style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            display: 'flex',
            flexDirection: 'row',
            gap: '30px',
            alignItems: 'center',


          }}>
            {
              facilities.map((facility) => {
                const facilityExists = formData.facilities.find((item) => item.id === facility.id);

                return (
                  <div



                    onClick={() => {
                      if (facilityExists) {
                        setFormData({
                          ...formData,
                          facilities: formData.facilities.filter((item) => item.id !== facility.id)
                        });
                      } else {
                        setFormData({
                          ...formData,

                          // remove icon from here
                          facilities: [...formData.facilities, delete facility.icon && facility

                          ]
                        });
                      }
                    }}
                    style={{

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",


                    }}
                  >
                    <div style={{
                      color: "white",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100px",
                      alignItems: 'center',
                    }}>
                      <span
                        style={{
                          backgroundColor: facilityExists ? "#676767" : null,
                        }}
                        className='h-10 items-center justify-center flex rounded-xl w-10 p-2'
                      >
                        <FontAwesomeIcon icon={facility.icon} />

                      </span>
                      <br />
                      <span>
                        {facility.name}
                      </span>
                    </div>
                  </div>
                );
              }
              )
            }

          </div>
          <div style={{
            marginTop: "20px"
          }}>
            <span style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "20px",
              fontFamily: "Plus Jakarta Sans"
            }}>
              Occupancies
            </span>
          </div>
          <div style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80%",

          }}>
            {
              occupancies.map((occupancy) => {
                const occupanciesExists = formData.occupancies.find((item) => item.id === occupancy.id);

                return (
                  <div
                    className='Section5-Hoverable-Button'
                    onClick={() => {
                      if (occupanciesExists) {
                        setFormData({
                          ...formData,
                          occupancies: formData.occupancies.filter((item) => item.id !== occupancy.id)
                        });
                      } else {
                        setFormData({
                          ...formData,
                          occupancies: [...formData.occupancies, occupancy] // No need to use 'delete' here
                        });
                      }
                    }}
                    style={{
                      backgroundColor: occupanciesExists ? "#676767" : null,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{
                      color: "white",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "60px",
                      alignItems: 'center',
                    }}>
                      <div>
                        {/* Ensure that the icon component is rendered correctly */}
                        {occupancy.icon && <occupancy.icon />}
                      </div>
                      <br />
                      <span>
                        {occupancy.name}
                      </span>
                    </div>
                  </div>
                );
              })
            }


          </div>


          <div style={{
            marginTop: "20px"
          }}>
            <span style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "20px",
              fontFamily: "Plus Jakarta Sans"
            }}>
              Meals
            </span>
          </div>
          <div style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80%",

          }}>
            {
              meals.map((meal) => {
                const mealsExists = formData.meals.find((item) => item.id === meal.id);

                return (
                  <div
                    className='Section5-Hoverable-Button'
                    onClick={() => {
                      if (mealsExists) {
                        setFormData({
                          ...formData,
                          meals: formData.meals.filter((item) => item.id !== meal.id)
                        });
                      } else {
                        setFormData({
                          ...formData,
                          meals: [...formData.meals, meal] // No need to use 'delete' here
                        });
                      }
                    }}
                    style={{
                      backgroundColor: mealsExists ? "#676767" : null,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div style={{
                      color: "white",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "60px",
                      alignItems: 'center',
                    }}>
                      <div>
                        {/* Ensure that the icon component is rendered correctly */}
                        {meal.icon && <meal.icon />}
                      </div>
                      <br />
                      <span>
                        {meal.name}
                      </span>
                    </div>
                  </div>
                );
              })
            }


          </div>

        </div>

        <div className="flex flex-wrap items-center gap-x-6 mt-20 ">
          {genderOptions.map((option) => (
            <div key={option.id} className="flex  flex-row items-center">
              <label
                key={option.id}
                className="relative flex items-center cursor-pointer "
              >
                <input
                  type="radio"
                  className="hidden"
                  name="gender"
                  value={option.id}
                  onChange={() => handleGenderChange(option.id)}
                  checked={selectedGender === option.id}
                />
                <div
                  className={`w-5 h-5 border-2   rounded-full flex items-center border-purple-500 justify-center ${selectedGender === option.id ? 'bg-purple-500 ' : 'bg-transparent'}`}
                >
                  {selectedGender === option.id && (
                    <div className="w-3 h-3 bg-purple-500 rounded-full"></div>
                  )}
                </div>
                <span className="ml-2 text-white">{option.label}</span>
              </label>
            </div>
          ))}
        </div>



        <div style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          marginLeft: "20px"
        }}>

          <div style={{
            marginTop: "20px"
          }}>
            <span style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "20px",
              fontFamily: "Plus Jakarta Sans"
            }}>
              Amenities
            </span>
          </div>
          <div style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80%",
          }}>

            {
              amenities.map((amenity) => {
                const amenityExists = formData.amenities.find((item) => item.id === amenity.id);

                return (
                  <div
                    className='Section5-Hoverable-Button'

                    onClick={() => {
                      if (amenityExists) {
                        setFormData({
                          ...formData,
                          amenities: formData.amenities.filter((item) => item.id !== amenity.id)
                        });
                      } else {
                        setFormData({
                          ...formData,
                          amenities: [...formData.amenities, delete amenity.icon && amenity]
                        });
                      }
                    }}
                    style={{
                      backgroundColor: amenityExists ? "#676767" : null,
                    }}
                  >
                    <span style={{ color: "white" }}>
                      <span style={{ paddingLeft: "10px" }}>
                        {amenity.name}
                      </span>
                    </span>
                  </div>
                );
              })


            }

          </div>
          <div style={{
            marginTop: "40px"
          }}>
            <span style={{
              color: "white",
              fontWeight: "bold",
              fontSize: "20px",
              fontFamily: "Plus Jakarta Sans"
            }}>
              Rules
            </span>
          </div>
          <div style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "80%",
          }}>
            {
              rules.map((rule) => {
                const ruleExists = formData.rules.find((item) => item.id === rule.id);

                return (
                  <div
                    className='Section5-Hoverable-Button'


                    onClick={() => {
                      if (ruleExists) {
                        setFormData({
                          ...formData,
                          rules: formData.rules.filter((item) => item.id !== rule.id)
                        });
                      } else {
                        setFormData({
                          ...formData,
                          rules: [...formData.rules, delete rule.icon && rule]
                        });
                      }
                    }}
                    style={{
                      backgroundColor: ruleExists ? "#676767" : null,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",


                    }}
                  >
                    <div style={{
                      color: "white",
                      textAlign: "center",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "60px",
                      alignItems: 'center',
                    }}>
                      <span>
                        {rule.icon && <rule.icon />}

                      </span>
                      <br />
                      <span>
                        {rule.name}
                      </span>
                    </div>
                  </div>
                );
              }
              )
            }


          </div>

          <div>
            <textarea style={
              {
                marginTop: "40px",
                width: "80%",
                height: "100px",
                backgroundColor: "#3F3C3C",
                border: "1px solid #9F9F9F",
                borderRadius: "8px",
                color: "white",
                outline: "none",
                paddingLeft: "16px",
                paddingTop: "10px",
                fontSize: "16px",
                fontFamily: "Plus Jakarta Sans"
              }
            }
              placeholder="Enter any other details here"
              value={formData.otherDetails}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  otherDetails: e.target.value
                })
              }}
            >
              {formData.otherDetails}
            </textarea>

          </div>

        </div>
      </div>
    </div>
  );
}
