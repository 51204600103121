import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../../Components/Navbar/Navbar";
import accomzyUser from "../../../Assets/accomzyUser.png";
import ChatUserCard from "../../../Components/Site/Explore/ChatUserCard";
import {
    AudioOutlined,
    MoreOutlined,
    PhoneOutlined,
    PlusCircleOutlined,
    SmileOutlined,
} from "@ant-design/icons";
import { getAllChatlist } from "../../../API/Chat/getAllChatlist";
import { realtime } from "../../../Utils/firebase";
import { sendMessage } from "../../../API/Chat/sendMessage";
import './Chat.css'
import cookie from 'react-cookies';
import PrimaryInput from "../../../Components/Input/PrimaryInput";
import AudioPlayer from "../../../Utils/AudioPlayer";
// Import animate.css for animations

export default function Chat() {

    const UserId = cookie.load('UserId');
    const [chatUser, setChatUser] = useState([]);
    const [roomId, setRoomId] = useState(null);

    const getAllMessages = async (roomId) => {
        console.log("ROOM ID FROM GET ALL MESSAGES", roomId);
        if (!roomId) {
            console.log("NO ROOM ID");
            setMessages([]);
        }
        try {
            console.log("TRY FROM GET ALL MESSAGES");
            realtime.ref("messages").child(roomId).on("value", async (snapshot) => {
                const messages = [];
                await snapshot.forEach((snap) => {
                    messages.push(snap.val());
                });
                console.log("MESSAGES FROM GET ALL MESSAGES");
                console.log(messages);
                console.log("MESSAGES FROM GET ALL MESSAGES");
                messages.sort((a, b) => {
                    return a.createdAt - b.createdAt;
                });
                setMessages(messages);
                //check last message user id
                //if last message user id is not equal to current user id
                //play audio
                if (messages[messages.length - 1].SenderId != UserId) {
                    handlePlayButtonClick();
                }

            });
        } catch (err) {
            console.log("ERR FROM GET ALL MESSAGES");
            console.log(err);
            console.log("ERR FROM GET ALL MESSAGES");
        }
    };

    useEffect(() => {
        getAllMessages(roomId);
    }, [roomId]);

    useEffect(() => {
        getAllChatlist().then(res => {
            console.log("RES FROM GET ALL CHAT LIST");
            console.log(res);
            setChatUser(res.chatList);
            setChatDetail({
                image: res.chatList[0].UserData.ProfilePhoto,
                name: res.chatList[0].UserData.Name,
                role: res.chatList[0].UserData.Role,
            });
            setRoomId(res.chatList[0].roomId);
            console.log("RES FROM GET ALL CHAT LIST");
        }).catch(err => {
            console.log("ERR FROM GET ALL CHAT LIST");
            console.log(err);
            console.log("ERR FROM GET ALL CHAT LIST");
        });
    }, []);

    const [chatDetail, setChatDetail] = useState(chatUser[0] ? {
        image: chatUser[0].UserData.ProfilePhoto,
        name: chatUser[0].UserData.Name,
        role: chatUser[0].UserData.Role,
    } : {
        image: "",
        name: "",
        role: "",
    });

    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const [isNewMessage, setIsNewMessage] = useState(false); // New state for tracking new messages

    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };

    const handleEnterPress = (e) => {
        if (e.key === "Enter" && inputMessage.trim() !== "") {
            sendMessage({
                message: inputMessage,
                roomId: roomId
            }).then(res => {
                console.log("Response From Sending Message");
                console.log(res);
                console.log("Response From Sending Message");

                // Set the state to trigger the animation
                setIsNewMessage(true);

                setTimeout(() => {
                    // Reset the state after a delay to allow the animation
                    setIsNewMessage(false);
                }, 1000); // Adjust the delay based on your animation duration
            }).catch(err => {
                console.log("Error From Sending Message");
                console.log(err);
                console.log("Error From Sending Message");
            });
            setInputMessage("");
        }
    };

    const chatContainerRef = useRef(null);

    // Scroll to the bottom when messages change
    useEffect(() => {
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop =
                chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const audioPlayerRef = useRef(null);

    const handlePlayButtonClick = () => {
        // Call the playAudio function through the ref
        if (audioPlayerRef.current) {
            audioPlayerRef.current.playAudio();
        }
    };


    return (
        <div className="min-h-screen max-h-screen">
            <head>
                <AudioPlayer ref={audioPlayerRef} />

            </head>
            <div className="Home-Navbar-Container">
                <Navbar />
            </div>

            <div
                style={{
                    paddingTop: "75px"
                }}
                className="Home-Main-Container h-screen flex"
            >
                <div className="bg-[#101010] w-[30%] overflow-y-auto h-full flex flex-col">
                    <div className="flex justify-center">
                        <div className="w-auto">
                            <div className="font-semibold text-[24px] text-white pb-[4px] mt-4">
                                Chats
                            </div>
                            <div className="bg-[#AC6FFA] rounded-[25px] w-full h-[4px]"></div>
                        </div>
                    </div>
                    <div style={{
                        marginTop: '20px',
                        paddingLeft: '20px',
                        paddingRight: '20px'

                    }}>
                        <PrimaryInput
                            Placeholder={'Search By Owner Name / Phone Number'}
                            width={'100%'}

                        />
                    </div>
                    <div className="mt-4">
                        {chatUser &&
                            chatUser.map((ch, key) => (
                                <div key={key}>
                                    <ChatUserCard
                                        roomId={ch.roomId}
                                        UserId={UserId}
                                        onClick={() => {
                                            setChatDetail({
                                                image: ch.UserData.ProfilePhoto,
                                                name: ch.UserData.Name,
                                                role: ch.UserData.Role,
                                            });

                                            setRoomId(ch.roomId)
                                            getAllMessages(ch.roomId)
                                        }
                                        }

                                        ch={ch} />
                                </div>
                            ))}
                    </div>
                </div>
                <div className="flex-1 flex flex-col ">
                    <div className="bg-[#181818] w-full px-4 h-[75px] flex justify-between items-center">
                        <div className="flex space-x-4 items-start ">
                            <img
                                src={chatDetail.image}
                                alt="User"
                                className="w-14 h-14 rounded-full"
                            />
                            <div className="flex flex-col">
                                <div className="text-white">{chatDetail.name}</div>
                                <div className="text-[#9F9F9F]">{chatDetail.role}</div>
                            </div>
                        </div>
                        <div className="flex space-x-6">
                            <div>
                                <PhoneOutlined className="text-white rotate-90 text-[22px]" />
                            </div>
                            <div>
                                <MoreOutlined className="text-white text-[24px]" />
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex-1 overflow-y-auto px-4 py-2"
                        ref={chatContainerRef}
                    >
                        {messages.map((message, index) => (
                            <div
                                style={{
                                    display: "flex",

                                }}
                                key={index}
                                className={`flex ${UserId == message.SenderId ? "justify-end" : "justify-start"
                                    } mb-3 ${isNewMessage && index === messages.length - 1
                                        ? "animate__fadeInUp" // Apply the custom animation class
                                        : ""
                                    }`}
                            >
                                <div>

                                </div>
                                <div
                                    className={`ml-3 bg-[#AC6FFA] rounded-lg p-2 max-w-[60%]`}
                                >
                                    <div className="text-white">{message.message}</div>

                                </div>
                                <br />

                            </div>
                        ))}
                    </div>
                    <div className="bg-[#181818] flex space-x-4 items-center px-4 py-2">
                        <SmileOutlined className="text-[#9F9F9F] text-[24px]" />
                        <input
                            type="text"
                            className="flex-1 bg-[#1D1D1D] outline-none py-2 px-3 rounded-[5px] text-white"
                            placeholder="Type your message..."
                            value={inputMessage}
                            onChange={handleInputChange}
                            onKeyPress={handleEnterPress}
                        />
                        <PlusCircleOutlined className="text-[#9F9F9F] text-[24px]" />
                        <AudioOutlined className="text-[#9F9F9F] text-[24px]" />
                    </div>
                </div>
            </div>
        </div>
    );
}
