import React from 'react'
import { appImages } from '../../Utils/Constants'
import GradientButton from '../../Components/Buttons/GradientButton'

export default function NotFounds() {
    return (
        <div style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            backgroundColor: "black",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            fontFamily: "Montserrat",
            backgroundImage: `url(${appImages.bgFadeGrid})`,
            backgroundRepeat: "no-repeat",backgroundPosition:"center",backgroundSize:"cover"

        }}>

            <img src={appImages.LOGO} width={200} height={200} alt="AccomzyLogo" />
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",


            }}>
                <h1 style={{ color: "white", marginLeft: "40px", marginRight: "40px" }}>Oops! Looks like you've taken a wrong turn.</h1>
                <h3 style={{
                    color: "white", textAlign: 'center',
                    margin: "40px"
                }}>
                    It seems you've stumbled upon an uncharted territory, and we couldn't find the page you were looking for. Don't worry; even the best explorers occasionally take detours!
                </h3>
            </div>
            <div style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                cursor: "pointer",

            }}>
                <GradientButton
                    onClick={() => window.location.href = "/"}
                    ButtonText={"  Let's head back!"} />
            </div>
        </div>
    )
}
