
import './App.css';
import Login from './Routes/Auth/Login';
import WebFont from 'webfontloader';
import React, { useLayoutEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NotFounds from './Routes/404/NotFounds';
import Register from './Routes/Auth/Register';
import Home from './Routes/Site/Home';
import { RouteEndpoints } from './Utils/RouterConsts';
import EnlistProperty from './Routes/Site/Broker/EnlistProperty';
import { Analytics } from '@vercel/analytics/react';

import toast, { Toaster } from 'react-hot-toast';
import cookie from 'react-cookies';
import PrivateRoute from './Utils/privateRoute';
import SubscriptionModal from './Components/Modals/SubscriptionModal';
import Explore from './Routes/Site/Explore';
import PropertyDetails from './Routes/Site/PropertyDetails';
import UserProfile from './Routes/Site/User/UserProfile';
import BrokerProfile from './Routes/Site/Broker/BrokerProfile';
import ContactUs from './Routes/Site/ContactUs';
import MapWithASearchBox from './MapWithASearchBox';
import Chat from './Routes/Site/User/Chat';
import PrivacyPolicy from './Routes/Site/PrivacyPolicy';
import ScrollToTopButton from './Components/Buttons/ScrollTopButton';
import DealsandOffers from './Routes/Site/DealsandOffers';
import ExploreSearch from './Routes/Site/ExploreSearch';
import AudioPlayer from './Utils/AudioPlayer';
import ReportAnIssue from './Routes/Site/ReportAnIssue';

function App() {
  useLayoutEffect(() => {
    const cookieData = cookie.load('session')
    console.log(cookieData)
  }
    , [])







  WebFont.load({
    google: {
      families: ['Montserrat:700,400', 'sans-serif', 'Plus Jakarta Sans']
    }
  });


  return (
    // <MapWithASearchBox />
    <div>
     

      <Analytics />
      <Router>


        <Routes>
          <Route element={<Home />} path='/' />
          <Route element={<Home />} path='/Home' />
          <Route element={<ExploreSearch />} path={RouteEndpoints.ExploreSearch} />
          <Route element={<Login />} path={RouteEndpoints.Login} />
          <Route element={<Register />} path={RouteEndpoints.Register} />
          <Route element={<PropertyDetails />} path={RouteEndpoints.PropertyDetails} />
          <Route element={<ContactUs />} path={RouteEndpoints.ContactUs} />
          <Route element={<ReportAnIssue />} path={RouteEndpoints.ReportAnIssue} />
          <Route element={<PrivacyPolicy />} path={RouteEndpoints.PrivacyPolicy} />
          <Route element={<NotFounds />} path='*' />
          <Route element={<Explore />} path={RouteEndpoints.Explore} />
          <Route element={<DealsandOffers />} path={RouteEndpoints.DealsandOffers} />
          <Route element={
            <PrivateRoute children={<UserProfile />} >
            </PrivateRoute>
          } path={RouteEndpoints.UserProfile} />
          <Route element={<Chat />} path={RouteEndpoints.Chat} />
          <Route element={
            <PrivateRoute children={<BrokerProfile />} >
            </PrivateRoute>
          } path={RouteEndpoints.OwnerProfile} />
          <Route element={
            <PrivateRoute children={<EnlistProperty />}>
            </PrivateRoute>
          } path={RouteEndpoints.EnlistProperty} />
        </Routes>

        <Toaster />
        <SubscriptionModal />
      </Router >
    </div>

  );
}

export default App;
