export const RouteEndpoints = {
    Register: '/Auth/Register',
    Login: '/Auth/Login',
    EnlistProperty: "/Broker/EnlistProperty",
    Explore: "/Explore",
    PropertyDetails: "/PropertyDetails/:id",
    UserProfile: "/User/Profile",
    OwnerProfile: "/Owner/Profile",
    ContactUs: "/ContactUs",
    ReportAnIssue: "/ReportAnIssue",
    Chat: "/User/Chat",
    PrivacyPolicy: '/PrivacyPolicy',
    DealsandOffers: "/Deals&Offers",
    ExploreSearch: '/ExploreSearch/',

}