import apiInstance from ".."
import useAuthStatus from "../../Hooks/useAuthStatus"
import { apiEndpoints } from "../constants"
import cookie from 'react-cookies';
export const sendMessage = async ({ roomId, message }) => {


    return new Promise(async (resolve, reject) => {

        const UserId = cookie.load('UserId');
        const Token = cookie.load('Token');
        try {

            apiInstance.post(`${apiEndpoints.sendMessage}`, {
                roomId,
                message
            }, {
                headers: {
                    UserId,
                    Token
                }
            }).then(res => {
                resolve(res.data)
                return;
            }
            ).catch(err => {
                console.log(err)
                reject(err)
                return;
            }
            )
        } catch (error) {
            reject(error);

        }
    }
    )

}
