import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

const ImageUploader = ({ onImagesSelected }) => {
    const [images, setImages] = useState([]);

    const onDrop = (acceptedFiles) => {
        const maxSize = 4 * 1024 * 1024; // 4 MB limit

        const imageFiles = acceptedFiles.filter(file => {
            if (!file.type.startsWith('image/')) {
                alert('Please upload only image files.');
                return false;
            }

            if (file.size > maxSize) {
                alert('Max image size allowed is 4 MB.');
                return false;
            }

            return true;
        });

        const updatedImages = [...images, ...imageFiles];
        setImages(updatedImages);
        onImagesSelected(updatedImages);
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'image/*',
        multiple: true,
    });

    const dropzoneStyle = {
        border: '2px solid #cccccc',
        borderRadius: '8px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        marginTop: '20px',
    };

    return (
        <div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%',
            }}>
                <div {...getRootProps()} style={dropzoneStyle}>
                    <input {...getInputProps()} />
                    <div style={
                        {
                            marginTop: '30px',
                            marginBottom: '30px',
                        }
                    }>
                        <span style={{
                            color: '#cccccc',
                        }}>Drag 'n' drop some images here, or click to select files</span>
                    </div>
                </div>
            </div>
            <ImageDisplay images={images} />
        </div>
    );
};

const ImageDisplay = ({ images }) => (
    <div style={{
        display: "flex",
        flexWrap: 'wrap',
        margin: 'auto',
        justifyContent: 'center',
        marginBottom: '30px',
    }}>
        {images.map((image, index) => (
            <div key={index} style={{
                display: 'block',
                width: '300px',
                height: '300px',
                margin: '10px',
            }}>
                <img src={URL.createObjectURL(image)} alt={`img-${index}`} style={imageStyle} />
            </div>
        ))}
    </div>
);

const imageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    margin: '10px',
};

export default ImageUploader;
