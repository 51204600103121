// Import React and CSS file
import * as React from 'react';
import Modal from '@mui/material/Modal';

import '../../Styles/Components/ImageModal.css'
import { Carousel } from 'react-responsive-carousel';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

// Create a ref for the modal
export const imageModalRef = React.createRef();

// Expose a function to open the modal
export function openImageModal() {
    if (imageModalRef.current) {
        imageModalRef.current.handleOpen();
    }
}

export default function ImageModal({ ImgArr = [] }) {
    const [open, setOpen] = React.useState(false);

    // Expose the handleOpen function
    const handleOpen = () => setOpen(true);
    const handleClose = (event, reason) => {

        setOpen(false);
    }


    // Set the ref to the modal component
    React.useEffect(() => {
        imageModalRef.current = { handleOpen };
    }, []);
    const imgAd = 'https://bestmediainfo.com/uploads/2019/03/VH_Bags_Campaign_3.jpg'
    const imgad2 = "https://as1.ftcdn.net/v2/jpg/01/31/25/62/1000_F_131256257_D2CABmYuUTXkwZPtx4XBOdoLMOseoGVs.jpg"
    const imgAd3 = "https://cdn.create.vista.com/downloads/b293f250-9619-40e7-ad92-1dd5dbcf518c_450.jpeg"
    return (
        <Modal

            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"

            aria-describedby="modal-modal-description"
        >
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

            }}>
                <div style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',


                }}>
                    <div
                        onClick={
                            handleClose
                        }
                        style={{
                            margin: '30px',

                        }}
                        className="global-hover-effect" >
                        X
                    </div>
                </div>
                <Carousel

                    autoPlay={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    interval={3000}
                >
                    {
                        ImgArr.map((item, index) => {
                            return (
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',

                                    width: '100%',
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',

                                        width: '60%',
                                    }}>
                                        <img src={item} alt="Slide 1" />

                                    </div>
                                </div>
                            )
                        })
                    }


                </Carousel>

            </div>
        </Modal>
    );
}
