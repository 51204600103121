import React, { useEffect, useState } from 'react';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import '../../../Styles/Site/Exlpore/FilterCard.css'
import { getFilterOptions } from '../../../API/site/getFilterOptions';

export default function FilterCard({ onFacilityFilter = () => { }, onAmenityFilter = () => { }, onPriceSliderChange = () => { } }) {

  const [distance, setDistance] = useState({ min: 2, max: 20 });
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([])
  const [amenities, setAmenities] = useState([]);
  const [facilities, setFacilities] = useState([])
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0)
  const [range, setRange] = useState({ min: minPrice, max: maxPrice });
  useEffect(() => {
    getFilterOptions().then(res => {
      setAmenities(res.Amenities)
      setMinPrice(res.minPrice)
      setMaxPrice(res.maxPrice)
      setRange({ min: res.minPrice, max: res.maxPrice })
      setFacilities(res.Facilities)
    }
    )

  }, [])

  const handleRangeChange = (newRange) => {
    setRange(newRange);
    onPriceSliderChange(newRange)


  };



  const handleDistanceChange = (newDistance) => {
    setDistance(newDistance);
  }

  const toggleAmenity = async (amenity) => {

    if (selectedAmenities.find(obj => obj.name == amenity.name)) {
      setSelectedAmenities(selectedAmenities.filter(obj => obj.name != amenity.name))
    }
    else {
      setSelectedAmenities([...selectedAmenities, amenity]);
    }
    onAmenityFilter(amenity)



  };

  const toggleFacility = async (facility) => {

    if (selectedFacilities.find(obj => obj.name == facility.name)) {
      setSelectedFacilities(selectedFacilities.filter(obj => obj.name != facility.name))
    }
    else {
      setSelectedFacilities([...selectedFacilities, facility]);
    }
    onFacilityFilter(facility)
  }




  return (
    <div style={{ padding: '20px', backgroundColor: '#101010', paddingLeft: '50px', borderRadius: '40px' }}>
      <h1 style={{ color: 'white', fontFamily: 'Montserrat', fontSize: 30, fontWeight: 600 }}>Filters</h1>

      <div className="price-range-container">
        <h3 className='price-range-text'>Price Range</h3>

        <InputRange

          minValue={parseInt(minPrice)}
          maxValue={parseInt(maxPrice)}
          value={{
            min: parseInt(range.min),
            max: parseInt(range.max)
          }}

          onChange={handleRangeChange}
          step={1}

          formatLabel={value => `₹${value}/month`}
        />
      </div>

      <div style={{ marginBottom: '30px' }}>
        <h3 className='price-range-amenities'>Amenities</h3>
        <div>
          {
            amenities.map(amenity => {
              return (
                <button
                  className={`amenities-button ${selectedAmenities.find(obj => obj.name == amenity.name) ? 'selected' : ''}`}
                  onClick={() => toggleAmenity(amenity)}
                >
                  <h3 className='amenities-text'>{amenity.name}</h3>
                </button>
              )
            })
          }
        </div>
      </div>

      <div style={{ marginBottom: '30px' }}>
        <h3 className='price-range-amenities'>Facilities</h3>
        <div>
          {
            facilities.map(facility => {
              return (
                <button
                  className={`amenities-button ${selectedFacilities.find(obj => obj.name == facility.name) ? 'selected' : ''}`}
                  onClick={() => toggleFacility(facility)}
                >
                  <h3 className='amenities-text'>{facility.name}</h3>
                </button>
              )
            })
          }
        </div>
      </div>

      <div className="price-range-container">
        <h3 className='price-range-text'>Distance from College or University</h3>

        <InputRange
          minValue={2}
          maxValue={20}
          value={distance}
          onChange={handleDistanceChange}
          step={1}
          formatLabel={value => `${value}kms`}
        />
      </div>

    </div>
  );
}
