import React, { useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import { Helmet } from 'react-helmet'
import { appImages } from '../../Utils/Constants'
import { COLORS } from '../../Utils/Colors'
import PrimaryInput from '../../Components/Input/PrimaryInput'
import PrimaryButton from '../../Components/Buttons/PrimaryButton'
import GradientButton from '../../Components/Buttons/GradientButton'

export default function ReportAnIssue() {
    const [email, setEmail] = useState(null)
    const [queryText, setQueryText] = useState("")
    return (
        <>
            <Helmet>

                <title> Contact Us | Accomzy
                </title>
                <meta name="description" content="Connect with Accomzy – Your Ultimate Destination for Exceptional Accommodations. Reach out to our dedicated team for inquiries, support, or partnership opportunities. Your comfort is our priority, and we're here to make your stay extraordinary. Contact us today for personalized assistance and seamless travel experiences.">
                </meta>
                <meta name='og:title' content='Contact Us | Accomzy' />
                <meta name="og:description" content="Connect with Accomzy – Your Ultimate Destination for Exceptional Accommodations. Reach out to our dedicated team for inquiries, support, or partnership opportunities. Your comfort is our priority, and we're here to make your stay extraordinary. Contact us today for personalized assistance and seamless travel experiences.">
                </meta> <meta name='og:url' content='https://accomzy.in/ReportAnIssue' />
                <meta name='og:type' content='website' />
                <meta name='og:site_name' content='Accomzy' />
                <meta name='og:locale' content='en_US' />

                <meta name='twitter:card' content='summary' />
                <meta name='twitter:site' content='@accomzy' />
                <meta name='twitter:creator' content='@accomzy' />
                <meta name='twitter:url' content='https://accomzy.in/' />


            </Helmet>
            <div className='Home-Navbar-Container'>
                <Navbar />
            </div>
            <div className='Home-Main-Container pb-40'>
                <div style={{
                    display: 'flex',
                    flexDirection: "column",
                    height: "60vh",
                    width: "100vw",

                    paddingLeft: "20px",
                    backgroundImage: "url('https://i.imgur.com/oSc9cnc.png')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}>
                    <div style={{
                        marginTop: "80px",

                        display: "block",
                        width: "100px",
                        height: "100px"
                    }}>
                        <img
                            alt='Accomzy-Logo'
                            src={appImages.LOGO}
                            style={{
                                maxWidth: "100%",
                                maxHeight: "100%"

                            }}
                        />
                    </div>
                    <div style={{
                        marginTop: "50px",

                    }}>
                        <span style={{
                            color: "white",
                            fontWeight: "bold",
                            fontSize: "40px",
                            paddingBottom: "32px",
                            borderBottom: "1px solid ",
                            borderBottomColor: COLORS.Primary
                        }}>
                            Facing any issues?
                        </span>
                    </div>
                    <div style={{
                        marginTop: "50px",

                    }}>
                        <span style={{
                            color: "white",
                            fontWeight: "bold",




                        }}>
                            Don’t worry Contact us by filling the form below
                        </span>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    height: "58vh",

                    backgroundImage: "url('https://i.ibb.co/ygcc3XX/Group-436.png')",
                    backgroundRepeat: "no-repeat",

                    backgroundSize: "fit",
                    flexDirection: "column"
                }}>
                    <div style={{
                        display: "flex",
                        width: "100vw",
                        justifyContent: 'center',
                        marginTop: "50px",
                        textAlign: 'center'
                    }}>
                        <span style={{
                            color: 'white',
                            fontSize: "38px",
                            fontWeight: "bold"
                        }}>
                            Report an <span className='text-purple-600'>Issue</span>
                        </span>


                    </div>
                    <div style={{
                        display: "flex",
                        justifyContent: 'center',

                    }}>
                        <form action="">
                            <div style={{
                                width: "80vw",
                                marginTop: "40px",
                                marginLeft: "20px"
                            }}>
                                <PrimaryInput
                                    id={"email"}
                                    Placeholder={"Enter  Your Email Address"}
                                    width={"100%"}
                                    Label={"Email"} />
                            </div>
                            <div style={{
                                width: "80vw",
                                marginTop: "40px",
                                marginLeft: "20px"
                            }}>

                                <PrimaryInput
                                    id={"Message"}


                                    onChange={(e) => {
                                        setQueryText(e.target.value)
                                    }}
                                    height={`${queryText.split('\n').length * 50}px`}
                                    value={queryText}
                                    type={"textarea"}
                                    Placeholder={"Enter  Your Query"}
                                    width={"100%"}
                                    Label={"Enter Text.."} />
                            </div>
                        </form>


                    </div>
                    <div style={{
                        display: "flex",
                        width: "100vw",
                        justifyContent: 'center',
                        marginTop: "20px",
                        


                    }}>
                        <div style={{
                            width: "150px",
                            height: "10px",
                            
                        }}>
                            <GradientButton

                                ButtonText={"Submit"}
                            />
                        </div>

                    </div>
                </div>

            </div >
        </>
    )
}
