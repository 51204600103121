// login.jsx

import React, { useState } from 'react';
import PrimaryInput from '../../Components/Input/PrimaryInput';

import GradientButton from '../../Components/Buttons/GradientButton';
import '../../Styles/Auth/Register.css'

import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { appImages } from '../../Utils/Constants';
import { RouteEndpoints } from '../../Utils/RouterConsts';
import { COLORS } from '../../Utils/Colors';

import toast from 'react-hot-toast';

import { register } from '../../API/auth/register';
import cookie from "react-cookies"
import sendOTP from '../../API/auth/sendOTP';
import verifyOTP from '../../API/auth/verifyOTP';
export default function Register() {
    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [loading, setLoading] = useState(false)
    const [showOTP, setShowOTP] = useState(false)
    const [verificationCode, setVerificationCode] = useState("")
    const [OTP, setOTP] = useState("")
    const [mode, setMode] = useState("user")
    const navigate = useNavigate();

    const onChangePhoneNumber = (e) => {
        //check regex 
        const phoneRegex = /^[0-9\b]+$/;
        if (phoneRegex.test(e.target.value) || e.target.value === '') {
            setPhoneNumber(e.target.value)
        }

    }

    const onOTPChange = (e) => {
        const otpRegex = /^[0-9\b]+$/;
        if (otpRegex.test(e.target.value) || e.target.value === '') {
            setOTP(e.target.value)
        }
    }

    const handleSendOTP = async () => {

        setLoading(true)
        await sendOTP({
            phoneNumber
        }).then((response) => {
            console.log(response)
            toast.success(response.message)
            setShowOTP(true)

            setVerificationCode(response.verificationCode)
        }
        ).catch((error) => {
            console.log(error)
            toast.error(error)
        }).finally(() => {
            setLoading(false)
        }
        )

    }

    const handleVerifyOTP = async () => {

        setLoading(true)
        await verifyOTP({
            VerificationCode: verificationCode,
            OTP,
            PhoneNumber: phoneNumber
        }).then(async (response) => {
            console.log(response)
            toast.success(response.message)
            await register({
                Explorer: mode == "user" ? true : false,
                Owner: mode == "owner" ? true : false,
                PhoneNumber: phoneNumber,
                Name: name
            }).then(async (data) => {
                console.log(data)
                await cookie.save('UserId', data.UserId, { path: '/' })
                await cookie.save('Token', data.Token, { path: '/' })
                navigate('/')

            }
            ).catch((error) => {
                console.log(error)
                toast.error(error.response.data.message)
                if (error.response.status == 409) {
                    navigate(RouteEndpoints.Login)
                }

            }).finally(() => {
                setLoading(false)
            }
            )
        }
        ).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message)
        }).finally(() => {
            setLoading(false)
        }
        )

    }

    return (
        <div className="login-container">
            <Helmet>
                <title>Register | Accomzy
                </title>
                <meta name="description" content="Register with Accomzy and get the best tenants. We provide you with the best services and ensure that you get the best tenants for your property.">
                </meta>
                <meta name='og:title' content='Register | Accomzy' />
                <meta name='og:description' content="Register with Accomzy and get the best tenants. We provide you with the best services and ensure that you get the best tenants for your property." />
                <meta name='og:url' content='https://accomzy.in/' />
                <meta name='og:image' content='https://i.ibb.co/wRs2VQX/file-cover-1.png' />
            </Helmet>

            <div className="left-section">
                <div className="background-dots">
                    <img src="https://i.ibb.co/Hpxx57d/bgDots.png" alt="Description" />
                </div>
                <div className="title fade-in">
                    <span>
                        Exploring Accommodation <br /> Made Easy
                    </span>
                </div>
            </div>
            <div className="right-section ">

                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: '16px',
                    marginRight: '32px'
                }}>
                    <img src={appImages.LOGO} width={82} alt="AccomzyLogo" />
                </div>
                <div className="login-form fade-in">
                    <div>
                        <span style={{
                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '38px',
                            fontFamily: 'Montserrat'
                        }}>
                            Signup
                        </span>
                        <br />
                        <span style={{
                            color: 'white',
                            fontSize: '16px',
                            color:'#9F9F9F',
                        }}>
                            Start right where you left off...
                        </span>
                    </div>
                    <div className="input-fields">
                        <div>
                            <PrimaryInput
                                onChange={
                                    (e) => {
                                        setName(e.target.value)
                                    }
                                }
                                maxLength={20}
                                Label={'Name'}
                                Placeholder={'Enter your Name'}
                            />
                        </div>
                        <div>
                            <PrimaryInput
                                id={"PhoneNumber"}
                                maxLength={10}
                                onChange={
                                    onChangePhoneNumber
                                }
                                Label={'Phone Number'}
                                handleKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        showOTP ? handleVerifyOTP() : handleSendOTP()
                                    }
                                }
                                }
                                Placeholder={'Enter your 10 digit Mobile Number'}
                            />
                        </div>
                        {
                            showOTP && <div>
                                <PrimaryInput
                                    onChange={
                                        onOTPChange
                                    }
                                    Label={'OTP'}
                                    handleKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleVerifyOTP()
                                        }
                                    }
                                    }
                                    Placeholder={'Enter 6 digit sent on your Mobile Number'}
                                />
                            </div>
                        }

                        {!showOTP &&
                            <div style={{
                                display: "flex",
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                <div
                                    onClick={() => {
                                        setMode("user")
                                    }}
                                    style={{
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "4px",
                                        paddingBottom: "4px",
                                        border: "1px solid",
                                        borderColor: COLORS.Primary,
                                        borderRadius: "25px",
                                        color: mode == "user" ? "black" : "white",
                                        backgroundColor: mode == "user" ? COLORS.Primary : "black",
                                        cursor: "pointer"
                                    }}>
                                    <span>
                                        Explorer
                                    </span>
                                </div>
                                <div
                                    onClick={() => {
                                        setMode("owner")
                                    }}
                                    style={{
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        paddingTop: "2px",
                                        paddingBottom: "4px",
                                        border: "1px solid",
                                        borderColor: COLORS.Primary,
                                        borderRadius: "25px",
                                        color: mode == "owner" ? "black" : "white",
                                        backgroundColor: mode == "owner" ? COLORS.Primary : "black",
                                        cursor: "pointer"
                                    }}
                                >
                                    <span>
                                        Owner
                                    </span>
                                </div>
                            </div>

                        }
                        <div className="actionButton" style={{
                            marginTop: '48px'
                        }}>
                            <GradientButton
                                onClick={loading ? null : showOTP ? handleVerifyOTP : handleSendOTP}
                                Loading={loading}

                                ButtonText={
                                    showOTP ? "Verify OTP" :
                                        'Send OTP'}
                            />



                        </div>
                        <div style={{

                            display: 'flex',
                            justifyContent: 'center',



                        }}>
                            <div
                                onClick={() => {
                                    navigate(RouteEndpoints.Login)
                                }}
                                className='SignUpLinkButton   rounded-xl'
                                style={{
                                    cursor: 'pointer',
                                    backgroundColor: '#131313',
                                    color: 'white',
                                    fontFamily: 'Plus Jakarta Sans',
                                    paddingTop: "27px",
                                    paddingBottom: "27px",
                                    paddingLeft: "48px",
                                    paddingRight: "48px",

                                }}>
                                Login Here
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}
