import React, { useEffect } from 'react'
import Navbar from '../../Components/Navbar/Navbar'
import { Helmet } from 'react-helmet'
import { getAllRooms } from '../../API/site/getAllRooms'
import Search from '../../Components/Site/Explore/Search'
import FilterCard from '../../Components/Site/Explore/FilterCard'
import '../../Styles/Site/Exlpore/Explore.css'
import RoomCardNew from '../../Components/Site/Explore/RoomCardNew'
import SkeletonLoader from '../../Components/Loader/Skeleton'
import ScrollToTopButton from '../../Components/Buttons/ScrollTopButton'
import { useLocation } from 'react-router-dom'
export default function ExploreSearch() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const { state } = useLocation()



    const [loading, setLoading] = React.useState(true)
    const [rooms, setRooms] = React.useState([])
    const [City, setCity] = React.useState(undefined)

    const [Institute, setInstitute] = React.useState(undefined)
    const [selectedFacilities, setSelectedFacilities] = React.useState([])
    const [selectedAmenities, setSelectedAmenities] = React.useState([])
    const [minPrice, setMinPrice] = React.useState(undefined)
    const [page, setPage] = React.useState(1)
    const [maxPrice, setMaxPrice] = React.useState(undefined)
    const [latitude, setLatitude] = React.useState(undefined)
    const [longitude, setLongitude] = React.useState(undefined)
    const [amenitiesAvailable, setAmenitiesAvailable] = React.useState([])
    const [searchQuery, setSearchQuery] = React.useState(undefined)
    const [propertyType, setPropertyType] = React.useState(state !== null && state.propertyType ? state.propertyType : undefined)


    useEffect(() => {

        getAllRooms({
            propertyType: propertyType,
            searchQuery,
            page: page,
            facilities: selectedFacilities,
            amenities: selectedAmenities,
            maxPrice: maxPrice,
            minPrice: minPrice,
            latitude: JSON.stringify(latitude),
            longitude: JSON.stringify(longitude),
            city: City,
            institute: state !== null && state.institute ? state.institute : undefined
        })
            .then((res) => {
                setLoading(true);
                console.log(res);
                const fetchedRooms = res;
                //if there is change in facilities or amenities then set new rooms to fetched rooms
                if (selectedFacilities.length > 0 || selectedAmenities.length > 0) {
                    setRooms(fetchedRooms);
                }
                else {

                    setRooms(fetchedRooms);
                }
                setTimeout(() => {
                    setLoading(false);
                }, 300);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }, [selectedFacilities, selectedAmenities, latitude, longitude, maxPrice, minPrice, City, searchQuery, propertyType]);

    useEffect(() => {
        state !== null && state.city && setCity(state.city)
        state !== null && state.institute && setInstitute(state.institute)
        state !== null && state.searchQuery && setSearchQuery(state.searchQuery)
        state !== null && state.latitude && setLatitude(state.latitude)
        state !== null && state.longitude && setLongitude(state.longitude)

        state !== null && state.propertyType && setPropertyType(state.propertyType)
    }, [])
    return (
        <div className='bg-[#090909] h-screen'>
            <ScrollToTopButton />
            <Helmet>
                <title>Explore Accommodations Near You | Accomzy
                </title>
                <meta name='og:description' content="Discover convenient and comfortable living with Accomzy! Find the perfect PGs, hostels, and apartments for students. Simplify your accommodation search with our user-friendly platform. Your ideal living space is just a click away. Start your housing journey with Accomzy today!" />
                <meta name='og:url' content='https://accomzy.in/' />
                <meta name='og:image' content='https://i.ibb.co/wRs2VQX/file-cover-1.png' />
                <meta name="description" content="Discover convenient and comfortable living with Accomzy! Find the perfect PGs, hostels, and apartments for students. Simplify your accommodation search with our user-friendly platform. Your ideal living space is just a click away. Start your housing journey with Accomzy today!">
                </meta>
                <meta name='og:title' content='Explore Accommodations Near You | Accomzy' />
            </Helmet>
            <div style={{
                position: 'fixed',
                width: '100%',
                zIndex: '100',

            }}>
                <Navbar />
            </div>
            <div style={{ backgroundColor: '#101010', borderBottomLeftRadius: '40px', borderBottomRightRadius: '40px' }}>
                <Search
                    secondValue={propertyType}
                    onfirstOption={(option) => { }}
                    onsecondOption={(option) => {
                        setPropertyType(option)
                    }}
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    LocationDefined={
                        City ? City : Institute ? Institute : false
                    }
                    showText={true}
                    CoordinateChange={(Coordinates) => {
                        const { latitude, longitude } = Coordinates


                        setLatitude(latitude)
                        setLongitude(longitude)

                    }}


                />
            </div>

            <div className='below-search'>
                <>

                    <div className='filter'>
                        <FilterCard
                            onPriceSliderChange={async (newRange) => {

                                const maxPrice = newRange.max
                                const minPrice = newRange.min
                                setMaxPrice(parseFloat(maxPrice).toFixed(2))
                                setMinPrice(parseFloat(minPrice).toFixed(2))
                            }
                            }
                            onFacilityFilter={(facility) => {
                                if (selectedFacilities.find(obj => obj.name == facility.name)) {
                                    setSelectedFacilities(selectedFacilities.filter(obj => obj.name != facility.name))
                                }
                                else {
                                    setSelectedFacilities([...selectedFacilities, facility]);
                                }

                            }
                            }
                            onAmenityFilter={(amenity) => {
                                if (selectedAmenities.find(obj => obj.name == amenity.name)) {
                                    setSelectedAmenities(selectedAmenities.filter(obj => obj.name != amenity.name))
                                }
                                else {
                                    setSelectedAmenities([...selectedAmenities, amenity]);
                                }
                            }}


                            amenities={amenitiesAvailable}
                            maxPrice={maxPrice}
                            minPrice={minPrice}
                        />
                    </div>

                    <div className='rooms'>
                        {
                            loading ? <>  <SkeletonLoader />  </> : rooms.map((roomCard) => (
                                <div style={{ paddingBottom: '20px' }}>
                                    <RoomCardNew key={roomCard.PropertyName} roomCard={roomCard} />
                                </div>
                            ))
                        }
                        {
                            loading ?
                                <div>
                                    <SkeletonLoader />
                                </div> : null
                        }


                    </div>
                </>



            </div>


        </div >
    )
}
