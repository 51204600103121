import React from "react";

export const TripalBed = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="63"
            height="14"
            fill="none"
            viewBox="0 0 63 14"
        >
            <path
                fill="#fff"
                d="M3 14H2l-.65-2H0V6.975c0-.55.196-1.017.588-1.4A1.948 1.948 0 012 5V2c0-.55.196-1.021.588-1.413A1.922 1.922 0 014 0h12c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v3c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v5h-1.35L18 14h-1l-.65-2H3.65L3 14zm7.296-9h6.732V2.792a2 2 0 00-2-2h-4.732V5zM3.168 5h6.336V.792H5.168a2 2 0 00-2 2V5zm-2.58 6.484h18.825a5.544 5.544 0 00-5.544-5.544H6.132a5.544 5.544 0 00-5.544 5.544zM24.143 14h-1l-.65-2h-1.35V6.975c0-.55.196-1.017.588-1.4A1.947 1.947 0 0123.143 5V2c0-.55.196-1.021.588-1.413A1.922 1.922 0 0125.143 0h12c.55 0 1.02.196 1.413.588.392.392.587.863.587 1.412v3c.55 0 1.02.196 1.413.588.392.392.587.863.587 1.412v5h-1.35l-.65 2h-1l-.65-2h-12.7l-.65 2zm7.295-9h6.732V2.792a2 2 0 00-2-2h-4.732V5zm-7.127 0h6.335V.792h-4.335a2 2 0 00-2 2V5zm-2.58 6.484h18.825a5.544 5.544 0 00-5.544-5.544h-7.738a5.544 5.544 0 00-5.543 5.544zM45.285 14h-1l-.65-2h-1.35V6.975c0-.55.196-1.017.588-1.4A1.947 1.947 0 0144.285 5V2c0-.55.196-1.021.588-1.413A1.922 1.922 0 0146.285 0h12c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v3c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v5h-1.35l-.65 2h-1l-.65-2h-12.7l-.65 2zm7.296-9h6.732V2.792a2 2 0 00-2-2H52.58V5zm-7.128 0h6.336V.792h-4.336a2 2 0 00-2 2V5zm-2.58 6.484h18.825a5.544 5.544 0 00-5.544-5.544h-7.737a5.544 5.544 0 00-5.544 5.544z"
            ></path>
        </svg>
    );
}

export const DoubleBed = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="42"
            height="14"
            fill="none"
            viewBox="0 0 42 14"
        >
            <path
                fill="#fff"
                d="M3 14H2l-.65-2H0V6.975c0-.55.196-1.017.588-1.4A1.948 1.948 0 012 5V2c0-.55.196-1.021.588-1.413A1.922 1.922 0 014 0h12c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v3c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v5h-1.35L18 14h-1l-.65-2H3.65L3 14zm7.296-9h6.732V2.792a2 2 0 00-2-2h-4.732V5zM3.168 5h6.336V.792H5.168a2 2 0 00-2 2V5zm-2.58 6.484h18.825a5.544 5.544 0 00-5.544-5.544H6.132a5.544 5.544 0 00-5.544 5.544zM24.143 14h-1l-.65-2h-1.35V6.975c0-.55.196-1.017.588-1.4A1.947 1.947 0 0123.143 5V2c0-.55.196-1.021.588-1.413A1.922 1.922 0 0125.143 0h12c.55 0 1.02.196 1.413.588.392.392.587.863.587 1.412v3c.55 0 1.02.196 1.413.588.392.392.587.863.587 1.412v5h-1.35l-.65 2h-1l-.65-2h-12.7l-.65 2zm7.295-9h6.732V2.792a2 2 0 00-2-2h-4.732V5zm-7.127 0h6.335V.792h-4.335a2 2 0 00-2 2V5zm-2.58 6.484h18.825a5.544 5.544 0 00-5.544-5.544h-7.738a5.544 5.544 0 00-5.543 5.544z"
            ></path>
        </svg>
    )
}

export const SingleBed = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="14"
            fill="none"
            viewBox="0 0 20 14"
        >
            <path
                fill="#fff"
                d="M3 14H2l-.65-2H0V6.975c0-.55.196-1.017.588-1.4A1.948 1.948 0 012 5V2c0-.55.196-1.021.588-1.413A1.922 1.922 0 014 0h12c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v3c.55 0 1.021.196 1.413.588.392.392.588.863.587 1.412v5h-1.35L18 14h-1l-.65-2H3.65L3 14zm7.296-9h6.732V2.792a2 2 0 00-2-2h-4.732V5zM3.168 5h6.336V.792H5.168a2 2 0 00-2 2V5zm-2.58 6.484h18.825a5.544 5.544 0 00-5.544-5.544H6.132a5.544 5.544 0 00-5.544 5.544z"
            ></path>
        </svg>

    )
}

export const Breakfast = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M17.79 10.47v7.32A4.21 4.21 0 0113.58 22H6.21C3.89 22 2 20.11 2 17.79v-7.32a4.21 4.21 0 014.21-4.21h7.37c2.32 0 4.21 1.89 4.21 4.21zM5.5 4V2.25M9.5 4V2.25M13.5 4V2.25M22 13.16c0 2.32-1.89 4.21-4.21 4.21V8.95A4.21 4.21 0 0122 13.16zM2 12h15.51"
            ></path>
        </svg>

    )
}

export const Lunch = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#clip0_1328_1169)">
                <path
                    fill="#fff"
                    d="M23.934 11.906a11.935 11.935 0 01-7.715 10.472V24H7.78v-1.622A11.935 11.935 0 01.065 11.906l16.041-.01m-.769-4.452c0 .731.3 1.125.535 1.434.065.094.13.178.187.263a.415.415 0 00.356.196.426.426 0 00.366-.647c-.075-.121-.16-.224-.234-.328-.207-.28-.366-.478-.366-.928 0-.45.15-.647.366-.928.234-.31.534-.703.534-1.434s-.3-1.125-.534-1.435l-.047-.056a.42.42 0 00-.59-.084.42.42 0 00-.085.59l.047.057c.206.281.365.478.365.928 0 .45-.15.647-.365.928-.244.319-.535.712-.535 1.444zm-4.49-.788c0 1.05.412 1.585.778 2.063.112.14.206.272.3.422a.415.415 0 00.356.196.425.425 0 00.366-.647c-.113-.178-.235-.337-.347-.487-.319-.422-.6-.787-.6-1.547 0-.76.281-1.125.6-1.547.365-.478.778-1.012.778-2.062 0-1.04-.413-1.585-.778-2.063L12.234.9a.42.42 0 00-.59-.084.42.42 0 00-.085.59l.066.094c.319.422.6.787.6 1.547 0 .76-.281 1.125-.6 1.547-.366.478-.778 1.022-.778 2.062zm-4.013.788c0 .731.3 1.125.535 1.434.065.094.13.178.187.263a.415.415 0 00.356.196.425.425 0 00.366-.647c-.075-.121-.16-.224-.234-.328-.207-.28-.366-.478-.366-.928 0-.45.15-.647.366-.928.234-.31.534-.703.534-1.434s-.3-1.125-.534-1.435l-.047-.056a.42.42 0 00-.591-.084.42.42 0 00-.084.59l.047.057c.206.281.365.478.365.928 0 .45-.15.647-.365.928-.235.319-.535.712-.535 1.444z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_1328_1169">
                    <path fill="#fff" d="M0 0H24V24H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export const Snacks = () => {
    return (

        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <g clipPath="url(#clip0_1328_1173)">
                <g fill="#fff">
                    <path d="M19.823 21.268c1.4-3.776 1.4-14.76 0-18.536l.41-2.176a.469.469 0 00-.46-.556H4.227a.469.469 0 00-.46.556l.41 2.176c-1.4 3.775-1.4 14.76 0 18.536l-.411 2.176a.469.469 0 00.46.556h15.548a.469.469 0 00.452-.594l-.403-2.138zM19.207.938l-.258 1.368H5.05L4.793.938h14.414zM4.99 3.244h14.02c1.235 3.662 1.235 13.85 0 17.512H4.99c-1.235-3.662-1.235-13.85 0-17.512zm-.198 19.818l.259-1.368h13.898l.258 1.368H4.792z"></path>
                    <path d="M7.097 15.759H7.1l.027.005a.926.926 0 00.114.007c.313 0 .781-.14 1.52-.37.712-.22 1.521-.471 2.05-.5a2.73 2.73 0 01.154-.005c.429 0 .858.1 1.313.205.545.127 1.163.27 1.896.27.598 0 2.095-.11 3.071-1.127.578-.602.851-1.394.812-2.354-.108-2.629-2.96-3.765-4.39-3.765-2.174 0-3.385 1.79-3.816 2.958-2.927.427-3.914 2.797-3.914 3.424 0 .674.443 1.15 1.159 1.252zm6.57-6.697c.982 0 3.37.825 3.454 2.866.028.695-.157 1.256-.551 1.666-.748.779-2.022.84-2.395.84-.626 0-1.164-.126-1.684-.246-.486-.113-.989-.23-1.526-.23-.069 0-.138.002-.206.006-.392.022-.867.13-1.35.265.756-1.072 1.171-2.346 1.243-2.577l.002-.007.002-.005c.03-.105.761-2.578 3.012-2.578zm-4.184 3.052c-.447 1.071-1.284 2.564-2.296 2.71-.31-.055-.311-.211-.311-.314.013-.247.616-1.878 2.607-2.396z"></path>
                </g>
            </g>
            <defs>
                <clipPath id="clip0_1328_1173">
                    <path fill="#fff" d="M0 0H24V24H0z"></path>
                </clipPath>
            </defs>
        </svg>
    )
}

export const Dinner = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="19"
            fill="none"
            viewBox="0 0 23 19"
        >
            <path
                fill="#fff"
                d="M19.988 0l-.002 18.017c.323.53.657.734.936.733.278-.001.608-.208.925-.723l-.864-9.202-.01-.112.069-.09c.992-1.273 1.34-3.473 1.056-5.334-.141-.93-.438-1.773-.853-2.368-.348-.5-.763-.818-1.257-.921zM2.533 0l-.001 3.147-.589-.001V0h-.67v3.146h-.59V0H0v4.84c0 .534.292.831.752 1.015l.19.076-.004.204-.28 11.893c.321.525.65.723.925.722.274-.001.603-.205.92-.723l-.326-11.89-.005-.206.19-.075c.484-.193.8-.52.8-1.017V0h-.63zM11.1 1.828a7.547 7.547 0 100 15.094 7.547 7.547 0 000-15.094z"
            ></path>
        </svg>
    )
}

export const Alcohal = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="24"
            fill="none"
            viewBox="0 0 17 24"
        >
            <path
                fill="#fff"
                fillRule="evenodd"
                d="M5.7.6a.6.6 0 00-.6-.6H3.6a.6.6 0 00-.6.6v7.31a.429.429 0 01-.142.318A8.634 8.634 0 000 14.645v8.03C0 23.407.593 24 1.325 24h5.66A1.413 1.413 0 008.4 22.586v-8.148c0-2.33-.925-4.563-2.572-6.21a.436.436 0 01-.128-.309V.6zM1.2 15h6v4.8h-6V15zm14.183-3h-4.367l.384-3.6H15l.383 3.6zM10.32 7.2h5.76l.608 5.722A3.508 3.508 0 0113.8 16.75v6.05h1.8a.6.6 0 010 1.2h-4.8a.6.6 0 010-1.2h1.8v-6.051a3.51 3.51 0 01-2.888-3.827L10.32 7.2z"
                clipRule="evenodd"
            ></path>
        </svg>

    )
}

export const Smoking = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="16"
            fill="none"
            viewBox="0 0 19 16"
        >
            <path
                fill="#fff"
                d="M0 13.177h14.118V16H0v-2.823zm17.412 0h1.412V16h-1.412v-2.823zm-2.353 0h1.412V16h-1.412v-2.823zm.8-7.784a3.128 3.128 0 00.941-2.24A3.153 3.153 0 0013.647 0v1.412c.941 0 1.741.78 1.741 1.74s-.8 1.742-1.74 1.742v1.412c2.107 0 3.764 1.722 3.764 3.83v2.1h1.412v-2.109a5.227 5.227 0 00-2.965-4.734zm-2.654 2.325h-1.44c-.942 0-1.742-.923-1.742-1.883s.8-1.647 1.742-1.647V2.776a3.153 3.153 0 100 6.306h1.44c.988 0 1.854.697 1.854 1.93v1.223h1.412v-1.543c0-1.704-1.506-2.974-3.266-2.974z"
            ></path>
        </svg>

    )
}


export const OnlyVeg = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                fill="#D9D9D9"
                fillRule="evenodd"
                d="M4 2h16a2 2 0 012 2v16a2 2 0 01-2 2H4a2 2 0 01-2-2V4a2 2 0 012-2zM0 4a4 4 0 014-4h16a4 4 0 014 4v16a4 4 0 01-4 4H4a4 4 0 01-4-4V4zm12 14a6 6 0 100-12 6 6 0 000 12z"
                clipRule="evenodd"
            ></path>
        </svg>
    )
}


export const More = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
        >
            <g>
                <g
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                >
                    <path d="M5 10h10"></path>
                    <path d="M10 15V5"></path>
                </g>
            </g>
        </svg>
    )
}


export const Star = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="#fff"
                d="M9.627 3.025l.44 1.333a1.5 1.5 0 001.42 1.04h1.386a1.493 1.493 0 01.88 2.707l-1.14.826a1.5 1.5 0 00-.546 1.674l.44 1.333a1.493 1.493 0 01-2.3 1.693l-1.12-.833a1.5 1.5 0 00-1.76 0l-1.12.833a1.494 1.494 0 01-2.3-1.673l.44-1.334A1.5 1.5 0 003.8 8.951l-1.167-.84a1.493 1.493 0 01.907-2.713h1.387a1.5 1.5 0 001.42-1.027l.44-1.333a1.493 1.493 0 012.84-.013z"
            ></path>
        </svg>
    )
}

export const Location = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="10"
            fill="none"
            viewBox="0 0 9 10"
        >
            <path
                fill="#A5A5A5"
                d="M4.03 6.007c-.916 0-1.663-.805-1.663-1.8 0-.994.747-1.794 1.663-1.794.915 0 1.663.804 1.663 1.8 0 .994-.748 1.794-1.663 1.794zm0-2.897c-.559 0-1.019.493-1.019 1.102 0 .61.456 1.102 1.019 1.102s1.018-.493 1.018-1.102c0-.609-.46-1.102-1.018-1.102z"
            ></path>
            <path
                fill="#A5A5A5"
                d="M4.03 10c-.637 0-1.277-.26-1.775-.776C.987 7.904-.414 5.797.115 3.29.592 1.018 2.427 0 4.029 0h.005c1.603 0 3.438 1.018 3.915 3.296.524 2.506-.877 4.607-2.145 5.928A2.466 2.466 0 014.03 10zm0-9.303c-1.251 0-2.858.721-3.284 2.748-.464 2.19.808 4.077 1.96 5.272.743.776 1.908.776 2.651 0 1.148-1.195 2.42-3.082 1.964-5.272C6.891 1.418 5.28.697 4.03.697z"
            ></path>
        </svg>
    )
}