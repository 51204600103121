import apiInstance from ".."
import useAuthStatus from "../../Hooks/useAuthStatus"
import { apiEndpoints } from "../constants"
import cookie from 'react-cookies';
export const getFilterOptions = async () => {


    return new Promise(async (resolve, reject) => {


        try {

            apiInstance.post(`${apiEndpoints.getFilterOptions}`, {}, {}).then(res => {

                resolve(res.data)
                return;
            }
            ).catch(err => {
                console.log(err)
                reject(err)
                return;
            }
            )
        } catch (error) {
            reject(error);

        }
    }
    )

}
