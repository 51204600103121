import React, { useState } from 'react'
import TypingEffect from './TypingEffect'
import '../../Styles/Home/HomeBackground.css'
import { COLORS } from '../../Utils/Colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
export default function HomeBackground() {
    const firstDropDownOptions = [
        {
            id: 1,
            name: '1 BHK'
        },
        {
            id: 2,
            name: '2 BHK'
        },
        {
            id: 3,
            name: '3 BHK'
        }
    ]
    const secondDropDownOptions = [
        {
            id: 1,
            name: 'PG'
        },
        {
            id: 2,
            name: 'Hostel'
        },
        {
            id: 3,
            name: 'Apartment'
        }
    ]
    const [firstDropDown, setFirstDropDown] = useState(firstDropDownOptions[0].name)
    const [secondDropDown, setSecondDropDown] = useState(secondDropDownOptions[0].name)
    const [showFirstDropDown, setShowFirstDropDown] = useState(false)
    const [showSecondDropDown, setShowSecondDropDown] = useState(false)

    return (
        <div className='Home-Background-Container'>
            <div className='Home-Background-Typing-Effect'>
                <TypingEffect />
            </div>
            {/* <div className='Home-Background-Search-Input-Container'>
                <div>
                    <input
                        className='Home-Background-Search-Input'
                        placeholder='Search by name or address...'
                        style={{
                            width: "70vw",
                            borderRadius: "30px",
                            border: '0px solid',
                            paddingTop: "15px",
                            paddingBottom: "15px",
                            paddingLeft: "20px",
                            fontFamily: 'Montserrat',
                            outline: 'none',
                        }} />

                </div>
                <div className='dropdown-search'>
                    <div
                        className='dropdown-search-div'
                        style={{
                            backgroundColor: COLORS.Primary,
                            display: "flex",
                            justifyContent: 'center'
                            , alignContent: 'center',
                            alignItems: 'center',
                            borderRadius: "30px",
                            position: "relative",
                        }}>
                        <span
                            style={{
                                color: "white",
                                fontWeight: 'bold'
                            }}
                        >
                            Search
                        </span>
                    </div>
                    <div
                        onClick={
                            () => {
                                setShowFirstDropDown(!showFirstDropDown)
                            }
                        }

                        style={{
                            background: "transparent",
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: 'center'
                            , alignContent: 'center',
                            alignItems: 'center',
                            paddingLeft: "40px",
                            paddingRight: "40px",

                            position: "relative",
                            borderLeft: "1px solid black",
                            left: "-390px"
                        }}>
                        <span
                            className='dropdown-search-span'

                        >
                            1 BHK
                            <span>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                        </span>
                        {
                            showFirstDropDown &&
                            <div style={{
                                position: "absolute",
                                top: "55px",

                                left: "1px",

                            }}>
                                {
                                    firstDropDownOptions.map((item) => {
                                        return (<>
                                            <div style={{
                                                display: "flex",
                                                backgroundColor: "white",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                justifyContent: 'center'
                                                , alignContent: 'center',
                                                alignItems: 'center',
                                                width: "105px",
                                                height: "50px",
                                                fontSize: "12px",
                                                border: "1px solid black",
                                            }}>
                                                <span>
                                                    {item.name}
                                                </span>

                                            </div>
                                        </>)
                                    })
                                }


                            </div>

                        }

                    </div>
                    <div
                        onClick={
                            () => {
                                setShowSecondDropDown(!showSecondDropDown)
                            }
                        }
                        style={{
                            cursor: "pointer",
                            background: "transparent",
                            display: "flex",
                            justifyContent: 'center'
                            , alignContent: 'center',
                            alignItems: 'center',
                            paddingLeft: "40px",
                            paddingRight: "40px",


                            position: "relative",
                            borderLeft: "1px solid black",
                            left: "-640px",

                        }}>
                        <span
                            className='dropdown-search-span'

                        >
                            PG<span>
                                <FontAwesomeIcon icon={faChevronDown} />
                            </span>
                        </span>

                        {
                            showSecondDropDown &&
                            <div style={{
                                position: "absolute",
                                top: "55px",

                                left: "1px",

                            }}>
                                {
                                    secondDropDownOptions.map((item) => {
                                        return (<>
                                            <div style={{
                                                display: "flex",
                                                backgroundColor: "white",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                justifyContent: 'center'
                                                , alignContent: 'center',
                                                alignItems: 'center',
                                                width: "105px",
                                                height: "50px",
                                                fontSize: "12px",
                                                border: "1px solid black",
                                            }}>
                                                <span>
                                                    {item.name}
                                                </span>

                                            </div>
                                        </>)
                                    })
                                }
                            </div>
                        }

                    </div>


                </div>



            </div> */}
        </div>
    )
}
