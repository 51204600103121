import React, { useEffect, useState } from 'react'
import Navbar from '../../Components/Navbar/Navbar';
import TestimonialCard from '../../Components/Home/TestimonialCard';
import FeatureCard from '../../Components/Home/FeatureCard';
import HomeBackground from '../../Components/Home/HomeBackground';
import Footer from '../../Components/Footer/Footer';
import HomeBanner from '../../Components/Home/HomeBanner';
import GoogleAd from '../../Components/Advertisement/GoogleAd';
import OurValueSection from '../../Components/Home/OurValueSection';
import '../../Styles/Site/Home.css'
import { Helmet } from 'react-helmet';
import { openSubscriptionModal } from '../../Components/Modals/SubscriptionModal';
import ManualAd from '../../Components/Advertisement/ManualAd';
import { faBus, faCalendar, faHome, faHouse } from '@fortawesome/free-solid-svg-icons';
import ScrollToTopButton from '../../Components/Buttons/ScrollTopButton';
import TestimonialCardNew from '../../Components/Home/TestimonialCardNew';
import MobileManualAd from '../../Components/Advertisement/MobileMobileAd';


export default function Home() {

    const data = [
        {
            id: 1,
            title: "Convenience",
            description: "Prioritize ease of use, seamless booking, and user-friendly features to make the app a "
        },
        {
            id: 2,
            title: "Transparency",
            description: "Maintain transparency in listings, pricing, and policies, allowing students to make informed decisions when booking accommodations and transport."
        },
        {
            id: 3,
            title: "Community",
            description: "Foster a sense of community among users, connecting students and travelers with hosts and transportation providers in a friendly and supportive environment."
        },
        {
            id: 4,
            title: "Student-Centric",
            description: " Prioritize the unique needs and challenges of students by offering student-specific features, discounts, and resources."
        }
    ]

    const testimonialData = [
        {
            Name: "Priyanshi Garg",
            Review: "Accomzy saved me so much time and effort during my first year of college. The platform's intuitive design and comprehensive listings made it easy to compare different housing options. I found a fantastic place with great roommates, all thanks to Accomzy",
            ProfileUrl: "https://s.yimg.com/lo/api/res/1.2/Q_8CT0KXqXhPX2uYAs5h7Q--/YXBwaWQ9YXBlY21lZGlhO3NtPTE7dz00NzE7aD00MDY-/https://media-mbst-pub-ue1.s3.amazonaws.com/creatr-uploaded-images/2020-02/1b135b50-5148-11ea-bf77-109a5a9d4ca6"
        },
        {
            Name: "Himanshu Tiwari",
            Review: "I was skeptical about finding decent accommodation close to my university, but Accomzy exceeded my expectations. The real-time updates and detailed property descriptions made the decision-making process smoother. I now have a fantastic place that meets all my requirements.",
            ProfileUrl: "https://www.careerguide.com/career/wp-content/uploads/2023/06/depositphotos_542532798-stock-photo-portrait-indian-college-boy-holding.webp"
        },
        {
            Name: "Harpreet Singh Bhatia",
            Review: "Navigating the housing market in a new country can be overwhelming, but Accomzy made it a breeze. The platform's tailored search options helped me find a cozy apartment close to my study location. I'm grateful for the convenience it brought to my study abroad experience",
            ProfileUrl: "https://st4.depositphotos.com/16405760/23694/i/450/depositphotos_236941312-stock-photo-portrait-young-attractive-beautiful-indian.jpg"
        },
    ];



    useEffect(() => {
        window.scrollTo(0, 0)
        //when screen load completely afer 2 sec it will open the subscription modal
        setTimeout(() => {
            //    openSubscriptionModal()
        }, 7000);



    })
    return (
        <>
            <Helmet>

                <title>Accomzy - Exploring Accommodation Made Easy
                </title>
                <meta name="description" content="Discover convenient and comfortable living with Accomzy! Find the perfect PGs, hostels, and apartments for students. Simplify your accommodation search with our user-friendly platform. Your ideal living space is just a click away. Start your housing journey with Accomzy today!">
                </meta>
                <meta name='og:title' content='Accomzy - Exploring Accommodation Made Easy' />
                <meta name='og:description' content="Discover convenient and comfortable living with Accomzy! Find the perfect PGs, hostels, and apartments for students. Simplify your accommodation search with our user-friendly platform. Your ideal living space is just a click away. Start your housing journey with Accomzy today!" />
                <meta name='og:url' content='https://accomzy.in/' />
                <meta name='og:image' content='https://i.ibb.co/wRs2VQX/file-cover-1.png' />


            </Helmet>
            <div className='Home-Navbar-Container'>

                <Navbar />
            </div>

            <div style={{
                marginTop: "0px"
            }}>
                <div style={{

                    width: '100vw',
                    height: '100vh',

                }}
                > <div style={{
                    paddingTop: "50px"
                }}>

                        <HomeBackground />
                    </div>

                    <ScrollToTopButton />

                    <div
                        className='Home-Banner-Container'
                    >

                        <HomeBanner />
                        <div className="md:px-0 px-4 w-full flex md:flex-wrap md:flex-row flex-col  justify-around mt-32 mb-20 gap-x-5">
                            {testimonialData.map((testimonial, index) => (
                                <div key={index} className='w-full md:w-[25%] mt-20'>
                                    <TestimonialCardNew data={testimonial} />
                                </div>
                            ))}
                        </div>
                        <div>
                        </div>
                        <div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            backgroundColor: "black",
                            flexDirection: 'column',
                            backgroundImage: "url(https://i.ibb.co/tPQtPSL/fadeGrid.png)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "right top",

                            transform: 'scaleX(-1)', // Flip the background image horizontally

                        }}
                    >
                        <div style={{
                            transform: 'scaleX(-1)', // Flip the background image horizontally
                        }}>
                            <div className="md:flex hidden w-full items-center justify-center">
                                <ManualAd />
                            </div>
                            <div className='md:hidden flex'>
                                <MobileManualAd />
                            </div>
                            {/* <GoogleAd /> */}
                            <div style={{
                                marginTop: "30px",
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                textAlign: 'center',
                            }}>
                                <span style={{
                                    fontSize: '40px',
                                    color: 'white',
                                    fontFamily: 'Montserrat',
                                    fontWeight: 'bold'
                                }}>
                                    Our {" "}
                                    <span style={{
                                        color: '#8A38F5'
                                    }}>
                                        Services
                                    </span>
                                </span>
                            </div>
                            <div style={{
                                backgroundImage: "url(https://i.ibb.co/Hpxx57d/bgDots.png)",
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "right top",
                            }}>
                                <div className='mx-4'
                                    style={{
                                        display: "flex",
                                        flexWrap: 'wrap',
                                        justifyContent: 'space-around',
                                        marginBottom: "100px",


                                    }}>

                                    <div>
                                        <FeatureCard
                                            icon={faHome}
                                            Heading={"PGs"}
                                            description={"Explore a wide range of PGs and hostels in your preferred location. Find the perfect accommodation that meets all your requirements."}
                                        />

                                    </div>
                                    <div>
                                        <FeatureCard
                                            icon={faBus}
                                            Heading={"Transportation Services"}
                                            description={"Book a cab to your university or workplace with Accomzy. Our transportation services are safe, reliable, and affordable."}
                                        />

                                    </div>   <div>
                                        <FeatureCard
                                            icon={faCalendar}
                                            Heading={"Booking Management"}
                                            description={"Manage your bookings with Accomzy. Our platform allows you to track your payments and view your booking history."}
                                        />

                                    </div>

                                </div>
                            </div>

                            <div style={{
                                paddingLeft: '20px',
                                paddingRight: '20px',
                                textAlign: 'center',
                            }}>
                                <span style={{
                                    fontSize: '40px',
                                    color: 'white',
                                    fontFamily: 'Montserrat',
                                    fontWeight: 'bold'
                                }}>
                                    Core  {" "}
                                    <span style={{
                                        color: '#8A38F5'
                                    }}>
                                        Values
                                    </span>
                                </span>
                            </div>
                            <div className='flex flex-wrap gap-x-24 gap-y-10 justify-center items-center w-full mt-4 mb-20'>
                                {data.map((item, index) => (
                                    <OurValueSection key={index} data={item} />
                                ))}
                            </div>

                            <Footer />
                        </div>



                    </div>

                </div>

            </div >

        </>



    )
}
