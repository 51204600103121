import React from 'react';
import ReactTypingEffect from 'react-typing-effect';
import { COLORS } from '../../Utils/Colors';

export default function TypingEffect() {
    const Texts = [
        "Exploring Accommodation Made Easy",
        "Navigating Housing Options with Ease",
        "Simplified Ways to Explore Housing",
        "Mastering the Art of Hassle-Free Accommodation Exploration",
        "Streamlining the Accommodation Search Process",
        "Effortless Accommodation Discovery: A Comprehensive Guide"
    ];

    const getColorStyle = (currentIndex) => (currentIndex === 1 ? {
        color: COLORS.Primary,
        fontFamily: 'Montserrat',
        fontWeight: 'bold'
    } : {
        color: 'white',
        fontFamily: 'Montserrat',
        fontWeight: 'bold'
    });

    return (
        <div>
            <ReactTypingEffect
                eraseSpeed={100}
                eraseDelay={400}
                speed={100}
                text={Texts}
                cursorRenderer={(cursor) => <h1
                    style={{
                        color: COLORS.Primary
                    }}
                >{cursor}</h1>}
                displayTextRenderer={(text, i) => {
                    const words = text.split(' ');

                    return (
                        <h1>
                            {words.map((word, index) => (
                                <span key={index}

                                    style={getColorStyle(index)}>
                                    {word}
                                    {index < words.length - 1 && ' '}
                                </span>
                            ))}
                        </h1>
                    );
                }}
            />
        </div>
    );
}
